import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RelatorioPendenciasFiscaisComponent} from './relatorio-pendencias-fiscais.component';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzInputModule} from 'ng-zorro-antd/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UiModule} from '../../ui.module';
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {NzTagModule} from "ng-zorro-antd/tag";

@NgModule({
  declarations: [
      RelatorioPendenciasFiscaisComponent
  ],
    imports: [
        CommonModule,
        NzLayoutModule,
        NzPageHeaderModule,
        NzFormModule,
        NzCardModule,
        NzProgressModule,
        NzTypographyModule,
        NzTableModule,
        NzBadgeModule,
        NzDropDownModule,
        NzIconModule,
        NzButtonModule,
        NzInputModule,
        FormsModule,
        UiModule,
        NzDatePickerModule,
        ReactiveFormsModule,
        NzTagModule
    ]
})
export class RelatorioPendenciasFiscaisModule { }
