import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Injectable} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {NzTableSortFn, NzTableSortOrder} from 'ng-zorro-antd/table';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import {ArquivosTransitoriosServiceService} from './arquivos-transitorios.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NzModalService} from 'ng-zorro-antd/modal';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Subscription} from 'rxjs';

export interface Data {
    arquivo: string;
    caminho: string;
    cnpj: string;
    data: string;
    tamanho: number;
    disabled: boolean;
    motivo?: string;
}

interface ColumnItem {
    title: string;
    priority: boolean;
    sortOrder: NzTableSortOrder | null;
    sortFn: NzTableSortFn | null;
    sortDirections: NzTableSortOrder[];
}

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}


@Injectable()
@Component({
    selector: 'app-monitor-arquivos-transitorios',
    templateUrl: './arquivos-transitorios.component.html',
    styleUrls: ['./arquivos-transitorios.component.scss']
})
export class MonitorArquivosTransitoriosComponent implements OnInit {
    @Input() data;
    origem = '';
    pastaTipo = '';
    pastaStatus = '';
    tabsStatus = [
        {
            pasta: 'processar',
            label: 'Em Processamento',
            indexTipo: 0
        },
        {
            pasta: 'processados',
            label: 'Processados',
            indexTipo: 0
        },
        {
            pasta: 'rejeitados',
            label: 'Rejeitados',
            indexTipo: 0
        },
        {
            pasta: 'nao-identificados',
            label: 'Não Identificados',
            indexTipo: 0
        }
    ];
    tabsTipo = [];

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
        }
    };

    colunasTabelaArquivos: ColumnItem[] = [
        {
            title: 'Arquivo',
            sortOrder: null,
            sortFn: (a: Data, b: Data) => a.arquivo.localeCompare(b.arquivo),
            sortDirections: ['ascend', 'descend', null],
            priority: false
        },
        {
            title: 'Caminho',
            sortOrder: null,
            sortFn: (a: Data, b: Data) => a.caminho.localeCompare(b.caminho),
            sortDirections: ['ascend', 'descend', null],
            priority: false
        },
        {
            title: 'Tamanho',
            sortOrder: null,
            sortFn: (a: Data, b: Data) => a.tamanho - b.tamanho,
            sortDirections: ['ascend', 'descend', null],
            priority: false
        },
        {
            title: 'Data',
            sortOrder: 'descend',
            sortFn: (a: Data, b: Data) => a.data.localeCompare(b.data),
            sortDirections: ['ascend', 'descend', null],
            priority: false
        }
    ];
    checked = false;
    loading = false;
    loadingCardTable = false;
    listOfData = [];
    cardTitle: string;
    uploadDisplayNone = true;
    formSistema: FormStack;
    filtroTipos: '';
    arrayFiltrosTipos: any[] = [];
    checkedItems = new Set<string>();
    loadingCardPasta = false;
    formUpload: FormStack;
    indeterminate = false;
    listOfDataTotal = 0;

    formFiltros: FormStack;
    currentSearch = null;

    arraySelectMotivos = [];
    qtdFiltrosAtivos = 0;

    tabTributoNumber = 0;
    tabStatusNumber = 0;

    existe = false;
    loadingsProcessar: {} = {};

    fileList: any[] = [];
    apiUrl = environment.apiUrl;
    uploading = false;
    progress: any[] = [];
    requestUpload: Subscription;

    constructor(
        private service: ArquivosTransitoriosServiceService,
        private fb: UntypedFormBuilder,
        private toastrService: ToastrService,
        private cdr: ChangeDetectorRef,
        private http: HttpClient,
        private modalService: NzModalService) {

        this.formSistema = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.formUpload = {
            modalVisible: false,
            formGroup: this.fb.group({})
        };

        this.pastaStatus = this.data?.tabStatus || 'processar';

        this.formFiltros = {
            modalVisible: false,
            formGroup: this.fb.group({
                motivo: [null, null],
            })
        };

    }

    ngOnInit() {
        this.iniciar();

        this.service.retornarSelectsMotivos().subscribe((retorno: any) => {
            this.arraySelectMotivos = retorno;
        });
    }

    iniciar(): void {

        this.service.retornaTipos().subscribe((response: any) => {
            this.arrayFiltrosTipos = [];
            response.forEach(value => {
                if (value.id !== 'perdcompweb' || this.origem !== 'upload') {
                    this.arrayFiltrosTipos.push(value);
                }
            });
        });

        this.origem = this.data?.pastaTipo ? 'upload' : this.data;

        switch (this.origem) {

            case 'upload':
                this.cardTitle = 'Transferências via Upload no Sistema';
                break;

            case 'agent':
                this.cardTitle = 'Transferências via aplicativo Master Agent';
                break;

            case 'edi':
                this.cardTitle = 'Transferências via integração EDI';
                break;

            default:
                this.cardTitle = 'Arquivos';

        }

        this.loadingCardPasta = true;

        this.loadingCardTable = true;

        this.service.retornaPastasArquivosTransitorios(this.origem).subscribe((data: any[]) => {

            this.tabsTipo = data;

            this.loadingCardPasta = false;

            if (this.tabsTipo.length > 0) {

                this.verificaExistenciaTributo();

                this.pastaTipo = this.existe && this.data.pastaTipo ? this.data.pastaTipo : this.tabsTipo[0].id;
                this.retornaArquivosTransitorios(this.pastaTipo, this.pastaStatus);

            }
            this.loadingCardTable = false;

        });

    }

    verificaExistenciaTributo() {
        this.existe = false;

        if (this.data.pastaTipo) {
            this.tabsTipo.forEach(t => {
                if (t.id === this.data.pastaTipo) {
                    this.existe = true;
                }
            });

            if (this.data.pastaTipo === 'efdfiscal' && !this.existe) {
                this.data.pastaTipo = 'efdcont';
                this.pastaTipo = 'efdcont';
                this.verificaExistenciaTributo();
            }
        }
    }

    retornaArquivosTransitorios(pastaTipo: string, pastaStatus: string, filtros?, click = null): any {

        this.pastaTipo = pastaTipo;

        if (pastaStatus === 'rejeitados') {

            if (this.colunasTabelaArquivos.length === 5) {
                this.colunasTabelaArquivos.splice(1, 1);
            }

            const c = this.colunasTabelaArquivos.find(coluna => coluna.title === 'Motivo');

            if (!c) {
                this.colunasTabelaArquivos.splice(1, 0,
                    {
                        title: 'Motivo',
                        sortOrder: null,
                        sortFn: (a: Data, b: Data) => a.motivo.localeCompare(b.motivo),
                        sortDirections: ['ascend', 'descend', null],
                        priority: false
                    }
                );
            }

            let qtd = 0;
            this.colunasTabelaArquivos.forEach(coluna => {
                if (coluna.title === 'CNPJ') {
                    qtd += 1;
                }
            });

            this.colunasTabelaArquivos.splice(2, qtd);

        } else if (pastaStatus === 'processados') {

            if (this.colunasTabelaArquivos.length === 5) {
                this.colunasTabelaArquivos.splice(1, 1);
            }

            const c = this.colunasTabelaArquivos.find(coluna => coluna.title === 'CNPJ');

            if (!c) {
                this.colunasTabelaArquivos.splice(1, 0,
                    {
                        title: 'CNPJ',
                        sortOrder: null,
                        sortFn: (a: Data, b: Data) => a.cnpj.localeCompare(b.cnpj),
                        sortDirections: ['ascend', 'descend', null],
                        priority: false
                    }
                );
            }

            const cM = this.colunasTabelaArquivos.find(coluna => coluna.title === 'Motivo');

            if (!cM) {
                this.colunasTabelaArquivos.splice(1, 0,
                    {
                        title: 'Motivo',
                        sortOrder: null,
                        sortFn: (a: Data, b: Data) => a.motivo.localeCompare(b.motivo),
                        sortDirections: ['ascend', 'descend', null],
                        priority: false
                    }
                );
            }

        } else if (this.colunasTabelaArquivos.length >= 5) {
            let qtd = 0;
            this.colunasTabelaArquivos.forEach(coluna => {
                if (coluna.title === 'CNPJ' || coluna.title === 'Motivo') {
                    qtd += 1;
                }
            });

            this.colunasTabelaArquivos.splice(1, qtd);

        }

        this.pastaStatus = this.data?.tabStatus || pastaStatus;

        this.loadingCardTable = true;

        const caminho = 'caminho=' + this.origem + '&pasta=' + pastaTipo + '&status=' + this.pastaStatus;

        if (this.currentSearch) {
            filtros.procurar = this.currentSearch;
        } else if (filtros) {
            delete filtros.procurar;
        }

        this.service.retornaTabelaArquivosTransitorios(caminho, filtros).subscribe((data: any) => {

            this.listOfData = data.items;

            if (!click && !data.items.length && pastaTipo === 'efdfiscal') {
                this.data.pastaTipo = 'efdcont';
                this.retornaArquivosTransitorios(this.data.pastaTipo, pastaStatus, filtros, click);

            } else {

                this.listOfDataTotal = data.total;

                this.refreshCheckedStatus();

                this.checkedItems.clear();

                if (!click && this.existe) {
                    for (const [index, t] of Object.entries(this.tabsStatus)) {

                        if (t.pasta === this.pastaStatus) {
                            this.changeTabsStatus({index});
                            break;
                        }
                    }

                    for (const [index, t] of Object.entries(this.tabsTipo)) {

                        if (t.id === pastaTipo) {
                            this.changeTabs({index});
                            break;
                        }
                    }
                }

                this.loadingCardTable = false;

            }

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    reprocessarArquivos() {

        const arquivos = this.retornaArquivos();

        this.service.reprocessarArquivos(arquivos).subscribe((response) => {

            this.toastrService.success(response.message);

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    removerArquivos() {

        const arquivos = this.retornaArquivos();

        this.service.removerArquivos(arquivos).subscribe((response) => {

            this.toastrService.success(response.message);

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    showConfirmReprocessar(): void {

        this.modalService.confirm({
            nzTitle: 'Deseja reprocessar o(s) arquivo(s) selecionado(s)?',
            nzOkText: 'Reprocessar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.reprocessarArquivos()
        });

    }

    showConfirmRemover(): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o(s) arquivo(s) selecionado(s)?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerArquivos()
        });

    }

    retornaArquivos() {

        const requestData = this.listOfData.filter(data => this.checkedItems.has(data.arquivo));
        const arquivos = [];

        for (const selecionado of requestData) {

            arquivos.push((selecionado.caminho === '/' ? '' : selecionado.caminho.replace(/^\/|\/$/g, '') + '/') + selecionado.arquivo);

        }

        return arquivos;

    }

    beforeUpload = (file: NzUploadFile): boolean => {
        this.fileList = this.fileList.concat(file);
        return false;
    }

    handleUpload(): void {

        const files: any = [];
        const tipo = this.formSistema.formGroup.value.tipo;

        this.uploading = true;

        const formData = new FormData();

        formData.append('tipo', tipo);

        this.fileList.forEach((file: any) => {
            files.push(file);
            formData.append('files[]', file);
        });

        this.service.doUpload(formData).subscribe((response: any) => {

            this.fileList = [];

            this.uploading = false;

            this.toastrService.success(response.message);

            this.uploadDisplayNone = true;

            this.filtroTipos = '';

            this.formSistema.formGroup.value.tipo = '';

            this.iniciar();

        }, (response) => {

            this.uploading = false;

            this.toastrService.error(response.error.message);

        });

    }

    formatBytes(bytes, decimals = 2) {

        if (bytes === 0) {
            return '0 Bytes';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

    }

    onAllChecked(checked: boolean): void {
        this.listOfData.filter(({disabled}) => !disabled).forEach(({arquivo}) => this.updateCheckedSet(arquivo, checked));
    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

    }

    onItemChecked(id: string, checked: boolean): void {
        this.updateCheckedSet(id, checked);
    }

    atualizar() {

        this.loadingCardPasta = true;

        this.loadingCardTable = true;

        this.service.retornaPastasArquivosTransitorios(this.origem).subscribe((data: any[]) => {

            this.tabsTipo = data;

            this.loadingCardPasta = false;

            if (this.tabsTipo.length > 0) {
                this.retornaArquivosTransitorios(this.pastaTipo, this.pastaStatus);
            }

        });

    }

    showHideModal(form: FormStack, visible) {
        form.modalVisible = visible;
    }

    refreshCheckedStatus() {

        const listOfEnabledData = this.listOfData.filter(({disabled}) => !disabled);
        this.checked = listOfEnabledData.every(({id}) => this.checkedItems.has(id));
        this.indeterminate = listOfEnabledData.some(({id}) => this.checkedItems.has(id)) && !this.checked;

    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    fechar(formulario: FormStack
    ): void {
        formulario.modalVisible = false;
    }

    calculaBadgeFiltros(): void {
        let qtd = 0;

        Object.entries(this.formFiltros.formGroup.value).forEach((value) => {

            if (value[0] !== 'ano' && value[0] !== 'sort' && value[0] !== 'page' && value[1]) {
                qtd += 1;
            }

        });

        this.qtdFiltrosAtivos = qtd;

    }

    filtrar() {

        this.formFiltros.modalVisible = false;

        this.calculaBadgeFiltros();

        this.retornaArquivosTransitorios(this.pastaTipo, this.pastaStatus, this.formFiltros.formGroup.value);

    }

    buscar() {
        this.retornaArquivosTransitorios(this.pastaTipo, this.pastaStatus, this.formFiltros.formGroup.value);
    }

    changeTabs(event) {
        this.tabTributoNumber = event.index;
        setTimeout(() => {
            this.cdr.detectChanges();
        }, 100);
    }

    changeTabsStatus(event) {
        this.tabStatusNumber = event.index;
        setTimeout(() => {
            this.cdr.detectChanges();
        }, 100);
    }

    reprocessarArquivo(data: any) {

        this.loadingsProcessar[data.arquivo] = true;

        this.service.processarArquivo(data.arquivo, data.caminho, this.pastaTipo, this.origem).subscribe(res => {
            this.loadingsProcessar[data.arquivo] = false;
            this.toastrService.success('Arquivo processado.');
            this.retornaArquivosTransitorios(this.pastaTipo, this.pastaStatus, this.formFiltros.formGroup.value);
        }, (res) => {
            this.loadingsProcessar[data.arquivo] = false;
            this.toastrService.error(res.error.message);
        });
    }

    handleChange({file, fileList}: NzUploadChangeParam): void {
        const status = file.status;
        if (status !== 'uploading') {

        }
        if (status === 'done') {
            this.toastrService.success(`${file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            this.toastrService.error(`${file.name} file upload failed.`);
        }
    }

    customRequest = (item: any): any => {
        const formData = new FormData();

        this.fileList.forEach((file: any) => {
            formData.append('files[]', file);
        });

        formData.append('tipo', this.formSistema.formGroup.get('tipo').value);

        const req = new HttpRequest('POST', `${environment.apiUrl}/drive/obrigacoes/federais/upload`, formData, {
            headers: new HttpHeaders(),
            reportProgress: true
        });

        this.requestUpload = this.http.request(req).subscribe(
            {
                next: (event) => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            if (event.total) {
                                const percentDone = Math.round(100 * (event.loaded / event.total));

                                // Atualiza o progresso para cada arquivo individualmente
                                this.progress = this.progress.map((_, index) => percentDone);
                                item.onProgress({percent: percentDone}, event);
                            }
                            break;
                        case HttpEventType.Response:
                            this.toastrService.success('Upload realizado com sucesso!');
                            item.onSuccess(event.body, this.fileList);
                            this.fileList = [];
                            this.uploading = false;
                            this.showHideModal(this.formUpload, false);
                            this.formSistema.formGroup.reset();
                            this.filtroTipos = null;
                            break;
                        default:
                            break;
                    }
                }, error: (error) => {
                    this.toastrService.error(error.error.message || error.message || 'Erro ao tentar realizar upload!');
                    this.uploading = false;
                    // item.onError(error);
                }
            });
    }

    startUpload = (): void => {
        if (this.formSistema.formGroup.valid) {
            this.uploading = true;

            this.progress = new Array(this.fileList.length).fill(0);

            this.customRequest({
                file: null,
                onProgress: (progress: any) => {
                    this.progress = this.progress.map(() => progress.percent);
                },
                onSuccess: (response: any, files: any) => {
                    // this.toastrService.success('Todos os arquivos foram enviados com sucesso!');
                },
                onError: (error: any) => {
                    // this.toastrService.error('Erro no envio dos arquivos');
                }
            });

        } else {
            for (const key in this.formSistema.formGroup.controls) {

                if (key) {

                    const campo = this.formSistema.formGroup.get(key);

                    campo.markAsDirty();
                    campo.updateValueAndValidity();

                }
            }
        }
    }

    cancelUpload() {
        if (this.requestUpload) {
            this.requestUpload.unsubscribe();
        }

        this.uploading = false;
        this.progress = [];
    }


}
