import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Helpers} from '../../core/helpers';

@Injectable({
    providedIn: 'root'
})
export class InboxService {

    constructor(protected http: HttpClient) {

    }

    getCabecalho(params: NzTableQueryParams): Observable<any> {

        const qStr = Helpers.montaQueryStribgBYNzTable(params);

        return this.http.get(`${environment.apiUrl}/inbox/dashboard/cabecalho${qStr}`);
    }

    listar(params: NzTableQueryParams): Observable<any> {

        const qStr = Helpers.montaQueryStribgBYNzTable(params);

        return this.http.get(`${environment.apiUrl}/inbox/dashboard/tabela${qStr}`);
    }

    getAnexos(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/inbox/dashboard/anexos/tabela/${id}`);
    }

    reprocessarAnexo(id: string) {

        return this.http.post(`${environment.apiUrl}/inbox/dashboard/anexos/reprocessar/${id}`, {});
    }

    removerAnexo(id: string) {

        return this.http.post(`${environment.apiUrl}/inbox/dashboard/anexos/remover/${id}`, {});
    }

}
