<nz-page-header class="site-page-header" nzTitle="Cadastros"
                nzSubtitle="Monitor de eventos">

    <nz-page-header-extra>
        <nz-button-group>
            <button *ngFor="let i of tabs" nz-button nzType="primary" [nzGhost]="showTable !== i.value"
                    (click)="changeTable(i.value)" [disabled]="loadingTable"
                    [ngStyle]="i.value === tabs[0].value ? {'border-top-left-radius': '0.45rem', 'border-bottom-left-radius': '0.45rem'} :
                                i.value === tabs[tabs.length - 1].value ? {'border-top-right-radius': '0.45rem', 'border-bottom-right-radius': '0.45rem'} : {}"
            >
                {{ i.key }}
            </button>

        </nz-button-group>
    </nz-page-header-extra>

</nz-page-header>
<nz-content>

    <nz-content>
        <nz-card nzTitle="Eventos" [nzLoading]="loadingTable" [nzExtra]="navBar"
                 [nzBodyStyle]="{'padding': loadingTable ? '15px' : '0px'}"
                 [ngClass]="[statusCard ? 'card-fullscreen' : 'ant-card-table fonte-doze']">

            <ng-template #navBar>
                <div class="nav-item d-flex">
                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                        <input (keydown.enter)="buscar()" type="text" nz-input [disabled]="loadingTable"
                               placeholder="Busca" [(ngModel)]="currentSearch">
                    </nz-input-group>
                    <ng-template #suffixIconButton>
                        <button (click)="buscar()" [disabled]="loadingTable" nz-button nzType="primary" nzSearch>
                            <i nz-icon nzType="search"></i></button>
                    </ng-template>
                    <button nz-button nzType="default" style="margin-right: 10px;"
                            (click)="btnResetSearch();">
                        Limpar
                    </button>
                    <button [disabled]="loadingTable" nz-button nzType="default" style="margin-right: 10px;"
                            (click)="reloadTable();">
                        <i nz-icon nzType="reload" nzTheme="outline"></i>
                    </button>
                    <button nz-button [nzType]="'default'" (click)="clickEvent()"
                            style="padding-left: 8px; padding-right: 8px;">
                        <i class="fas" [ngClass]="statusCard ? 'fa-compress' : 'fa-expand'"></i>
                    </button>
                </div>
            </ng-template>

            <nz-alert nzType="warning" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                      *ngIf="checkedItems.size > 0 && showTable === '0'">
                <ng-template #checkedMessage>
                    <div nz-row nzAlign="middle" nzGutter="24">
                        <div nz-col nzSpan="24">
                            <strong class="text-primary"></strong>
                            Deseja confirmar
                            {{
                                checkedItems.size > 1 ? ' os ' + checkedItems.size + ' eventos selecionados '
                                    : 'o evento selecionado '
                            }} ?

                            <button [nzLoading]="loadingEventos.length > 0" style="margin-left: 10px"
                                    (click)="confirmaEvento()" nz-button nzType="primary">
                                Confirmar
                            </button>
                        </div>
                    </div>
                </ng-template>
            </nz-alert>

            <nz-table #basicTable
                      nzSize="small"
                      [nzScroll]="{ x: 'auto' }"
                      [nzData]="items"
                      [nzFrontPagination]="false"
                      [nzTotal]="paginations?.total"
                      [nzPageSize]="paginations?.per_page"
                      [nzPageIndex]="paginations?.current_page"
                      (nzQueryParams)="listByTable($event)"
                      [nzShowTotal]="rangeTemplateUsuarios"
                      class="table-small"
            >
                <thead>
                <tr>
                    <th *ngIf="showTable === '0'" nzWidth="50px" (nzCheckedChange)="onAllChecked($event)"
                        [nzChecked]="checked"
                        id="check-all">
                    </th>
                    <th *ngIf="showTable !== '0'"></th>
                    <th [width]="'350px'" nzColumnKey="evento">Evento</th>
                    <th nzColumnKey="acao">Ação</th>
                    <th [nzWidth]="'180px'" *ngIf="showTable === '0'"></th>
                    <th [nzAlign]="'center'"  nzColumnKey="created_at" [nzSortFn]="true" *ngIf="showTable !== '0'">Data execução</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of items">
                    <td *ngIf="showTable === '0'" (nzCheckedChange)="onItemChecked(data.id, $event)"
                        [nzChecked]="checkedItems.has(data.id)">
                    </td>
                    <td *ngIf="showTable !== '0'">
                        <div style="width: 1px"></div>
                    </td>
                    <td *ngIf="data.key !== 'email-sucesso'">
                        <div *ngFor="let e of data.evento">
                            {{ e }}<br>
                        </div>
                        <br>({{data.empresa_cnpj | cpfCnpj}})
                    </td>
                    <td *ngIf="data.key !== 'email-sucesso'">
                        <div style="display: flex; gap: 10px; align-items: center">
                            <div style="min-width: 180px">
                                {{ data?.acao?.acao }}
                            </div>
                            <div *ngIf="data.cronometro"
                                 style="display: flex; gap: 10px; align-items: center; min-width: 420px">
                                <i style="color: rgba(255,105,0,0.76); font-size: 1.4em"
                                   class="fa-regular fa-hourglass-half"></i>
                                <div class="cronometro">
                                    Evento será executado automaticamente em {{ data?.cronometro }}
                                </div>
                            </div>

                        </div>
                    </td>

                    <td *ngIf="data.key === 'email-sucesso'">
                        <div>
                            {{ data?.evento }}
                            <br>({{data.empresa_cnpj | cpfCnpj}})
                        </div>
                    </td>

                    <td *ngIf="data.key === 'email-sucesso'">
                        <div style="display: grid; grid-template-columns: auto 130px;">
                            <div style="display: flex; gap: 10px; align-items: center;">
                                <div>
                                    <div  style="min-width: 180px">
                                        {{ data?.acao?.acao }}<br>
                                    </div>
                                </div>
                                <div *ngIf="data.cronometro"
                                     style="display: flex; gap: 10px; align-items: center; min-width: 420px">
                                    <i style="color: rgba(255,105,0,0.76); font-size: 1.4em"
                                       class="fa-regular fa-hourglass-half"></i>
                                    <div class="cronometro">
                                        Evento será executado automaticamente em {{ data?.cronometro }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button *ngIf="showTable !== '0'" [nzLoading]="loadingEventos.includes(data.id)"
                                        (click)="reprocessar(data.id)"
                                        style="float: left" nz-button>Reprocessar
                                </button>
                            </div>
                        </div>
                    </td>

                    <td nzAlign="center" *ngIf="showTable == '0'">
                        <button *ngIf="data.status?.toString() === '0'" nz-button nzType="default"
                                (click)="confirmaEvento( data.ids )"
                                [nzLoading]="loadingButton(data.ids)">
                            {{ data.key === 'email-sucesso' ? 'Enviar Email' : 'Antecipar Ação' }}
                        </button>
                    </td>
                    <td [nzAlign]="'center'" *ngIf="showTable !== '0'">
                        <div [nz-tooltip]="data.detalhesProcessamento ? data.detalhesProcessamento : ''">
                            {{ data.completed_at ? (data.completed_at | date: "dd/MM/yy 'às' HH:mm") : '' }}
                        </div>
                    </td>
                </tr>
                </tbody>
            </nz-table>
            <ng-template #rangeTemplateUsuarios let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>

        </nz-card>
    </nz-content>
