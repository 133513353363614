import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './modules/home/pages/home.component';
import {PasswordRecoveryComponent} from './modules/login/pages/password-recovery.component';
import {AuthenticationService} from './core/auth/authentication.service';
import {DashboardPublicPdfService} from "./modules/compliance/dashboard-public-pdf/dashboard-public-pdf.service";
import {ContaNaoEncontradaComponent} from "@components/conta-nao-encontrada/conta-nao-encontrada.component";

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent,
        // canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module')
            .then(m => m.LoginModule),
    },
    {
        path: 'conta-nao-encontrada',
        loadChildren: () => import('./core/components/conta-nao-encontrada/conta-nao-encontrada.module')
            .then(m => m.ContaNaoEncontradaModule),

    },
    {
        path: 'aprovacoes',
        loadChildren: () => import('./modules/aprovacoes/aprovacoes.module')
            .then(m => m.AprovacoesModule),
    },
    {
        path: 'compliance/bi-dashboard-pdf',
        loadChildren: () => import('./modules/compliance/dashboard-public-pdf/dashboard-public-pdf.module')
            .then(m => m.DashboardPublicPdfModule),
        resolve: {
            dashsBI: DashboardPublicPdfService
        }
    },
    {
        path: 'obrigacao-empresa-detalhe/:id',
        loadChildren: () => import('./modules/cadastros/obrigacoes-empresas/obrigacao-empresa-detalhe/obrigacao-empresa-detalhe.module')
            .then(m => m.ObrigacaoEmpresaDetalheModule),
    },
    {
        path: 'change-password',
        component: PasswordRecoveryComponent,
    },
    {
        path: '**',
        redirectTo: 'home'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
    providers: [
        AuthenticationService
    ]
})

export class AppRoutingModule {
}
