<nz-page-header class="site-page-header" nzTitle="Calendar" nzSubtitle="Obrigações">
    <nz-page-header-extra>
        <a nz-button nzType="link" (click)="slideSwiper('left', 4)">
            <i class="fas fa-angle-double-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 2)">
            <i class="fas fa-angle-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 2)">
            <i class="fas fa-angle-right"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 4)">
            <i class="fas fa-angle-double-right"></i>
        </a>
        <a nz-button nzType="link" (click)="toggleCollapseCard()">
            <span>{{ collapseCard ? 'Expandir' : 'Recolher' }}</span>
        </a>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <nz-row [nzGutter]="24">
        <nz-col [nzSpan]="24" style="overflow: hidden;">
            <swiper
                    [(index)]="cardCompetenciaAtivo"
                    (click)="selecionaCardCompetencia($event)"
                    slidesPerView="auto"
                    [spaceBetween]="30"
                    [centeredSlides]="true"
                    [mousewheel]="false"
                    [scrollbar]="false"
                    [navigation]="true">
                <ng-template *ngFor="let item of arrayCompetencias; index as i"
                             swiperSlide
                             class="swiper-card-competencia"
                >
                    <div class="card-competencia ant-card pull-up card-{{i}}"
                         [ngClass]="collapseCard && 'compress'">
                        <div class="ant-card-head">
                            <h2>{{ arrayMesesLabel[item.mes - 1] | uppercase }}</h2>
                            <h3>{{ item.ano }}</h3>
                        </div>
                        <div class="text-center ant-card-body card-{{i}}">
                            <div nz-row>
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{ item.unidades }}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Unidades
                                </div>
                            </div>
                            <div nz-row style="margin-bottom: 15px;">
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{ item.totalObrigacoes }}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Obrigações
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Pendentes</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px; color: #ff9149 !important;">
                                                <strong>
                                                    {{ item.pendentes }}
                                                </strong>
                                            </span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Atrasadas</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px; color: #ff4961 !important;">
                                                <strong>{{ item.atrasadas }}</strong>
                                            </span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Concluídas</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px; color: #28d094 !important;">
                                                <strong>{{ item.concluidas }}</strong>
                                            </span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Concluídas com Atraso</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px;">
                                                <strong>
                                                    {{ item.concluidasAtraso }}
                                                </strong>
                                            </span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>

                            <div nz-row style="margin: 0; padding: 0" *ngIf="item.processamentoData">
                                <nz-divider style="margin-top: 10px; margin-bottom: 0;"></nz-divider>
                                <div nz-col [nzSpan]="24"
                                     style="text-align: center; font-size: 12px; margin-top: 0px;line-height: 1; margin-bottom: 0">
                                    <small nz-typography nzType="secondary" class="small">Último processamento em <br>
                                        {{ item.processamentoData | date: 'dd/MM/yy HH:mm' }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </swiper>
        </nz-col>
    </nz-row>
    <nz-row [nzGutter]="24">
        <nz-col [nzXl]="7" [nzMd]="12" [nzSm]="24" [nzXs]="24">
            <nz-card nzTitle="Obrigações Concluídas"
                     [ngClass]="!cardLoadingObrigacoesConcluidas ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingObrigacoesConcluidas">
                <div nz-row style="height: 15.6em;">
                    <div nz-col [nzSpan]="13" class="d-flex align-items-center">
                        <highcharts-chart
                                [Highcharts]="HighchartsGauge"
                                [options]="chartGaugeOptions"
                                [(update)]="updateFlag"
                                *ngIf="HighchartsGauge && chartGaugeOptions"

                                style="width: 100%; height: 180px; display: block;"
                        ></highcharts-chart>
                    </div>
                    <div nz-col [nzSpan]="11">
                        <ul nz-list nzBordered nzSize="small" style="margin: 10px;">
                            <nz-list-item *ngFor="let item of tableObrigacoesConcluidas">
                                <a nz-button nzType="link" style="padding-left: 0 !important;"
                                   (click)="filtrarConcluidas(item.key)">
                                    {{ item.label }}
                                </a>
                                <p class="mb0">{{ item.itens.real }}/{{ item.itens.total }}</p>
                            </nz-list-item>
                        </ul>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzXl]="7" [nzMd]="12" [nzSm]="24" [nzXs]="24">
            <nz-card nzTitle="Status"
                     [ngClass]="!cardLoadingStatus ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingStatus">
                <div nz-row style="height: 15.6em;">
                    <div nz-col [nzSpan]="12" class="d-flex align-items-center">
                        <highcharts-chart
                                [Highcharts]="HighchartsPie"
                                [options]="chartPieOptions"
                                [(update)]="updateFlag"
                                *ngIf="HighchartsPie && chartPieOptions"
                                style="width: 100%; height: 180px; display: block;"
                        ></highcharts-chart>
                    </div>
                    <div class="status-calendar" [nzSpan]="12" nz-col style="overflow-y: auto; max-height: 200px">
                        <ul nz-list nzBordered nzSize="small" style="margin: 10px;">
                            <nz-list-item class="ant-card-hoverable" (click)="filtrar('status', 'P')">
                                <h5 class="custom-card-value mb0">
                                    <i class="fas fa-circle" style="color: #ff9149;"></i> Pendentes
                                </h5>
                                <a class="mb0">{{ tableStatus.qtdPendentes }}</a>
                            </nz-list-item>
                            <nz-list-item class="ant-card-hoverable" (click)="filtrar('status', 'A')">
                                <h5 class="custom-card-value mb0">
                                    <i class="fas fa-circle" style="color: #ff4961;"></i> Atrasadas
                                </h5>
                                <a class="mb0" (click)="filtrar('status', 'A')">{{ tableStatus.qtdAtrasados }}</a>
                            </nz-list-item>
                            <nz-list-item class="ant-card-hoverable" (click)="filtrar('status', 'C')">
                                <h5 class="custom-card-value mb0">
                                    <i class="fas fa-circle" style="color: #1565c0;"></i> Concluídas
                                </h5>
                                <a class="mb0" (click)="filtrar('status', 'C')">{{ tableStatus.qtdConcluidos }}</a>
                            </nz-list-item>
                            <nz-list-item class="ant-card-hoverable" (click)="filtrar('status', 'CA')">
                                <h5 class="custom-card-value mb0">
                                    <i class="fas fa-circle" style="color: #666ee8;"></i> Concluídas com Atraso
                                </h5>
                                <a class="mb0"
                                   (click)="filtrar('status', 'CA')">{{ tableStatus.qtdConcluidoAtrasadas }}</a>
                            </nz-list-item>

                            <nz-list-item class="ant-card-hoverable" (click)="filtrar('status', 'D')">
                                <h5 class="custom-card-value mb0">
                                    <i class="fas fa-circle" style="color: #7a7a7a;"></i> Dispensado
                                </h5>
                                <a class="mb0"
                                   (click)="filtrar('status', 'D')">{{ tableStatus.qtdDispensada }}</a>
                            </nz-list-item>

                            <nz-list-item>
                                <h5 class="custom-card-value mb0">
                                    Total
                                </h5>
                                <a class="mb0" style="cursor: default">{{ tableStatus.qtdTotal }}</a>
                            </nz-list-item>
                        </ul>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzXl]="5" [nzMd]="12" [nzSm]="24" [nzXs]="24">
            <nz-card nzTitle="Confirmações"
                     [ngClass]="!cardLoadingConfirmacoesAutomaticas ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingConfirmacoesAutomaticas"
                     [nzBodyStyle]="{'height': '15.6em'}">
                <div nz-card-grid style="width: 50%; height: 50%; text-align: center; padding: 15px;"
                     (click)="filtrar('confirmacoes', 'N');">
                    <nz-content>
                        <nz-row>
                            <nz-col [nzSpan]="12" class="d-flex align-items-center">
                                <i class="fa-regular fa-circle" style="font-size: 24px; color: #c7c7c7"></i>
                            </nz-col>
                            <nz-col [nzSpan]="12" class="d-flex justify-content-center align-items-center"
                                    style="flex-direction: column;">
                                <div>
                                    <a nz-button nzType="link">{{ tableConfirmacoesAutimaticas.qtdNaoConcluidas }}</a>
                                </div>
                                <h5 style="height: 36px; margin-bottom: .2em;">À Confirmar</h5>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </div>
                <div nz-card-grid style="width: 50%; height: 50%; text-align: center; padding: 15px;"
                     (click)="filtrar('confirmacoes', 'R');">
                    <nz-content>
                        <nz-row>
                            <nz-col [nzSpan]="12" class="d-flex align-items-center">
                                <i class="fas fa-times" style="font-size: 24px; color: #f44336;"></i>
                            </nz-col>
                            <nz-col [nzSpan]="12" class="d-flex justify-content-center align-items-center"
                                    style="flex-direction: column;">
                                <div>
                                    <a nz-button nzType="link">{{ tableConfirmacoesAutimaticas.qtdRejeitadas }}</a>
                                </div>
                                <h5 style="line-height: 3.1;height: 36px; margin-bottom: .2em;">Rejeitadas</h5>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </div>
                <div nz-card-grid style="width: 50%; height: 50%; text-align: center; padding: 15px;"
                     (click)="filtrar('confirmacoes', 'A');">
                    <nz-content>
                        <nz-row>
                            <nz-col [nzSpan]="12" class="d-flex align-items-center">
                                <i class="fas fa-sync" style="font-size: 24px; color: #666ee8;"></i>
                            </nz-col>
                            <nz-col [nzSpan]="12" class="d-flex justify-content-center align-items-center"
                                    style="flex-direction: column;">
                                <div>
                                    <a nz-button nzType="link">{{ tableConfirmacoesAutimaticas.qtdVerificando }}</a>
                                </div>
                                <h5 style="height: 36px; margin-bottom: .2em;">Em Confirmação</h5>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </div>
                <div nz-card-grid style="width: 50%; height: 50%; text-align: center; padding: 15px;"
                     (click)="filtrar('confirmacoes', 'C');">
                    <nz-content>
                        <nz-row>
                            <nz-col [nzSpan]="12" class="d-flex align-items-center">
                                <i class="fa-regular fa-circle-check"
                                   style="font-size: 24px; color: #28d094 !important;"></i>
                            </nz-col>
                            <nz-col [nzSpan]="12" class="d-flex justify-content-center align-items-center"
                                    style="flex-direction: column;">
                                <div>
                                    <a nz-button nzType="link">{{ tableConfirmacoesAutimaticas.qtdConfirmadas }}</a>
                                </div>
                                <h5 style="line-height: 3.1;height: 36px; margin-bottom: .2em;">Confirmadas</h5>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzXl]="5" [nzMd]="12" [nzSm]="24" [nzXs]="24">
            <nz-card nzTitle="Eficiência"
                     [nzExtra]="extraCardEficienca"
                     [ngClass]="!cardLoadingEficiencia ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingEficiencia"
                     [nzBodyStyle]="{'height': '15.6em'}">
                <ng-template #extraCardEficienca>
                    <span nz-icon nzType="question-circle" nzTheme="outline"
                          [nz-tooltip]="extraTooltipEficiencia"></span>
                </ng-template>
                <ng-template #extraTooltipEficiencia>
                    Total de obrigações {{ graficoEficiencia.totalObrigacoes }}. <br>
                    Concluídas no prazo {{ graficoEficiencia.totalConcluidas }}. <br>
                    A eficiência considera o total de obrigações concluídas dentro do prazo.
                </ng-template>
                <nz-content>
                    <nz-row>
                        <nz-col [nzSpan]="24">
                            <highcharts-chart
                                    [Highcharts]="HighchartsSolidGauge"
                                    [options]="chartSolidGaugeOptions"
                                    [(update)]="updateFlag"
                                    *ngIf="HighchartsSolidGauge && chartSolidGaugeOptions"
                                    style="width: 100%; height: 100px; display: block;"
                            ></highcharts-chart>
                        </nz-col>
                    </nz-row>
                    <nz-row>
                        <nz-col [nzSpan]="24">
                            <highcharts-chart
                                    [Highcharts]="HighchartsColumn"
                                    [options]="chartColumnOptions"
                                    [(update)]="updateFlag"
                                    *ngIf="HighchartsColumn && chartColumnOptions"
                                    style="width: 100%; height: 100px; display: block;"
                            ></highcharts-chart>
                        </nz-col>
                    </nz-row>
                </nz-content>
            </nz-card>
        </nz-col>
    </nz-row>
    <nz-row [nzGutter]="24">
        <nz-col [nzXl]="7" [nzMd]="24" [nzSm]="24" [nzXs]="24">
            <nz-card nzTitle="Obrigações"
                     [ngClass]="!cardLoadingObrigacoes ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingObrigacoes"
                     [nzExtra]="dimissCardExtraObrigacoes">
                <nz-table #tableObrigacoes [nzData]="arrayTableObrigacoesFront"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          nzSize="small"
                          style="width: 100%;">
                    <thead>
                    <tr>
                        <th style="text-align: center; max-width: 55%">Obrigação</th>
                        <th style="text-align: center; width: auto">Unidades</th>
                        <th style="text-align: center;" nzWidth="100px">Qtde</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableObrigacoes.data">
                        <td style="max-width: 120px">
                            <a style="width: 100%; word-break: break-word;"
                               (click)="filtrar('obrigacao_id', item.obrigacao_id)">{{ item.obrigacao }}</a>
                        </td>
                        <td style="text-align: center;">{{ item.unidades }}</td>
                        <td style="text-align: right;">
                            <div nzTooltipTitle="{{item?.quantidadeConcluidas}} concluídas de {{item?.quantidadeObrigacao}}"
                                 nz-tooltip>
                                <nz-progress
                                        [nzPercent]="item?.porcentagem"

                                >
                                </nz-progress>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card nzTitle="Não Atribuídos"
                     [ngClass]="!cardLoadingNaoAtribuidos ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingNaoAtribuidos"
                     [nzExtra]="dimissCardExtra">
                <nz-content style="padding: 15px 0;">
                    <nz-row>
                        <nz-col [nzSpan]="12" style="text-align: center; border-right: 1px solid #f0f0f0;">
                            <nz-content>
                                <nz-row>
                                    <nz-col nzSpan="24">
                                        <i class="far fa-building" style="color: #1e9ff2; font-size: 20px;"></i>
                                    </nz-col>
                                </nz-row>
                                <nz-row>
                                    <nz-col nzSpan="24">
                                        <a nz-button nzType="link"
                                           (click)="filtrar('', '');">Unidades</a>
                                    </nz-col>
                                </nz-row>
                                <nz-row>
                                    <nz-col nzSpan="16">
                                        <nz-progress [nzPercent]="naoAtribuidoPorcUnidades"
                                                     [nzShowInfo]="false"
                                                     style="margin-left: 10%; width: 90%; display: block;"
                                        ></nz-progress>
                                    </nz-col>
                                    <nz-col nzSpan="8">
                                        <span class="spanProgress">{{ minNaoAtribuidoUnidades }}
                                            /{{ maxNaoAtribuidoUnidades }}</span>
                                    </nz-col>
                                </nz-row>
                            </nz-content>
                        </nz-col>
                        <nz-col [nzSpan]="12" style="text-align: center;">
                            <nz-content>
                                <nz-row>
                                    <nz-col nzSpan="24">
                                        <i class="far fa-calendar-alt" style="color: #1e9ff2; font-size: 20px;"></i>
                                    </nz-col>
                                </nz-row>
                                <nz-row>
                                    <nz-col nzSpan="24">
                                        <a nz-button nzType="link"
                                           (click)="filtrar('', '');">Obrigações</a>
                                    </nz-col>
                                </nz-row>
                                <nz-row>
                                    <nz-col nzSpan="16">
                                        <nz-progress [nzPercent]="naoAtribuidoPorcObrigacoes"
                                                     [nzShowInfo]="false"
                                                     style="margin-left: 10%; width: 90%; display: block;"
                                        ></nz-progress>
                                    </nz-col>
                                    <nz-col nzSpan="8">
                                        <span class="spanProgress">{{ minNaoAtribuidoObrigacoes }}
                                            /{{ maxNaoAtribuidoObrigacoes }}</span>
                                    </nz-col>
                                </nz-row>
                            </nz-content>
                        </nz-col>
                    </nz-row>
                </nz-content>
            </nz-card>
            <nz-card nzTitle="Equipes"
                     [ngClass]="!cardLoadingEquipes ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingEquipes"
                     [nzExtra]="dimissCardExtra">
                <nz-table #tableEquipes [nzData]="arrayTableEquipes"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          [nzScroll]="{ x: '240px'}"
                          nzSize="small">
                    <thead>
                    <tr>
                        <th style="text-align: center;"></th>
                        <th style="text-align: center;" nzWidth="120px">Unidades</th>
                        <th style="text-align: center;" nzWidth="120px">Obrigações</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableEquipes.data">
                        <td style="text-align: center;">
                            <div>
                                <i class="fas fa-users"></i>
                            </div>
                            <a nz-button nzType="link" style="white-space: nowrap"
                               [nz-tooltip]="item.equipe?.length > 40 ? item.equipe : ''"
                               (click)="filtrar('equipe_id', item.equipe_id);">{{ abrevEquipe(item.equipe) }}</a>
                        </td>
                        <td style="text-align: center;white-space: nowrap;">
                            <div>
                                <nz-progress [nzPercent]="item.porcUnidades"
                                             [nzShowInfo]="false"
                                ></nz-progress>
                                <div>
                                    <span class="spanProgress">{{ item.unidades.min }}/{{ item.unidades.max }}</span>
                                </div>
                            </div>
                        </td>
                        <td style="text-align: center;white-space: nowrap;">
                            <div>
                                <nz-progress [nzPercent]="item.porcObrigacoes"
                                             [nzShowInfo]="false"
                                ></nz-progress>
                                <div>
                                    <span class="spanProgress">{{ item.obrigacoes.min }}
                                        /{{ item.obrigacoes.max }}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card nzTitle="Responsáveis"
                     [ngClass]="!cardLoadingResponsaveis ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingResponsaveis"
                     [nzExtra]="dimissCardExtra">
                <nz-table #tableResponsaveisUsuariosTop20 [nzData]="arrayTableResponsaveis"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          nzSize="small">
                    <thead>
                    <tr>
                        <th style="text-align: center;"></th>
                        <th style="text-align: center;" nzWidth="110px">Unidades</th>
                        <th style="text-align: center;">Obrigações</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableResponsaveisUsuariosTop20.data; let i = index">
                        <td style="text-align: center; white-space: normal">
                            <div>
                                <nz-avatar nzText="{{item.iniciais}}"
                                           style="color: #ffffff;"
                                           [ngStyle]="{'background-color': arrayColors[i]}"
                                ></nz-avatar>
                            </div>
                            <a nz-button nzType="link"
                               style="padding: 0;white-space: normal"
                               (click)="filtrar('responsavel_id', item.id);">
                                {{ item.nome }}
                            </a>
                        </td>
                        <td style="text-align: center;white-space: nowrap;">
                            <div>
                                <nz-progress [nzPercent]="item.porcUnidades"
                                             [nzShowInfo]="false"
                                ></nz-progress>
                                <div>
                                    <span class="spanProgress">{{ item.unidades.min }}/{{ item.unidades.max }}</span>
                                </div>
                            </div>
                        </td>
                        <td style="text-align: center;white-space: nowrap;">
                            <div>
                                <nz-progress [nzPercent]="item.porcObrigacoes"
                                             [nzShowInfo]="false"
                                ></nz-progress>
                                <div>
                                         <span class="spanProgress">{{ item.obrigacoes.min }}
                                             /{{ item.obrigacoes.max }}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
        </nz-col>
        <nz-col [nzXl]="17" [nzMd]="24" [nzSm]="24" [nzXs]="24">

            <nz-card nzTitle="Grupo"
                     [nzExtra]="tabsExtra"
                     [ngClass]="[statusCard ? 'card-fullscreen' : '', !cardGrupoLoading ? 'ant-card-table' : '']"
                     [nzLoading]="cardGrupoLoading">
                <nz-table #tableGrupos [nzData]="grupos" class="table-small"
                          [nzFrontPagination]="false"
                >
                    <!--                    [nzShowTotal]="rangeTemplate"-->
                    <thead>
                    <tr>
                        <th></th>
                        <th nzAlign="center">Unidades</th>
                        <th>Agenda</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of tableGrupos.data">
                        <td nzAlign="center">
                            <span style="font-size: 16px;">{{ data.descricao }}</span>
                        </td>
                        <td nzAlign="center">{{ data.qtdUnidades }}</td>
                        <td style="max-width: 550px">
                            <div style="display: grid; grid-template-columns: 49% auto; gap: 40px; max-width: 550px">
                                <div>
                                    <div class="d-flex justify-content-between"
                                         (click)="filtrarCalendar('status','P', data.id)">
                                        <a>Pendentes</a>
                                        <a>
                                            {{ data.qtdPendentes }}
                                            <i class="fas fa-circle"
                                               style="color: #ff9149 !important;; font-size:10px;"></i>
                                        </a>
                                    </div>
                                    <div class="d-flex justify-content-between"
                                         (click)="filtrarCalendar('status','A', data.id)">
                                        <a>Atrasadas</a>
                                        <a>
                                            {{ data.qtdAtrasadas }}
                                            <i class="fas fa-circle text-grey"
                                               style="color: #ff4961 !important; font-size:10px;"></i>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <div class="d-flex justify-content-between"
                                         (click)="filtrarCalendar('status','C', data.id)">
                                        <a>Concluídas</a>
                                        <a>
                                            {{ data.qtdConcluidos }}
                                            <i class="fas fa-circle"
                                               style="color: #1565c0 !important; font-size:10px;"></i>
                                        </a>
                                    </div>
                                    <div class="d-flex justify-content-between"
                                         (click)="filtrarCalendar('status','CA', data.id)">
                                        <a>Concluídas com Atraso</a>
                                        <a>
                                            {{ data.qtdConcluidoAtrasadas }}
                                            <i class="fas fa-circle" style="color: #666ee8; font-size:10px;"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
                <ng-template #tabsExtra>
                    <div class="d-flex">
                        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                            <input (keydown.enter)="buscar()" type="text" nz-input placeholder="Busca"
                                   [(ngModel)]="currentSearch"/>
                        </nz-input-group>
                        <ng-template #suffixIconButton>
                            <button nz-button nzType="primary" nzSearch
                                    (click)="buscar();"><i
                                    nz-icon nzType="search"></i></button>
                        </ng-template>
                        <button nz-button nzType="default" (click)="resetSearch();" style="margin-right: 10px;">
                            Limpar
                        </button>
                        <button nz-button [nzType]="'default'" (click)="clickEvent()">
                            <i class="fas" [ngClass]="!statusCard ? 'fa-expand' : 'fa-compress'"></i>
                        </button>
                    </div>
                </ng-template>
                <ng-template #rangeTemplate let-range="range" let-total>
                    {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                </ng-template>
            </nz-card>
        </nz-col>
    </nz-row>
</nz-content>
<ng-template #dimissCardExtra>
    <a nz-button [nzType]="'link'" style="color: rgba(0,0,0,.85)" (click)="cardCompress($event.target)"
       [nzSize]="'small'">
        <i nz-icon nzType="plus" class="d-none" nzTheme="outline"></i>
        <i nz-icon nzType="minus" nzTheme="outline"></i>
    </a>
</ng-template>

<ng-template #dimissCardExtraObrigacoes>

    <div style="display: flex">
        <div style="max-width: 200px">
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" [nzSuffix]="inputClearTpl"
                            style="margin-right: 10px;">
                <input (keyup)="buscarFront()" type="text" nz-input placeholder="Busca"
                       [(ngModel)]="this.currentSearchObrigacoes"/>
            </nz-input-group>
            <ng-template #suffixIconButton>
                <button nz-button nzType="primary" nzSearch
                        (click)="buscarFront();"><i
                        nz-icon nzType="search"></i></button>
            </ng-template>
            <ng-template #inputClearTpl>
      <span
              nz-icon
              class="ant-input-clear-icon"
              nzTheme="fill"
              nzType="close-circle"
              *ngIf="this.currentSearchObrigacoes"
              (click)="buscarFront(true)"
      ></span>
            </ng-template>
        </div>

        <a nz-button [nzType]="'link'" style="color: rgba(0,0,0,.85)" (click)="cardCompress($event.target)"
           [nzSize]="'small'">
            <i nz-icon nzType="plus" class="d-none" nzTheme="outline"></i>
            <i nz-icon nzType="minus" nzTheme="outline"></i>
        </a>
    </div>


</ng-template>
