import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxConfigComponent } from './inboxConfig.component';
import {NgxMaskModule} from 'ngx-mask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgZorroModule } from 'src/app/shared/ng-zorro.module';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';



@NgModule({
  declarations: [
    InboxConfigComponent
  ],
  imports: [
    CommonModule,
    NgZorroModule,
    NgxMaskModule,
    FormsModule,
    ReactiveFormsModule,
    NzPageHeaderModule,
    NzSwitchModule,
    NzDatePickerModule
  ]
})
export class InboxConfigModule { }
