<nz-page-header class="site-page-header" nzTitle="Situacao Fiscal"
                nzSubtitle="Relatório Pendências Fiscais"></nz-page-header>

<nz-content>
    <ng-template #semDados>
        <span>
            -
        </span>
    </ng-template>
    <nz-row nzGutter="24">
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores"
                     [nzBodyStyle]="loadingContadores ? {'padding': '10px 15px'} : {'padding': '0'}">
                <div nz-row class="card-contador">
                    <div class="card-cont-t">
                        <span style="font-size: 1.2em">
                            ICMS Declarado
                        </span>
                    </div>
                    <div class="card-cont-b">
                        <div>
                                <span nz-typography class="valor-tributo"
                                      style="color: rgb(104,26,255);">
                                {{ (contadores.quantidade_icmsDeclarado | number) || '-' }}
                            </span>

                        </div>
                        <div>
                            <nz-progress [nzPercent]="(contadores.porcent_icms_declarado | number: '1.0-0')"
                                         nzType="circle"
                                         [nzWidth]="60"
                                         [nzFormat]="formatOne"
                                         [nzStrokeColor]="'#681AFFFF'"></nz-progress>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>

        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores"
                     [nzBodyStyle]="loadingContadores ? {'padding': '10px 15px'} : {'padding': '0'}">
                <div nz-row class="card-contador">
                    <div class="card-cont-t">
                        <span style="font-size: 1.2em">
                            ICMS Parcelamento
                        </span>
                    </div>
                    <div class="card-cont-b">
                        <div>
                                <span nz-typography class="valor-tributo"
                                      style="color: rgb(255,148,26);">
                                {{ (contadores.quantidade_icmsParcelamento | number) || '-' }}
                            </span>

                        </div>
                        <div>
                            <nz-progress [nzPercent]="(contadores.porcent_icms_parcelamento | number: '1.0-0')"
                                         nzType="circle"
                                         [nzWidth]="60"
                                         [nzFormat]="formatOne"
                                         [nzStrokeColor]="'#FF941AFF'"></nz-progress>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>

        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores"
                     [nzBodyStyle]="loadingContadores ? {'padding': '10px 15px'} : {'padding': '0'}">
                <div nz-row class="card-contador">
                    <div class="card-cont-t">
                        <span style="font-size: 1.2em">
                            IPVA
                        </span>
                    </div>
                    <div class="card-cont-b">
                        <div>
                                <span nz-typography class="valor-tributo"
                                      style="color: rgb(0,158,64);">
                                {{ (contadores.quantidade_ipva | number) || '-' }}
                            </span>

                        </div>
                        <div>
                            <nz-progress [nzPercent]="(contadores.porcent_ipva | number: '1.0-0')"
                                         nzType="circle"
                                         [nzWidth]="60"
                                         [nzFormat]="formatOne"
                                         [nzStrokeColor]="'#009E40FF'"></nz-progress>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>

        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores"
                     [nzBodyStyle]="loadingContadores ? {'padding': '10px 15px'} : {'padding': '0'}">
                <div nz-row class="card-contador">
                    <div class="card-cont-t">
                        <span style="font-size: 1.2em">
                            ITCMD
                        </span>
                    </div>
                    <div class="card-cont-b">
                        <div>
                                <span nz-typography class="valor-tributo"
                                      style="color: rgb(237,205,7);">
                                {{ (contadores.quantidade_itcmd | number) || '-' }}
                            </span>

                        </div>
                        <div>
                            <nz-progress [nzPercent]="(contadores.porcent_itcmd | number: '1.0-0')"
                                         nzType="circle"
                                         [nzWidth]="60"
                                         [nzFormat]="formatOne"
                                         [nzStrokeColor]="'#EDCD07FF'"></nz-progress>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>

        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores"
                     [nzBodyStyle]="loadingContadores ? {'padding': '10px 15px'} : {'padding': '0'}">
                <div nz-row class="card-contador">
                    <div class="card-cont-t">
                        <span style="font-size: 1.2em">
                            AIIM
                        </span>
                    </div>
                    <div class="card-cont-b">
                        <div>
                                <span nz-typography
                                      class="valor-tributo"
                                      style="color: rgb(198,23,112);">
                                {{ (contadores.quantidade_aimm | number) || '-' }}
                            </span>

                        </div>
                        <div>
                            <nz-progress [nzPercent]="(contadores.porcent_aimm | number: '1.0-0')"
                                         nzType="circle"
                                         [nzWidth]="60"
                                         [nzFormat]="formatOne"
                                         [nzStrokeColor]="'#C61770FF'"></nz-progress>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>

        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores"
                     [nzBodyStyle]="loadingContadores ? {'padding': '10px 15px'} : {'padding': '0'}">
                <div nz-row class="card-contador">
                    <div class="card-cont-t">
                        <span style="font-size: 1.2em">
                            ICMS Pendência
                        </span>
                    </div>
                    <div class="card-cont-b">
                        <div>
                                <span nz-typography class="valor-tributo"
                                      style="color: rgb(9,110,175);">
                                {{ (contadores.quantidade_icmsPendencia| number) || '-' }}
                            </span>

                        </div>
                        <div>
                            <nz-progress [nzPercent]="(contadores.porcent_icms_pendencia | number: '1.0-0')"
                                         nzType="circle"
                                         [nzWidth]="60"
                                         [nzFormat]="formatOne"
                                         [nzStrokeColor]="'#096EAFFF'"></nz-progress>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>

    </nz-row>
    <nz-card [ngClass]="[!loadingTabela ? 'ant-card-table' : '', expand ? 'card-fullscreen' : '']"
             [nzLoading]="loadingTabela"
             [nzBodyStyle]="loadingTabela ? {'overflow' : 'hidden', 'padding':'15px'} : {'padding': '5px 0'}"
             [nzExtra]="navBar">
        <ng-template #navBar>
            <div class="d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button disabled="{{loadingTabela}}" [nzDropdownMenu]="menuAcoes" style="margin-right: 10px;"
                        class="centralized-icon" nz-button nz-dropdown
                        nzType="default">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes>
                    <ul nz-menu>
                        <li [nzDisabled]="loadingExportarPlanilha || items.length === 0" nz-menu-item
                            (click)="!loadingExportarPlanilha && items.length > 0 ? modalExportarPlanilha(true) : '';">
                            {{ !loadingExportarPlanilha ? 'Exportar Planilha' : 'Exportando planilha...' }}
                        </li>
                        <li [nzDisabled]="loadingExportarPDF || items.length === 0" nz-menu-item
                            (click)="!loadingExportarPDF && items.length > 0 ? exportarPdfs() : '';">
                            {{ !loadingExportarPDF ? 'Exportar PDF' : 'Exportando PDFs...' }}
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="getTabela(currentParams, currentSearch);" type="text" nz-input
                           placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="getTabela(currentParams, currentSearch);"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="expandEvent()" style="padding: 4px 8px;">
                    <i class="fas" [ngClass]="!expand ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>

        <nz-table #basicTable
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="listByTable($event)"
                  [nzFooter]="footer"
                  class="table-small">
            <thead>
            <tr>
                <th nzAlign="center" style="min-width: 180px" nzColumnKey="nome" [nzSortFn]="true">Unidade</th>
                <th nzAlign="center" nzColumnKey="icmsDeclarado" [nzSortFn]="true">ICMS Declarado</th>
                <th nzAlign="center" nzColumnKey="icmsParcelamento" [nzSortFn]="true">ICMS Parcelamento</th>
                <th nzAlign="center" nzColumnKey="ipva" [nzSortFn]="true">IPVA</th>
                <th nzAlign="center" nzColumnKey="itcmd" [nzSortFn]="true">ITCMD</th>
                <th nzAlign="center" nzColumnKey="aimm" [nzSortFn]="true">AIIM</th>
                <th nzAlign="center" nzColumnKey="icmsPendencia" [nzSortFn]="true">ICMS Pendência</th>
                <th nzAlign="center" [nzSortFn]="true">Último Rel. Pendência
                </th>
                <th nzAlign="center" nzColumnKey="ultimoProcessamento" [nzSortFn]="true">Última Emissão</th>
                <th nzAlign="center" nzColumnKey="proximaConsulta" [nzSortFn]="true">Próxima Consulta</th>
                <th nzAlign="center" colSpan="2"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data">
                <td>{{ item.nome || '-' }}
                    <br><small>({{ (item.cnpj | cpfCnpj) || '-' }})</small></td>
                <td nzAlign="center">
                    <i [nz-tooltip]="'Com pendência'" *ngIf="item.icmsDeclarado else semDados"
                       class="fa-solid fa-xmark"></i>
                </td>
                <td nzAlign="center">
                    <i [nz-tooltip]="'Com pendência'" *ngIf="item.icmsParcelamento else semDados"
                       class="fa-solid fa-xmark"></i>
                </td>
                <td nzAlign="center">
                    <i [nz-tooltip]="'Com pendência'" *ngIf="item.ipva else semDados" class="fa-solid fa-xmark"></i>
                </td>
                <td nzAlign="center">
                    <i [nz-tooltip]="'Com pendência'" *ngIf="item.itcmd else semDados" class="fa-solid fa-xmark"></i>
                </td>
                <td nzAlign="center">
                    <i [nz-tooltip]="'Com pendência'" *ngIf="item.aimm else semDados" class="fa-solid fa-xmark"></i>
                </td>
                <td nzAlign="center">
                    <i [nz-tooltip]="'Com pendência'" *ngIf="item.icms_pendencia else semDados"
                       class="fa-solid fa-xmark"></i>
                </td>
                <td nzAlign="center">
                    <div style="align-items: center; width: 100%">
                        <a [nzLoading]="loadingVerRelatorio[item.id]"
                           nz-button nzType="link" nz-tooltip="Visualizar"
                           (click)="visualizarRelatorio(item)">
                            <i class="fa-regular fa-file-pdf"></i>
                        </a>
                    </div>
                </td>
                <td nzAlign="center">
                    <div style="min-width: 160px">
                        {{ (item.ultimoProcessamento | date: 'dd/MM/yyyy HH:mm') || '-' }}
                    </div>
                </td>
                <td nzAlign="center">
                    <div style="min-width: 160px">
                        <span *ngIf="item.ultimoProcessamentoLog else dataProximaConsulta"
                              style="margin-left: 10px; color: #ff4d4f; font-size: 1.2em"
                              [nz-tooltip]="item.ultimoProcessamentoLog" nz-icon nzType="exclamation-circle"
                              nzTheme="outline"></span>
                        <ng-template #dataProximaConsulta>
                            {{ item?.proximaConsulta | date: 'dd/MM/yyyy HH:mm' }}
                        </ng-template>
                    </div>
                </td>
                <td style="white-space: nowrap;">

                    <button *ngIf="currentUser?.origem === 'console'"
                            nz-button nzType="link"
                            nzTooltipTitle="Reprocessar Registro (user-console)" nz-tooltip
                            [nzLoading]="processamentoFlag[item.empresa_id]"
                            (click)="showConfirm(item)">
                        <i nz-icon nzType="reload" nzTheme="outline"
                           [nzSpin]="processamentoFlag[item.empresa_id]"></i>
                    </button>
                </td>
                <td nzAlign="center">
                    <a nz-button nzType="link" [nzLoading]="loadingHistorico[item.cnpj]"
                       (click)="getHistorico(item.cnpj)">Histórico</a>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{ pagination?.from }}-{{ pagination?.to }}
                    de {{ pagination?.total }} registros</span>
        </ng-template>
    </nz-card>
</nz-content>

<nz-modal (nzOnCancel)="showModalRalatorio( false)"
          [nzBodyStyle]="{'padding' : '0'}"
          [(nzVisible)]="modalRelatorioVisible"
          [nzStyle]="{ top: screenModalTop + 'px' }"
          [nzWidth]="screenWidth"
          nzTitle="Ralatório"
          class="modal-table">
    <ng-container *nzModalContent>
        <iframe [ngStyle]="{height: screenHeight + 'px', width: '100%', border: 'none'}"
                [src]="iframeUrl"
                title=""></iframe>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalRalatorio(false)">Fechar</button>
        <button nz-button nzType="primary" (click)="onClickDownloadPdf()">Download</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="visible.filtrar"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="1100">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltros">
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Unidade</nz-form-label>
                    <nz-form-control [nzSpan]="16" size="60" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.value.empresaId">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresaId">
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="opt.descricao + ' (' + (opt.empresa_cnpj | cpfCnpj) + ')'"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Status de Pendência</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.value.statusEmissao">
                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="statusEmissao">
                            <nz-option [nzLabel]="'Com pendências'" [nzValue]="'1'"></nz-option>
                            <nz-option [nzLabel]="'Sem pendências'" [nzValue]="'0'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Última Emissão</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.value.dataUltimaEmissao">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="dataUltimaEmissao" style="width: 100%;"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="visible.exportPlanilha"
          [nzTitle]="'Exportar Planilha'" [nzClosable]="true"
          (nzOnCancel)="modalExportarPlanilha(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [nzAutoTips]="autoTips" [formGroup]="formExportar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportarPlanilha(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadingExportarPlanilha" (click)="confirmaExportar()">Exportar
        </button>
    </div>
</nz-modal>

<nz-modal (nzOnCancel)="showModalHistorico(false)"
          [nzBodyStyle]="{'padding' : '0'}"
          [(nzVisible)]="visible.modalHistorico"
          [nzWidth]="1100"
          nzTitle="Histórico"
          class="modal-table">
    <ng-container *nzModalContent>
        <nz-table #basicTable
                  [nzData]="itemsHistorico"
                  [nzFrontPagination]="false"
                  (nzQueryParams)="listHistoricoByTable($event)"
                  class="table-small"
                  style="overflow: auto">
            <thead>
            <tr>
                <th nzAlign="center" nzColumnKey="processamentoFim" [nzSortFn]="true">Data Emissão</th>
                <th nzAlign="center" nzColumnKey="icmsDeclarado" [nzSortFn]="true">ICMD Declarado</th>
                <th nzAlign="center" nzColumnKey="icmsParcelamento" [nzSortFn]="true">ICMS Parcelamento</th>
                <th nzAlign="center" nzColumnKey="ipva" [nzSortFn]="true">IPVA</th>
                <th nzAlign="center" nzColumnKey="itcmd" [nzSortFn]="true">ITCMD</th>
                <th nzAlign="center" nzColumnKey="aimm" [nzSortFn]="true">AIIM</th>
                <th nzAlign="center" nzColumnKey="icmsPendencia" [nzSortFn]="true">ICMS Pendência</th>
                <th nzAlign="center"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data">
                <td nzAlign="center">
                    <div style="min-width: 160px">
                        {{ (item.processamentoFim | date: 'dd/MM/yyyy HH:mm') || '-' }}
                    </div>
                </td>
                <td nzAlign="center">
                    <i [nz-tooltip]="'Com pendência'" *ngIf="item.icmsDeclarado else semDados"
                       class="fa-solid fa-xmark"></i>
                </td>
                <td nzAlign="center">
                    <i [nz-tooltip]="'Com pendência'" *ngIf="item.icmsParcelamento else semDados"
                       class="fa-solid fa-xmark"></i>
                </td>
                <td nzAlign="center">
                    <i [nz-tooltip]="'Com pendência'" *ngIf="item.ipva else semDados" class="fa-solid fa-xmark"></i>
                </td>
                <td nzAlign="center">
                    <i [nz-tooltip]="'Com pendência'" *ngIf="item.itcmd else semDados" class="fa-solid fa-xmark"></i>
                </td>
                <td nzAlign="center">
                    <i [nz-tooltip]="'Com pendência'" *ngIf="item.aimm else semDados" class="fa-solid fa-xmark"></i>
                </td>
                <td nzAlign="center">
                    <i [nz-tooltip]="'Com pendência'" *ngIf="item.icms_pendencia else semDados"
                       class="fa-solid fa-xmark"></i>
                </td>
                <td nzAlign="center">
                    <button (click)="visualizarRelatorio(item)" nz-button
                            nzType="link"
                            nz-tooltip="true" nzTooltipTitle="PDF">
                        Ver
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalHistorico(false)">Fechar</button>
    </div>
</nz-modal>
