import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgZorroModule} from '../../../../shared/ng-zorro.module';
import {NgxMaskModule} from 'ngx-mask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {UiModule} from '../../../ui.module';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzAffixModule} from 'ng-zorro-antd/affix';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {ConfiguracaoSsoComponent} from './configuracao-sso.component';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzResultModule} from 'ng-zorro-antd/result';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {ExportarTabelaModule} from '@components/exportar-tabela/exportar-tabela.module';
import {NzSwitchModule} from "ng-zorro-antd/switch";

@NgModule({
    declarations: [
        ConfiguracaoSsoComponent
    ],
    exports: [
        ConfiguracaoSsoComponent
    ],
    imports: [
        CommonModule,
        NgZorroModule,
        NgxMaskModule,
        ReactiveFormsModule,
        NzRadioModule,
        UiModule,
        NzBreadCrumbModule,
        NzTabsModule,
        NzListModule,
        NzPageHeaderModule,
        NzAffixModule,
        NzTypographyModule,
        NzDatePickerModule,
        FormsModule,
        NzTagModule,
        NzCheckboxModule,
        NzSpaceModule,
        NzAlertModule,
        NzBadgeModule,
        NzResultModule,
        NzUploadModule,
        NzSkeletonModule,
        ExportarTabelaModule,
        NzSwitchModule
    ]
})
export class ConfiguracaoSsoModule {
}
