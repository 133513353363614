<nz-page-header class="site-page-header" nzTitle="Obrigações" nzSubtitle="Regras">
</nz-page-header>
<nz-content>
    <nz-card class="ant-card-table" [nzExtra]="navBar" nzTitle="Regras"
             [nzLoading]="loading"
             [nzBodyStyle]="loading ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
             [ngClass]="[status ? 'card-fullscreen' : 'ant-card-table']">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                        nzType="default" style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="buscar()" type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch
                            (click)="buscar()"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>
        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <th nzColumnKey="obrigacao" [nzSortFn]="true">Obrigação</th>
                <th nzColumnKey="orgao" [nzSortFn]="true">Orgão</th>
                <th nzWidth="150px" nzColumnKey="classificacao" [nzSortFn]="true">Classificação</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let regras of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemChecked(regras.obrigacao_id, $event)"
                    [nzChecked]="checkedItems.has(regras.obrigacao_id)"
                    [nzDisabled]="regras.disabled">
                </td>
                <td nzLeft>{{regras?.obrigacao_id}}</td>
                <td nzLeft>{{regras?.orgao}}</td>
                <td>
                    <div class="d-flex justify-content-between">
                        <span class="text-grey-dark" style="margin-right: 10px;">Graves</span>
                        <div class="d-flex align-items-center">
                            <span style="margin-right: 5px;">{{regras.graves}}</span>
                            <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span class="text-grey-dark" style="margin-right: 10px;">Médias</span>
                        <div class="d-flex justify-content-center align-items-center">
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{regras.medias}}</span>
                                <i class="fas fa-circle" style="color: #dbb807; font-size:10px;"></i>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span class="text-grey-dark" style="margin-right: 10px;">Leves</span>
                        <div class="d-flex align-items-center">
                            <span style="margin-right: 5px;">{{regras.leves}}</span>
                            <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                        </div>
                    </div>
                </td>
                <td nzRight nzAlign="center">
                    <button (click)="openTab('/check/editar-obrigacoes-regras/', regras.obrigacao_id, {obrigacao_id: regras.obrigacao_id, descricao: regras.descricao})"
                            nz-button
                            nzType="default">
                        Editar
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </nz-card>

</nz-content>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Obrigações:</nz-form-label>
                <nz-form-control [nzSpan]="12" nzValidateStatus="success">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="obrigacao_id">
                        <nz-option *ngFor="let option of comboObrigacoes"
                                   [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Orgão:</nz-form-label>
                <nz-form-control [nzSpan]="12" nzValidateStatus="success">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="orgao">
                        <nz-option *ngFor="let option of comboOrgaos"
                                   [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>
