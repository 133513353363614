import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {
    CamposPreviewArquivoServiceService
} from '@components/campos-preview-arquivo/campos-preview-arquivo.service.service';
import {notZero} from '../../validatorsForms';
import {Helpers} from '../../helpers';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

interface Estrutura {
    campos: any[];
    nome: string;
    tipo: string;
    obrigatorio?: boolean;
    valor?: string;
}

@Component({
    selector: 'app-campos-preview-arquivo',
    templateUrl: './campos-preview-arquivo.component.html',
    styleUrls: ['./campos-preview-arquivo.component.scss']
})
export class CamposPreviewArquivoComponent implements OnInit {

    constructor(
        private fb: UntypedFormBuilder,
        private toastrService: ToastrService,
        private arquivoService: CamposPreviewArquivoServiceService,
        public sanitizer: DomSanitizer,
    ) {

        this.formLayout = {
            modalVisible: false,
            formGroup: this.fb.group({})
        };

    }

    @Input() data;

    iframeUrl: SafeResourceUrl = '';
    formLayout: FormStack;
    camposLayout = [];
    camposResponse: any = [];

    loadings = {
        dados: true,
        gravando: false
    };

    semDados = false;

    sessaoesParaExibir = {};

    protected readonly Helpers = Helpers;

    ngOnInit() {
        if (this.data?.arquivoId) {

            this.arquivoService.retornaArquivo(this.data.arquivoId).subscribe({
                next: async (response) => {

                    if (!response || Object.keys(response).length === 0 || !response.estrutura) {
                        this.toastrService.warning('Sem dados para exibir');
                        this.loadings.dados = false;
                        this.semDados = true;
                        return;
                    }

                    const estrutura = JSON.parse(response.estrutura);

                    this.camposResponse = response;
                    this.camposResponse.estruturaToUpdate = estrutura.estrutura;

                    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + estrutura.conteudo);

                    const formItens = await this.montaCamposLayout(estrutura.estrutura);

                    this.formLayout.formGroup = this.fb.group({});

                    this.formLayout.formGroup = this.fb.group(formItens);

                    Object.values(this.formLayout.formGroup.controls).forEach(control => {

                        if (control.invalid) {
                            control.markAsDirty();
                            control.updateValueAndValidity({onlySelf: true});
                        }

                    });

                    this.loadings.dados = false;

                },
                error: (err: any) => {

                    this.loadings.dados = false;
                    this.toastrService.error(err.error.message);

                }
            });
        }
    }

    gravarArquivo() {

        if (this.formLayout.formGroup.valid) {

            this.loadings.gravando = true;

            const itens = Object.entries(this.formLayout.formGroup.value).filter((item) => {
                return item[1] !== null;
            });

            const data: any = {};
            data.json = {};

            itens.forEach((item) => {
                    const chave = item[0].split('|');

                    if (chave[1]) {
                        const campo = this.camposResponse.estruturaToUpdate.filter(j => j.nome === chave[1]);

                        const cToUpdate = campo[0].campos.filter(c => c.nome === chave[0])?.[0];
                        cToUpdate.valor = item[1];
                    } else {
                        const campo = this.camposResponse.estruturaToUpdate.filter(j => j.nome === chave[0]);

                        const cToUpdate = campo[0];
                        cToUpdate.valor = item[1];
                    }
                }
            );

            data.tipo_id = this.camposResponse.tipo_id;
            data.json.processoId = JSON.parse(this.camposResponse.estrutura).processoId;
            data.json.estrutura = this.camposResponse.estruturaToUpdate;
            data.json.conteudo = JSON.parse(this.camposResponse.estrutura).conteudo;

            this.arquivoService.gravarArquivo(this.data.arquivoId, data).subscribe(
                {
                    next: (response) => {

                        this.loadings.gravando = false;

                        if (response.message) {
                            this.toastrService.success('Dados atualizados com sucesso!');
                        }

                    },
                    error: (err) => {
                        this.loadings.gravando = false;
                        this.toastrService.error(err.error.message);
                    }
                });

        } else {
            for (const key in this.formLayout.formGroup.controls) {

                if (key) {
                    const campo = this.formLayout.formGroup.get(key);
                    this.toastrService.error('Preencha todos os campos obrigatórios');

                    campo.markAsDirty();
                    campo.updateValueAndValidity();

                }
            }
        }
    }

    montaCamposLayout(e: Estrutura[]) {

        const formItens = {};

        e.forEach(estrutura => {

            const campoParaLayout = {
                nome: estrutura.nome,
                campos: [],
                tipo: '',
                qtdRequired: 0
            };

            console.log(estrutura);

            if (estrutura.nome == 'Tipo de Documento' || estrutura.nome == 'Origem'){
                return;
            }

            if (estrutura.campos) {

                estrutura.campos.forEach(value => {

                    value.nome = value.nome.replace(/\./g, '');

                    formItens[`${value.nome}|${estrutura.nome}`] = [value.valor, this.setValidatorsField(value.nome, value.obrigatorio)];
                    this.formLayout.formGroup.addControl(
                        `${value.nome}|${estrutura.nome}`,
                        new UntypedFormControl(null, this.setValidatorsField(value.nome, value.obrigatorio))
                    );

                    this.formLayout.formGroup.get(`${value.nome}|${estrutura.nome}`).updateValueAndValidity({onlySelf: true});

                    campoParaLayout.campos.push({
                        sessao: estrutura.nome,
                        tipo: 'sessao',
                        nome: `${value.nome}|${estrutura.nome}`,
                        valor: value.valor,
                        obrigatorio: value.obrigatorio,
                    });

                    campoParaLayout.tipo = 'sessao';

                    if (value.obrigatorio) {
                        campoParaLayout.qtdRequired++;
                    }

                });

            } else {
                // campo normal (não uma sessão)
                formItens[estrutura.nome] = [estrutura.valor, null];
                this.formLayout.formGroup.addControl(
                    estrutura.nome,
                    new UntypedFormControl(null, this.setValidatorsField(estrutura.nome, estrutura.obrigatorio))
                );

                campoParaLayout.tipo = 'campo';

                campoParaLayout.campos.push({
                    sessao: estrutura.nome,
                    tipo: 'campo',
                    nome: estrutura.nome,
                    valor: estrutura.valor,
                });
            }

            campoParaLayout.campos.sort((a, b) => {
                if (a.obrigatorio === b.obrigatorio) {
                    return 0;
                }
                return a.obrigatorio ? -1 : 1;
            });

            this.camposLayout.push(campoParaLayout);

        });

        return formItens;
    }

    setValidatorsField(nome, obrigatorio) {
        const validators = [];
        if (obrigatorio) {
            validators.push(Validators.required);
        }

        if (['Total', 'total'].includes(nome)) {
            validators.push(notZero);
        }

        if (validators.length > 0) {
            return validators;
        }

        return null;
    }

    formataVisualizacaoCampo(campo) {
        return campo.split('|')[0];
    }

    mostrarMais(sessao: string) {
        this.sessaoesParaExibir[sessao] = this.sessaoesParaExibir[sessao] ? !this.sessaoesParaExibir[sessao] : true;
    }
}
