import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../core/auth/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {passwordStrength} from 'check-password-strength';
import {AppComponent} from '../../../app.component';

@Component({
    selector: 'app-password-recovery',
    templateUrl: './password-recovery.component.html',
    styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit, AfterViewInit {
    formPasswordRecovey: UntypedFormGroup;
    passwordVisible: boolean;
    confirmPasswordVisible: boolean;

    identificadorUrl: string = null;
    srcLogo: string = null;
    contaNome: string = null;
    loading = false;

    passwordValidate = {
        lowercase: false,
        uppercase: false,
        number: false,
        // symbol: false,
        length: false,
        confirm: true,
    };
    mensagem: string;
    usuarioNome: string;
    usuarioEmail: string;
    usuarioLogin: string;
    backgroundImageUrl;
    logoUrl;
    verifyThemeCount = 0;
    popoverVisibleSenha = false;

    constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute,
                private cdr: ChangeDetectorRef,
                private authService: AuthenticationService, private toastrService: ToastrService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.buildForm();
        this.authService.logout('', false);
        this.route.queryParams.subscribe((params) => this.saveParams(params));

        this.getAccountIdByUrl().then(() => {
        });
    }

    ngAfterViewInit() {
        this.verifyTheme();
    }

    verifyTheme() {

        if (AppComponent.logoUrl && AppComponent.backgroundImageUrl) {
            this.logoUrl = AppComponent.logoUrl;
            this.backgroundImageUrl = AppComponent.backgroundImageUrl;
            this.cdr.detectChanges();
            return;
        }

        if (this.verifyThemeCount > 100) {
            return;
        }

        setTimeout(() => {
            this.verifyThemeCount++;
            this.verifyTheme();
        }, 100);

    }

    saveParams(params) {
        this.formPasswordRecovey.controls.token.setValue(params.token);
        this.formPasswordRecovey.controls.login.setValue(params.login);
        this.formPasswordRecovey.controls.login.disable();

        this.mensagem = params.mensagem ? params.mensagem : null;
        this.usuarioNome = params.nome ? params.nome : null;
        this.usuarioEmail = params.email ? params.email : null;
        this.usuarioLogin = params.login ? params.login : null;
    }

    buildForm() {
        this.formPasswordRecovey = this.fb.group({
            login: ['', Validators.required],
            token: ['', Validators.required],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
        }, {validator: this.mustMatch('password', 'confirmPassword')});
    }

    mustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (!control || !matchingControl) {
                return null;
            }

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return null;
            }

            if (control.value !== matchingControl.value && control.value !== '') {
                matchingControl.setErrors({mustMatch: true});
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    onSubmit() {

        if (this.formPasswordRecovey.valid) {
            this.loading = true;
            this.authService.changePassword(this.usuarioEmail,
                this.formPasswordRecovey.value.token, this.formPasswordRecovey.value.password,
                this.identificadorUrl)
                .subscribe((res: any) => {
                    this.toastrService.success(res.data);
                    this.loading = false;
                    this.router.navigate(['login']);

                }, (res => {
                    this.loading = false;
                    this.toastrService.error(res.error.message);
                }));
        }
    }

    async getAccountIdByUrl() {

        this.identificadorUrl = this.authService.getIdentificadorByUrl(window.location.href);

        this.identificadorUrl = this.authService.getIdentificadorByUrl(window.location.href);

        if (this.identificadorUrl) {
            this.authService.getLogoByIdentificador(this.identificadorUrl).subscribe(res => {
                if (res?.message) {
                    this.srcLogo =
                        'data:image/png;base64,' + res.message;
                } else {
                    this.authService.getContaNomeByIdentificador(this.identificadorUrl).subscribe((response: any) => {
                        if (response?.message) {
                            this.contaNome = response?.message;
                        } else {
                            this.router.navigate(['/conta-nao-encontrada']);
                        }
                    });
                }
            });

        }
    }

    verificaSenha(senha, verificaConfirm = false) {

        this.popoverVisibleSenha = senha != null && senha !== '';
        const validator = passwordStrength(senha);

        const haveLowercase = validator.contains.filter((element) => {
            return element === 'lowercase';
        });

        this.passwordValidate.lowercase = haveLowercase.length > 0;

        const haveUppercase = validator.contains.filter((element) => {
            return element === 'uppercase';
        });

        this.passwordValidate.uppercase = haveUppercase.length > 0;

        const haveNumber = validator.contains.filter((element) => {
            return element === 'number';
        });

        this.passwordValidate.number = haveNumber.length > 0;

        if (validator.length < 8 || validator.length > 30) {
            this.passwordValidate.length = false;
        }

        if (validator.length >= 8 && validator.length <= 30) {
            this.passwordValidate.length = true;
        }

    }

    validaCampoSenha(senha: FocusEvent, verificaConfirm = false) {

        this.popoverVisibleSenha = false;
        if (this.formPasswordRecovey.value.password) {
            let error = false;

            for (const [chave, valor] of Object.entries(this.passwordValidate)) {
                if (chave !== 'confirm' && valor === false) {
                    error = true;
                }
            }
            this.formPasswordRecovey.get('password')?.setErrors(error ? {incorrect: true} : null);
        }
    }
}
