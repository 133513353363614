<nz-page-header class="site-page-header" nzTitle="Administração" nzSubtitle="Configurações da conta"></nz-page-header>
<nz-content>
    <nz-row nzGutter="24">
        <nz-col nzSpan="4">
            <nz-card [ngClass]="'ant-card-table'">
                <nz-tabset nzSize="large" nzTabPosition="left" class="tab-tipos" [(nzSelectedIndex)]="tabAtiva"
                           (nzSelectChange)="changeTabs($event)">
                    <nz-tab [nzTitle]="tituloTabConfiguracoes"></nz-tab>
                </nz-tabset>
                <ng-template #tituloTabConfiguracoes>
                    <i class="fa-solid fa-key"></i> Login SSO
                </ng-template>
            </nz-card>
        </nz-col>
        <nz-col nzSpan="20">
            <app-admin-configuracao-sso *ngIf="tabAtiva == 0"></app-admin-configuracao-sso>
        </nz-col>
    </nz-row>
</nz-content>
