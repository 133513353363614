<nz-page-header class="site-page-header" nzTitle="Delivery" nzSubtitle="Transmissões">
    <nz-page-header-extra>
        <nz-button-group>
            <nz-badge [nzCount]="qtdFiltrosAtivosCabecalho"
                      [nzStyle]="{ backgroundColor: '#52c41a'}">
                <button nz-button nzType="primary" nzGhost
                        style="border-radius: 0.45rem;"
                        (click)="abrirModal(formFiltrosCabecalho)">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="6">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                        <div>
                            <h1 nz-typography class="custom-card-value text-nowrap"
                                style="margin: 0; font-size: 28px; margin-bottom: 10px;">
                                {{ dadosCabecalho.obrigacao }}
                            </h1>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                {{ dadosCabecalho.orgao }}
                            </h4>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                        <div>
                            <h4 class="custom-card-title text-muted" style="line-height: 1;">
                                Vencimento:
                            </h4>
                            <h1 nz-typography class="custom-card-value" style="margin: 0; font-size: 30px">
                                {{ dadosCabecalho.mesSelecionado }}<span
                                    style="font-weight: 400;">/</span>{{ dadosCabecalho.anoSelecionado }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-center justify-content-center">
                        <i class="far fa-building"
                           style="font-size: 56px; margin-right: 15px; color: #009688 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Unidades
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #009688 !important; font-size: 36px;">
                                {{ dadosCabecalho.qtdUnidades | number: '1.0-0' }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24"
                         class="d-flex align-items-center justify-content-center">
                        <i class="far fa-calendar-alt"
                           style="font-size: 56px; margin-right: 15px; color:#1e9ff2 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Obrigações
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #1e9ff2 !important; font-size: 36px;">
                                {{ dadosCabecalho.qtdObrigacoes | number: '1.0-0' }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="10" [nzXl]="6">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24"
                         class="d-flex">
                        <i nz-icon nzType="info-circle" nzTheme="outline"
                           style="font-size: 56px; margin-right: 15px; color: #3653a1 !important;"></i>
                        <div>
                            <h1 class="custom-card-title text-muted" style="line-height: 1;">
                                Status
                            </h1>
                            <div class="d-flex justify-content-between">
                                <a class="custom-card-title text-muted"
                                   (click)="filtrarCampo(formFiltrosGrid, 'status', '0')"
                                   nz-tooltip nzTooltipTitle="Na Fila"
                                   style="margin-right: 10px; white-space: nowrap; margin-bottom: 5px; line-height: 1; width: 100%; font-size: 18px;">
                                    <i style="color: rgba(0,0,0,.45);" class="far fa-hourglass"></i>
                                    <span class="custom-card-span-valor" style="color: rgba(0,0,0,.45);">
                                    {{ dadosCabecalho.status?.naoIniciado | number: '1.0-0' }}
                                    </span>
                                </a>
                                <a class="custom-card-title text-muted"
                                   nz-tooltip nzTooltipTitle="Em Execução"
                                   (click)="filtrarCampo(formFiltrosGrid, 'status', '1')"
                                   style="margin-right: 10px; white-space: nowrap; margin-bottom: 0; line-height: 1; width: 100%; font-size: 18px;">
                                    <i class="fas fa-redo"></i>
                                    <span class="custom-card-span-valor" style="color: rgba(0,0,0,.45);">
                                        {{ dadosCabecalho.status?.emExecucao | number: '1.0-0' }}
                                    </span>
                                </a>
                                <a class="custom-card-title text-muted"
                                   nz-tooltip nzTooltipTitle="Erro ao Transmitir"
                                   (click)="filtrarCampo(formFiltrosGrid, 'status', '3')"
                                   style="margin-right: 10px; white-space: nowrap; margin-bottom: 5px; line-height: 1; width: 100%; font-size: 18px;">
                                    <i style="color: red;" class="fas fa-close"></i>
                                    <span class="custom-card-span-valor" style="color: rgba(0,0,0,.45);">
                                        {{ dadosCabecalho.status?.erro | number: '1.0-0' }}
                                    </span>
                                </a>
                                <a class="custom-card-title text-muted"
                                   nz-tooltip nzTooltipTitle="Transmitidos"
                                   (click)="filtrarCampo(formFiltrosGrid, 'status', '2')"
                                   style="margin-right: 10px; white-space: nowrap; margin-bottom: 5px; line-height: 1; width: 100%; font-size: 18px;">
                                    <i style="color: #28a745;" class="fas fa-check"></i>
                                    <span class="custom-card-span-valor" style="color: rgba(0,0,0,.45);">
                                        {{ dadosCabecalho.status?.transmitidas | number: '1.0-0' }}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
    </div>
</nz-content>
<nz-content>
    <nz-card class="fonte-doze"
             [nzLoading]="loading"
             [ngClass]="[!loading ? 'ant-card-table' : '']"
             [nzExtra]="navBar"
             nzTitle="Transmissões">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button [nzDropdownMenu]="menuAcoes" class="centralized-icon"
                        nz-button nz-dropdown
                        nzType="default" style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>

                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item [nzDisabled]="loadings.exportar"
                            (click)="!loadings.exportar && exportarArquivosEmLote()">
                            {{ loadings.exportar ? 'Solicitando exportação...' : 'Exportar arquivos' }}
                        </li>
                        <li nz-button *ngIf="currentUser?.origem === 'console'" nz-submenu nzTitle="Console">
                            <ul>
                                <li nz-menu-item
                                    (click)="checkedItems.size && showConfirm()"
                                    [nzDisabled]="checkedItems.size === 0 || loadings.enviandoParaTransmissao">
                                    {{ loadings.enviandoParaTransmissao ? 'Enviando arquivos para transmissão...' : 'Enviar arquivos para transmissão' }}
                                </li>

                                <li nz-menu-item
                                    (click)="checkedItems.size && showConfirmReamarzenar()"
                                    [nzDisabled]="checkedItems.size === 0 || loadings.enviandoParaRearmazenamento">
                                    {{loadings.enviandoParaRearmazenamento ? 'Enviando arquivos para rearmazenamento...' : 'Rearmazenar arquivos'}}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input
                           placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #tableRegras
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzShowTotal]="rangeTemplate"
                  class="table-small">
            <thead>
            <tr>
                <th nzAlign="center" #column1>
                    <label nz-checkbox [(ngModel)]="checked" (nzCheckedChange)="onAllChecked($event)"></label>
                </th>
                <th #column2></th>
                <th #column3 nzColumnKey="obrigacao_descricao" [nzSortFn]="true" nzAlign="center">Obrigação</th>
                <th #column4 nzColumnKey="empresa" [nzSortFn]="true" nzAlign="center">Empresa</th>
                <th #column5 nzColumnKey="grupo" [nzSortFn]="true" nzAlign="center">Grupo</th>
                <th #column6 nzColumnKey="competencia" [nzSortFn]="true" nzAlign="center">Competência</th>
                <th #column7 nzColumnKey="vencimento" [nzSortFn]="false" nzAlign="center">Vencimento</th>
                <th #column8 nzColumnKey="status" [nzSortFn]="true" nzAlign="center">Status</th>
                <th #column8 nzColumnKey="usuario_nome" [nzSortFn]="true" nzAlign="center">Armazenamento</th>
                <th #column10 [nzShowSort]="false" nzAlign="center">Histórico</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let arquivo of tableRegras.data">
                <tr>
                    <td nzLeft
                        (nzCheckedChange)="onItemCheckedTransmissor(arquivo.checkObrigacaoValidacao_id, arquivo.roboBaixa_id, $event)"
                        [nzChecked]="checkedItems.has(arquivo.checkObrigacaoValidacao_id)"
                        [nzDisabled]="arquivo.disabled">
                    </td>
                    <td [nzExpand]="agrupamentoSet.has(arquivo.id)" (nzExpandChange)="onExpandChange(arquivo, $event)"
                        style="width: 34px; max-width: 34px"
                        *ngIf="arquivo.quantidadeAgrupamento && arquivo.quantidadeAgrupamento >= 2"></td>
                    <td *ngIf="!arquivo.quantidadeAgrupamento || arquivo.quantidadeAgrupamento <= 1"></td>
                    <td nzLeft>
                            {{ arquivo?.obrigacao_descricao }}
                    </td>
                    <td nzLeft style="width: 300px;">
                        {{ modoDemo(arquivo?.empresa, 'empresaCodigoNome') }}
                        ({{ modoDemo(arquivo?.cnpj | cpfCnpj, 'cnpj') }})
                    </td>
                    <td nzLeft>{{ modoDemo(arquivo?.grupo, 'grupo') }}</td>
                    <td nzAlign="center">{{ arquivo?.competencia | date : 'MM/yyyy' }}</td>
                    <td nzAlign="center">{{ arquivo?.vencimento | date : 'dd/MM/yyyy' }}</td>
                    <td nzAlign="center">
                        <app-status-com-anexos [arquivo]="arquivo" [nomeValido]="'transmissao'"></app-status-com-anexos>
                    </td>
                    <td nzAlign="center">
                        Por {{ arquivo?.usuario_nome ? arquivo?.usuario_nome : '-' }}
                        {{ arquivo?.armazenamento_data ? ' em ' + (arquivo.armazenamento_data | date : 'dd/MM/yyyy HH:mm') : '' }}<br>
                        {{ arquivo.tamanhoDescricao }}
                    </td>
                    <td nzAlign="center">
                        <a (click)="openTab('/cadastros/obrigacao-empresa-detalhe/', arquivo?.obrigacaoEmpresa_id, {id: arquivo?.obrigacaoEmpresa_id})"
                           nz-tooltip nzTooltipTitle="Histórico" style="font-size: 20px;"
                        >
                            <i class="fa-solid fa-list"></i>
                        </a>
                    </td>
                </tr>
                <ng-container *ngIf="loadingsAgrupamentos[arquivo.id]">
                    <tr>
                        <td colspan="8" nzAlign="center">
                            <nz-skeleton></nz-skeleton>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="!loadingsAgrupamentos[arquivo.id]" style="padding: 0;"
                              [ngStyle]="{'padding' : '0'}">
                    <tr [nzExpand]="agrupamentoSet.has(arquivo.id)" style="padding: 0"
                        *ngFor="let subRegistro of subRegistros[arquivo.id] ?? []">
                        <td [ngStyle]="{
                            'width': column1Width + 'px',
                            'min-width': column1Width + 'px',
                                position: 'relative',
                                padding: '8px',
                                'overflow-wrap': 'break-word'
                        }"></td>
                        <td [ngStyle]="{
                            'width': column2Width + 'px',
                            'min-width': column2Width + 'px',
                                position: 'relative',
                                padding: '8px',
                                'overflow-wrap': 'break-word'
                        }"></td>
                        <td [ngStyle]="{
                            'width': column3Width + 'px',
                            'min-width': column3Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word'
                            }">
                            <button nz-button nzType="link"
                                    (click)="openTab('/cadastros/obrigacao-empresa-detalhe/', subRegistro?.obrigacaoEmpresa_id, {id: subRegistro?.obrigacaoEmpresa_id})">
                                {{ subRegistro?.obrigacao_descricao }}
                            </button>
                        </td>
                        <td [ngStyle]="{
                            'width': column4Width + 'px',
                            'min-width': column4Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word'
                            }">
                            {{ modoDemo(subRegistro?.empresa, 'empresaCodigoNome') }}
                            ({{ modoDemo(subRegistro?.cnpj | cpfCnpj, 'cnpj') }})
                        </td>
                        <td [ngStyle]="{
                            'width': column5Width + 'px',
                            'min-width': column5Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word'
                            }">{{ modoDemo(subRegistro?.grupo, 'grupo') }}
                        </td>
                        <td nzAlign="center"
                            [ngStyle]="{
                            'width': column6Width + 'px',
                            'min-width': column6Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word'
                            }">{{ subRegistro?.competencia | date : 'MM/yyyy' }}
                        </td>
                        <td nzAlign="center"
                            [ngStyle]="{
                            'width': column7Width + 'px',
                            'min-width': column7Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word'
                            }">{{ subRegistro?.vencimento | date : 'dd/MM/yyyy' }}
                        </td>
                        <td [ngStyle]="{
                            'width': column8Width + 'px',
                            'min-width': column8Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word'
                            }" nzAlign="center">
                            <app-status-com-anexos [arquivo]="subRegistro"
                                                   [nomeValido]="'transmissao'"></app-status-com-anexos>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="formFiltrosCabecalho.modalVisible"
          [nzTitle]="'Filtros'" [nzClosable]="true"
          (nzOnCancel)="fecharModal(formFiltrosCabecalho)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosCabecalho.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Vencimento</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.vencimento">
                        <nz-date-picker [nzMode]="'month'"
                                        [nzFormat]="'MM/yyyy'"
                                        [(ngModel)]="formFiltrosCabecalho.formGroup.value.vencimento"
                                        formControlName="vencimento" style="width: 100%;"
                                        [nzAllowClear]="false"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Categoria</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.categoria">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="categoria"
                                   [(ngModel)]="formFiltrosCabecalho.formGroup.value.categoria"
                                   (ngModelChange)="clearInput(formFiltrosCabecalho.formGroup, 'categoria', 'obrigacao')">
                            <nz-option *ngFor="let opt of comboCategorias"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Obrigação</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.obrigacao">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="obrigacao"
                                   [(ngModel)]="formFiltrosCabecalho.formGroup.value.obrigacao"
                                   (ngModelChange)="clearInput(formFiltrosCabecalho.formGroup, 'obrigacao', 'categoria')">
                            <nz-option *ngFor="let opt of comboObrigacoes"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fecharModal(formFiltrosCabecalho)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrarCabecalho()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formFiltrosGrid.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="fecharModal(formFiltrosGrid)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosGrid.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Grupo</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.grupo">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="grupo">
                            <nz-option *ngFor="let opt of arraySelectGrupos"
                                       [nzLabel]="modoDemo(opt.descricao, 'total')"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.empresa_id">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   [(ngModel)]="formFiltrosGrid.formGroup.value.empresa_id">
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') +
                                       ' ('+modoDemo(opt.empresa_cnpj | cpfCnpj, 'cnpj') + ')'"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Competência</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.competencia">
                        <nz-date-picker [nzMode]="'month'"
                                        [nzFormat]="'MM/yyyy'"
                                        [(ngModel)]="formFiltrosGrid.formGroup.value.competencia"
                                        formControlName="competencia" style="width: 100%;"
                                        [nzAllowClear]="false"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Status</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.status">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="status"
                                   [(ngModel)]="formFiltrosGrid.formGroup.value.status">
                            <nz-option [nzLabel]="'Não Iniciado'" [nzValue]="'0'"></nz-option>
                            <nz-option [nzLabel]="'Em Execução'" [nzValue]="1"></nz-option>
                            <nz-option [nzLabel]="'Transmitidas'" [nzValue]="2"></nz-option>
                            <nz-option [nzLabel]="'Erro ao Transmitir'" [nzValue]="3"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Usuário</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.usuario_id">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="usuario_id">
                            <nz-option *ngFor="let opt of arraySelectUsuarios"
                                       [nzLabel]="opt.nome"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="formFiltrosGrid.formGroup.value.status === 3">
                    <nz-form-label [nzSpan]="5">Erro</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.erroMensagem">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="erroMensagem">
                            <nz-option *ngFor="let opt of comboErros"
                                       [nzLabel]="opt"
                                       [nzValue]="opt"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fecharModal(formFiltrosGrid)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrarGrid()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalPdfVisible"
          [nzTitle]="'Arquivo'" [nzClosable]="true"
          (nzOnCancel)="modalPdf(false)"
          [nzWidth]="900">

    <div *nzModalContent style="height:500px">
        <nz-skeleton *ngIf="!arquivoUrl"></nz-skeleton>
        <object>
            <embed *ngIf="arquivoUrl" [src]="arquivoUrl | safe : 'resourceUrl'" style="width: 100%; height: 100%">
        </object>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalPdf(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="downloadPdf()">Download</button>
    </div>
</nz-modal>
