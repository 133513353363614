import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '@models/user.model';
import {AbstractService} from './abstract.service';
import {Observable, Subject} from 'rxjs';
import {UsuarioLogado} from '@models/usuarioLogado.model';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class UserService extends AbstractService<User> {

    constructor(http: HttpClient) {
        super(http, '/user', User);
    }

    currentuser: Subject<UsuarioLogado> = new Subject<UsuarioLogado>();

    usuarioLogado = (): Observable<UsuarioLogado> => this.http.get<UsuarioLogado>(`${environment.apiUrl}/usuario-logado`);
    configuracoes = (): Observable<UsuarioLogado> => this.http.get<UsuarioLogado>(`${environment.apiUrl}/configuracoes-conta`);

    exportExcel(): any {
        return this.http.get(`${this.baseUrl}/excel-export`, {responseType: 'blob'});
    }

    abrirChamado(formData: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/administracao/chamados/abrirChamado`, formData);
    }

    setHistory(url: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/administracao/usuarios/acesso/historico`, {url});
    }


}
