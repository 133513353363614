<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Editar Inscrições e Regimes"></nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-affix [nzOffsetTop]="offsetTop" [nzTarget]="target">
                <nz-card class="ant-card-meta-reduce" [nzLoading]="loadings.loadingDadosCadastrais"
                         [nzBodyStyle]="loadings.loadingDadosCadastrais ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
                    <nz-card-meta [nzTitle]="modoDemo(dadosCadastrais.nome, 'empresaCodigoNome')"
                                  [nzDescription]="dadosCadastrais.cnpj ? 'CNPJ: ' + (dadosCadastrais.cnpj | cpfCnpj) : '-'"></nz-card-meta>
                    <nz-divider></nz-divider>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="ID">
                                <nz-list-item-meta-title>
                                    {{ dadosCadastrais.id ? dadosCadastrais.id : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>

                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Criado em">
                                <nz-list-item-meta-title>
                                    {{ dadosCadastrais.created_at ? (dadosCadastrais.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Última alteração">
                                <nz-list-item-meta-title>
                                    {{ dadosCadastrais.updated_at ? (dadosCadastrais.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                </nz-card>
                <nz-anchor class="transparent-anchor" [nzAffix]="false" [nzShowInkInFixed]="true"
                           [nzContainer]="target">
                    <nz-link nzHref="#dadosCadastrais" nzTitle="Dados Cadastrais"></nz-link>
                    <nz-link nzHref="#inscricaoFederal" nzTitle="Receita Federal"></nz-link>
                    <nz-link nzHref="#st1" nzTitle="Matriz"></nz-link>
                    <nz-link nzHref="#st2" nzTitle="Regime Tributário"></nz-link>
                    <nz-link nzHref="#st3" nzTitle="Inscrição Estadual"></nz-link>
                    <nz-link nzHref="#st4" nzTitle="Inscrição Municipal"></nz-link>
                    <nz-link nzHref="#st5" nzTitle="Inscrição Imobiliária"></nz-link>
                </nz-anchor>
            </nz-affix>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">
            <nz-card nzTitle="Dados Cadastrais" [nzExtra]="extraDadosCadastrais"
                     [nzLoading]="loadings.loadingDadosCadastrais"
                     [nzBodyStyle]="loadings.loadingDadosCadastrais ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
                <ng-template #extraDadosCadastrais>
                    <button nz-button nzType="primary" *ngIf="currentUser?.origem === 'console'"
                            (click)="sincronizarEmbedded('importar');" style="margin-right: 5px;"
                            nz-tooltip [nzTooltipTitle]="titleTemplate" [nzLoading]="loadings.importando">
                        {{ loadings.importando ? 'Importando...' : 'Importar' }}
                    </button>
                    <ng-template #titleTemplate let-thing>
                        <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                        <span style="margin-left: 5px;">Usuário console</span>
                    </ng-template>

                    <button nz-button nzType="primary" *ngIf="currentUser?.origem === 'console'"
                            (click)="sincronizarEmbedded('exportar');"
                            nz-tooltip [nzTooltipTitle]="titleTemplate" [nzLoading]="loadings.exportando">
                        {{ loadings.exportando ? 'Exportando...' : 'Exportar' }}
                    </button>
                </ng-template>
                <nz-list id="dadosCadastrais">
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Código">
                            <nz-list-item-meta-title>
                                {{ dadosCadastrais.codigo ? modoDemo(dadosCadastrais.codigo, 'total') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Empresa">
                            <nz-list-item-meta-title>
                                {{ modoDemo(dadosCadastrais.nome, 'empresaCodigoNome') }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Grupo">
                            <nz-list-item-meta-title>
                                {{ dadosCadastrais.grupoEmpresarial_descricao ? modoDemo(dadosCadastrais.grupoEmpresarial_descricao, 'empresaCodigoNome') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Endereço">
                            <nz-list-item-meta-title>
                                {{ dadosCadastrais.endereco ? modoDemo(dadosCadastrais.endereco + ', ' + dadosCadastrais.numero, 'total') : '-' }}
                                <br>
                                {{ dadosCadastrais.endereco ? modoDemo(dadosCadastrais.bairro + ' - ' + dadosCadastrais.cidade + '/' + dadosCadastrais.estado, 'total') : '' }}
                                <br>
                                {{ dadosCadastrais.endereco ? modoDemo(dadosCadastrais.cep, 'total') : '' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>

            <nz-card id="inscricaoFederal" class="ant-card-table" nzTitle="Receita Federal" [nzExtra]="extraIfs"
                     [nzLoading]="loadingIf"
                     [nzBodyStyle]="loadingIf ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
                <ng-template #extraIfs>
                    <!--                    ifs sempre terá apenas um registro-->
                    <button *ngIf="ifs.length else semIe" nz-button nzType="default"
                            (click)="alterarReceita(ifs[0].documento);"
                            style="margin-right: 5px;" [nzLoading]="loadingReceita">
                        Atualizar
                    </button>
                    <ng-template #semIe>
                        <button disabled="disabled" nz-button nzType="default" style="margin-right: 5px;">
                            Atualizar
                        </button>
                    </ng-template>
                    <button style="margin-right: 5px;" nz-button nzType="primary" (click)="cadastrar(formIf, false);">
                        Cadastrar
                    </button>
                    <!--                    Não está mais realizando a consulta devido essa consulta também necessitar de HCaptcha-->
                    <!--                    <button nz-button nzType="default" [nzLoading]="loadingRFB" (click)="atualizarViaRFB();">-->
                    <!--                        Atualizar via portal RFB-->
                    <!--                    </button>-->
                </ng-template>
                <nz-table #basicTableIf
                          nzSize="small"
                          [nzData]="ifs"
                          [nzFrontPagination]="false"
                          [nzTotal]="pagination.ifs?.total"
                          [nzPageSize]="pagination.ifs?.per_page"
                          [nzPageIndex]="pagination.ifs?.current_page"
                          (nzQueryParams)="listarIfs($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="documento" [nzSortFn]="true">Documento</th>
                        <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Início</th>
                        <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Fim</th>
                        <th nzColumnKey="situacaoCadastral" [nzSortFn]="true" nzAlign="center">Situação Cadastral</th>
                        <th nzColumnKey="situacaoCadastralData" [nzSortFn]="true" nzAlign="center">Data Situação
                            Cadastral
                        </th>
                        <th nzColumnKey="situacaoCadastralData" [nzSortFn]="true" nzAlign="center">Atualizado em
                        </th>
                        <th nzAlign="right"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTableIf.data">
                        <td style="white-space: nowrap;">{{ modoDemo(item.documento | cpfCnpj, 'cnpj') }}</td>
                        <td nzAlign="center">{{ item.dataInicio | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="center">{{ item.dataFim  | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="center">{{ item.situacaoCadastral }}</td>
                        <td nzAlign="center">{{ item.situacaoCadastralData  | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="center">{{ item.updated_at  | date: 'dd/MM/yyyy' }}</td>
                        <td style="white-space: nowrap; text-align: right">
                            <button nz-button nzType="default"
                                    (click)="alterarIf(item);"
                                    style="margin-right: 5px;">
                                Alterar
                            </button>
                            <button nz-button nzType="default" [nzLoading]="removendoId === item.id"
                                    (click)="showConfirm('if', item);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>

            <nz-card id="st1" class="ant-card-table" nzTitle="Matriz" [nzExtra]="extraMatrizes"
                     [nzLoading]="loadingMatrizes"
                     [nzBodyStyle]="loadingMatrizes ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
                <ng-template #extraMatrizes>
                    <button nz-button nzType="primary" (click)="cadastrar(formMatriz, false);">
                        Cadastrar
                    </button>
                </ng-template>
                <nz-table #basicTable1
                          nzSize="small"
                          [nzData]="matrizes"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadingMatrizes"
                          [nzTotal]="pagination.matrizes?.total"
                          [nzPageSize]="pagination.matrizes?.per_page"
                          [nzPageIndex]="pagination.matrizes?.current_page"
                          (nzQueryParams)="listarMatrizes($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="cnpj" [nzSortFn]="true">CNPJ</th>
                        <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Início</th>
                        <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Fim</th>
                        <th nzAlign="right"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable1.data">
                        <td>{{ modoDemo(item.cnpj | cpfCnpj, 'cnpj') }}</td>
                        <td nzAlign="center">{{ item.dataInicio | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="center">{{ item.dataFim  | date: 'dd/MM/yyyy' }}</td>
                        <td nzRight nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarMatriz(item);"
                                    style="margin-right: 10px;">
                                Alterar
                            </button>
                            <button nz-button nzType="default"
                                    (click)="showConfirm('matriz', item);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>

            <nz-card id="st2" class="ant-card-table" nzTitle="Regime Tributário" [nzExtra]="extraRegimes"
                     [nzLoading]="loadingRegime"
                     [nzBodyStyle]="loadingRegime ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
                <ng-template #extraRegimes>
                    <button nz-button nzType="primary" (click)="cadastrar(formRegime, false);">
                        Cadastrar
                    </button>
                </ng-template>
                <nz-table #basicTable2
                          nzSize="small"
                          [nzData]="regimes"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadingRegime"
                          [nzTotal]="pagination.regimes?.total"
                          [nzPageSize]="pagination.regimes?.per_page"
                          [nzPageIndex]="pagination.regimes?.current_page"
                          (nzQueryParams)="listarRegimes($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="regimeTributario_descricao" [nzSortFn]="true">Regime</th>
                        <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Início</th>
                        <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Fim</th>
                        <th nzAlign="right"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable2.data">
                        <td>{{ modoDemo(item.regimeTributario_descricao, 'total') }}</td>
                        <td nzAlign="center">{{ item.dataInicio | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="center">{{ item.dataFim | date: 'dd/MM/yyyy' }}</td>
                        <td nzRight nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarRegime(item);"
                                    style="margin-right: 10px;">
                                Alterar
                            </button>
                            <button nz-button nzType="default" [nzLoading]="this.removendoId === item.id"
                                    (click)="showConfirm('regime', item);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>

            <nz-card id="st3" class="ant-card-table" nzTitle="Inscrição Estadual" [nzExtra]="extraIes"
                     [nzLoading]="loadingIe"
                     [nzBodyStyle]="loadingIe ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
                <ng-template #extraIes>
                    <div class="nav-item d-flex">
                        <nz-input-group *ngIf="showBuscarEstaduais" nzSearch [nzAddOnAfter]="suffixIconButtonIEs"
                                        [nzSuffix]="inputClearTpl"
                                        style="margin-right: 10px;">
                            <input (keydown.enter)="buscarEstaduais()" type="text" nz-input placeholder="Busca"
                                   [(ngModel)]="currentSearchEstaduais"/>
                        </nz-input-group>
                        <ng-template #suffixIconButtonIEs>
                            <button nz-button nzType="primary" nzSearch
                                    (click)="buscarEstaduais();"><i
                                    nz-icon nzType="search"></i></button>
                        </ng-template>

                        <ng-template #inputClearTpl>
                          <span
                                  nz-icon
                                  class="ant-input-clear-icon"
                                  nzTheme="fill"
                                  nzType="close-circle"
                                  *ngIf="currentSearchEstaduais"
                                  (click)="buscarEstaduais(true)"
                          ></span>
                        </ng-template>

                        <button nz-button nzType="primary" (click)="cadastrar(formIe, false);">
                            Cadastrar
                        </button>
                    </div>
                </ng-template>
                <nz-table #basicTable3
                          nzSize="small"
                          [nzData]="ies"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadingIe"
                          [nzTotal]="pagination.ies?.total"
                          [nzPageSize]="pagination.ies?.per_page"
                          [nzPageIndex]="pagination.ies?.current_page"
                          (nzQueryParams)="listarIes($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="isento" [nzSortFn]="false" nzShowSort="false">Isento</th>
                        <th nzColumnKey="ie" [nzSortFn]="true" nzAlign="center">IE</th>
                        <th nzColumnKey="uf" [nzSortFn]="true" nzAlign="center">UF</th>
                        <th nzColumnKey="tipo" [nzSortFn]="true" nzAlign="center">Tipo</th>
                        <th nzColumnKey="situacao" [nzSortFn]="true" nzAlign="center">Situação</th>
                        <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Inicio</th>
                        <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Fim</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable3.data">
                        <td>{{ item.ie ? 'Não' : 'Sim' }}</td>
                        <td nzAlign="center">{{ item.ie ? modoDemo(item.ie, 'total') : '-' }}</td>
                        <td nzAlign="center">{{ item.uf ? item.uf : '-' }}</td>
                        <td nzAlign="center">{{ item.ie ? item.tipo : '-' }}</td>
                        <td nzAlign="center">{{ item.ie ? item.situacaoCadastral : '-' }}</td>
                        <td nzAlign="center">{{ item.ie ? (item.dataInicio | date: 'dd/MM/yyyy') : '-' }}</td>
                        <td nzAlign="center">{{ item.ie ? (item.dataFim | date: 'dd/MM/yyyy') : '-' }}</td>
                        <td nzRight nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarIe(item);"
                                    style="margin-right: 10px;">
                                Alterar
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>

            <nz-card id="st4" class="ant-card-table" nzTitle="Inscrição Municipal" [nzExtra]="extraIms"
                     [nzLoading]="loadingIm"
                     [nzBodyStyle]="loadingIm ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
                <ng-template #extraIms>
                    <div class="nav-item d-flex">
                        <nz-input-group *ngIf="showBuscarMunicipais" nzSearch [nzAddOnAfter]="suffixIconButtonIEs"
                                        [nzSuffix]="inputClearTpl"
                                        style="margin-right: 10px;">
                            <input (keydown.enter)="buscarMunicipais()" type="text" nz-input placeholder="Busca"
                                   [(ngModel)]="currentSearchMunicipais"/>
                        </nz-input-group>
                        <ng-template #suffixIconButtonIEs>
                            <button nz-button nzType="primary" nzSearch
                                    (click)="buscarMunicipais();"><i
                                    nz-icon nzType="search"></i></button>
                        </ng-template>

                        <ng-template #inputClearTpl>
                          <span
                                  nz-icon
                                  class="ant-input-clear-icon"
                                  nzTheme="fill"
                                  nzType="close-circle"
                                  *ngIf="currentSearchMunicipais"
                                  (click)="buscarMunicipais(true)"
                          ></span>
                        </ng-template>
                        <button nz-button nzType="primary" (click)="cadastrar(formIm, true);">
                            Cadastrar
                        </button>
                    </div>
                </ng-template>
                <nz-table #basicTable4
                          nzSize="small"
                          [nzData]="ims"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadingIm"
                          [nzTotal]="pagination.ims?.total"
                          [nzPageSize]="pagination.ims?.per_page"
                          [nzPageIndex]="pagination.ims?.current_page"
                          (nzQueryParams)="listarIms($event)"
                >
                    <thead>
                    <tr>
                        <th nzColumnKey="im" [nzSortFn]="true">IM</th>
                        <th nzColumnKey="municipioCodigo" [nzSortFn]="true" nzAlign="center">Cod. Mun</th>
                        <th nzColumnKey="cidade_nome" [nzSortFn]="true" nzAlign="center">Município</th>
                        <th nzColumnKey="situacao" [nzSortFn]="true" nzAlign="center">Situação</th>
                        <th nzColumnKey="tipo" [nzSortFn]="true" nzAlign="center">Tipo</th>
                        <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Inicio</th>
                        <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Fim</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable4.data">
                        <td>{{ modoDemo(item.im, 'total') }}</td>
                        <td nzAlign="center">{{ modoDemo(item.municipioCodigo, 'total') }}</td>
                        <td nzAlign="center">{{ item.cidade_nome }}</td>
                        <td nzAlign="center">{{ item.situacaoCadastral }}</td>
                        <td nzAlign="center">{{ item.tipo ?? '-' }}</td>
                        <td nzAlign="center">{{ item.dataInicio | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="center">{{ item.dataFim | date: 'dd/MM/yyyy' }}</td>
                        <td nzRight nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarIm(item);"
                                    style="margin-right: 10px;">
                                Alterar
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>

            <nz-card [nzLoading]="gravandoImobiliaria || loadingImombiliarias"
                     [nzBodyStyle]="gravandoImobiliaria || loadingImombiliarias ? {'padding' : '15px' } : {}"
                     id="st5" class="ant-card-table" nzTitle="Inscrição Imobiliária" [nzExtra]="extraImobiliarias">

                <ng-template #extraImobiliarias>
                    <div class="nav-item d-flex">
                        <nz-input-group *ngIf="showBuscarImobiliarias" nzSearch [nzAddOnAfter]="suffixIconButton"
                                        [nzSuffix]="inputClearTpl"
                                        style="margin-right: 10px;">
                            <input (keydown.enter)="buscarImobiliarias()" type="text" nz-input placeholder="Busca"
                                   [(ngModel)]="currentSearchImobiliarias"/>
                        </nz-input-group>
                        <ng-template #suffixIconButton>
                            <button nz-button nzType="primary" nzSearch
                                    (click)="buscarImobiliarias();"><i
                                    nz-icon nzType="search"></i></button>
                        </ng-template>

                        <ng-template #inputClearTpl>
                          <span
                                  nz-icon
                                  class="ant-input-clear-icon"
                                  nzTheme="fill"
                                  nzType="close-circle"
                                  *ngIf="this.currentSearchImobiliarias"
                                  (click)="buscarImobiliarias(true)"
                          ></span>
                        </ng-template>

                        <button nz-button nzType="primary" (click)="cadastrar(formImobiliaria, false);">
                            Cadastrar
                        </button>
                    </div>
                </ng-template>
                <nz-table #basicTable5
                          nzSize="small"
                          [nzData]="iImobiliarias"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadingImombiliarias"
                          [nzTotal]="pagination.imobiliarias?.total"
                          [nzPageSize]="pagination.imobiliarias?.per_page"
                          [nzPageIndex]="pagination.imobiliarias?.current_page"
                          (nzQueryParams)="listarImobiliarias($event)"
                >
                    <thead>
                    <tr>
                        <th nzColumnKey="ii" [nzSortFn]="true">Inscrição</th>
                        <th nzColumnKey="tipo" [nzSortFn]="true">Tipo</th>
                        <th nzColumnKey="estado_uf" [nzSortFn]="true" nzAlign="center">UF</th>
                        <th nzColumnKey="cidade_nome" [nzSortFn]="true" nzAlign="center">Municipio</th>
                        <th nzColumnKey="situacaoCadastral" [nzSortFn]="true" nzAlign="center">Situação</th>
                        <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Inicio</th>
                        <th nzColumnKey="setor" [nzSortFn]="true" nzAlign="center">Setor</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable5.data">
                        <td>
                            <span *ngIf="item.descricao else semApelido">
                                {{ item.descricao }}<br>
                                <small>{{ item.ii ? modoDemo(item.ii, 'total') : '-' }}</small>
                            </span>
                            <ng-template #semApelido>
                                {{ item.ii ? modoDemo(item.ii, 'total') : '-' }}
                            </ng-template>
                        </td>
                        <td nzAlign="center">{{ item.tipo || '-' }}</td>
                        <td nzAlign="center">{{ item.estado_uf || '-' }}</td>
                        <td nzAlign="center">{{ item.cidade_nome + ' - (' + item.municipioCodigo + ')' }}</td>
                        <td nzAlign="center">{{ item.situacaoCadastral ? item.situacaoCadastral === 'Ativo' ? 'Ativa' : 'Cancelada' : ' ' }}</td>
                        <td nzAlign="center">{{ item.dataInicio | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="center">{{ item.setor || '-' }}</td>
                        <td nzRight nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarImobiliaria(item);"
                                    style="margin-right: 10px;">
                                Alterar
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>

        </div>
    </div>
</nz-content>

<nz-modal [(nzVisible)]="formIf.modalVisible"
          [nzTitle]="editandoIf ? 'Alterar Inscrição Federal' : 'Cadastrar Inscrição Federal'" [nzClosable]="true"
          (nzOnCancel)="fechar(formIf)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formIf.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Documento</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input
                           formControlName="documento" type="text"
                           autocomplete="off" size="60" mask="00.000.000/0000-00"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="currentUser.origem === 'console'">
                <nz-form-label [nzSpan]="8">Situação Cadastral</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="situacaoCadastral">
                        <nz-option *ngFor="let opt of comboSituacaoCadastral"
                                   [nzLabel]="opt"
                                   [nzValue]="opt"
                        >
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formIf)">Cancelar</button>
        <button *ngIf="!editandoIf" nz-button nzType="primary" [nzLoading]="loadings.gravandoIF" (click)="insertIf()">
            Inserir
        </button>
        <button *ngIf="editandoIf" nz-button nzType="primary" [nzLoading]="loadings.gravandoIF" (click)="updateIf()">
            Alterar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formMatriz.modalVisible"
          [nzTitle]="editandoMatriz ? 'Alterar Matriz' : 'Cadastrar Matriz'" [nzClosable]="true"
          (nzOnCancel)="fechar(formMatriz)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formMatriz.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>CNPJ</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input
                           formControlName="cnpj" type="text"
                           autocomplete="off" size="60" mask="00.000.000/0000-00"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formMatriz)">Cancelar</button>
        <button *ngIf="!editandoMatriz" nz-button nzType="primary" [nzLoading]="loadings.gravandoMatriz"
                (click)="insertMatriz()">
            Inserir
        </button>
        <button *ngIf="editandoMatriz" nz-button nzType="primary" [nzLoading]="loadings.gravandoMatriz"
                (click)="updateMatriz()">
            Alterar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formRegime.modalVisible"
          [nzTitle]="editandoRegime ? 'Alterar Regime Tributário' : 'Cadastrar Regime Tributário'" [nzClosable]="true"
          (nzOnCancel)="fechar(formRegime)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formRegime.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Regime Tributário</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="regimeTributario_id">
                        <nz-option *ngFor="let opt of comboRegimes"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        >
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formRegime)">Cancelar</button>
        <button *ngIf="!editandoRegime" nz-button nzType="primary" [nzLoading]="loadings.gravandoRegime"
                (click)="insertRegime()">
            Inserir
        </button>
        <button *ngIf="editandoRegime" nz-button nzType="primary" [nzLoading]="loadings.gravandoRegime"
                (click)="updateRegime()">
            Alterar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formIe.modalVisible"
          [nzTitle]="editandoIe ? 'Alterar Inscrição Estadual' : 'Cadastrar Inscrição Estadual'" [nzClosable]="true"
          (nzOnCancel)="fechar(formIe)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formIe.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Isento?</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <label nz-checkbox formControlName="isento">
                        Sim
                    </label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!formIe.formGroup.value.isento">
                <nz-form-label [nzSpan]="8" nzRequired>IE</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input
                           formControlName="ie"
                           autocomplete="off" size="60"
                           (keydown)="keyPressNumber($event)"
                           min="1">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!formIe.formGroup.value.isento">
                <nz-form-label [nzSpan]="8" nzRequired>Tipo</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="tipo">
                        <nz-option [nzLabel]="'Normal'" [nzValue]="'Normal'"></nz-option>
                        <nz-option [nzLabel]="'Substituta'" [nzValue]="'Substituta'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!formIe.formGroup.value.isento">
                <nz-form-label [nzSpan]="8" nzRequired>UF</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="uf">
                        <nz-option *ngFor="let opt of comboUFs"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!formIe.formGroup.value.isento">
                <nz-form-label [nzSpan]="8" nzRequired>Situação</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="situacaoCadastral">
                        <!--                        <nz-option [nzLabel]="'Nula'" [nzValue]="'Nula'"></nz-option>-->
                        <nz-option [nzLabel]="'Ativa'" [nzValue]="'Ativo'"></nz-option>
                        <nz-option [nzLabel]="'Inativa'" [nzValue]="'Inativo'"></nz-option>
                        <!--                        <nz-option [nzLabel]="'Suspensa'" [nzValue]="'Suspensa'"></nz-option>-->
                        <!--                        <nz-option [nzLabel]="'Inapta'" [nzValue]="'Inapta'"></nz-option>-->
                        <!--                        <nz-option [nzLabel]="'Baixada'" [nzValue]="'Baixada'"></nz-option>-->
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!formIe.formGroup.value.isento">
                <nz-form-label [nzSpan]="8" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;">
                    </nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="!formIe.formGroup.value.isento">
                <nz-form-label [nzSpan]="8">Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formIe)">Cancelar</button>
        <button *ngIf="!editandoIe" nz-button nzType="primary" [nzLoading]="loadings.gravandoIE"
                (click)="confirmInsertIe()">
            Inserir
        </button>
        <button *ngIf="editandoIe" nz-button nzType="primary" [nzLoading]="loadings.gravandoIE" (click)="updateIe()">
            Alterar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formIm.modalVisible"
          [nzTitle]="editandoIm ? 'Alterar Inscrição Municipal' : 'Cadastrar Inscrição Municipal'" [nzClosable]="true"
          (nzOnCancel)="fechar(formIm)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formIm.formGroup" style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>IM</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input
                           formControlName="im"
                           (keydown)="keyPressNoSpecialChar($event)"
                           (keyup)="charUnidentified('im')"
                           autocomplete="off" size="60">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8">UF</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="uf"
                               (ngModelChange)="filtraComboMunicipio(formIm.formGroup.value.uf)">
                        <nz-option *ngFor="let opt of comboUFs"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Município</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="municipioCodigo">
                        <nz-option *ngFor="let opt of comboMunicipio"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Situação</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="situacaoCadastral">
                        <nz-option [nzLabel]="'Nula'" [nzValue]="'Nulo'"></nz-option>
                        <nz-option [nzLabel]="'Ativa'" [nzValue]="'Ativo'"></nz-option>
                        <nz-option [nzLabel]="'Suspensa'" [nzValue]="'Suspenso'"></nz-option>
                        <nz-option [nzLabel]="'Inapta'" [nzValue]="'Inapto'"></nz-option>
                        <nz-option [nzLabel]="'Baixada'" [nzValue]="'Baixado'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="8">Tipo</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="tipo">
                        <nz-option [nzLabel]="'Normal'" [nzValue]="'Normal'"></nz-option>
                        <nz-option [nzLabel]="'Transitória'" [nzValue]="'Transitória'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formIm)">Cancelar</button>
        <button *ngIf="!editandoIm" nz-button nzType="primary" [nzLoading]="loadings.gravandoIM" (click)="insertIm();">
            Inserir
        </button>
        <button *ngIf="editandoIm" nz-button nzType="primary" [nzLoading]="loadings.gravandoIM" (click)="updateIm();">
            Alterar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formReceita.modalVisible"
          [nzTitle]="'Alterar Receita Federal'" [nzClosable]="true" (nzOnCancel)="fechar(formReceita)">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formReceita.formGroup"
              style="width: 100%;">
            <input nz-input formControlName="empresa_id" type="hidden" ngModel="{{dadosReceita.empresa_id}}">
            <input nz-input formControlName="regimeTributario_id" type="hidden"
                   ngModel="{{dadosReceita.regimeTributario_id}}">
            <nz-form-item>
                <nz-form-label [nzSpan]="12" nzRequired>CNPJ</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <input nz-input
                           formControlName="documento" type="text"
                           autocomplete="off" size="60" ngModel="{{editarDocumentoReceita}}"
                           mask="00.000.000/0000-00">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="12">Situação</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="situacaoCadastral"
                               ngModel="{{editarSituacaoCadstralReceita}}">
                        <nz-option [nzLabel]="'Nula'" [nzValue]="'Nulo'"></nz-option>
                        <nz-option [nzLabel]="'Ativa'" [nzValue]="'Ativo'"></nz-option>
                        <nz-option [nzLabel]="'Suspensa'" [nzValue]="'Suspenso'"></nz-option>
                        <nz-option [nzLabel]="'Inapta'" [nzValue]="'Inapto'"></nz-option>
                        <nz-option [nzLabel]="'Baixada'" [nzValue]="'Baixado'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formReceita)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.gravandoReceita" (click)="updateReceita();">
            Alterar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formImobiliaria.modalVisible"
          [nzTitle]="editandoImobiliaria ? 'Alterar Inscrição Imobiliária' : 'Cadastrar Inscrição Imobiliária'"
          [nzClosable]="true"
          (nzOnCancel)="fechar(formImobiliaria)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formImobiliaria.formGroup"
              style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Inscrição</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input
                           placeholder="Informe a inscrição"
                           formControlName="ii"
                           (keydown)="keyPressNoSpecialChar($event)"
                           (keyup)="charUnidentified('ii')"
                           autocomplete="off" size="60">
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Tipo</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="tipo">
                        <nz-option [nzLabel]="'CIB'" [nzValue]="'CIB'"></nz-option>
                        <nz-option [nzLabel]="'Imobiliaria'" [nzValue]="'Imobiliária'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Apelido</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input
                           placeholder="Informe o apelido"
                           formControlName="descricao"
                           autocomplete="off" size="60">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="8">UF</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="uf"
                               (ngModelChange)="filtraComboMunicipio(formImobiliaria.formGroup.value.uf)">
                        <nz-option *ngFor="let opt of comboUFs"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Município</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               (ngModelChange)="changeMunicipio()"
                               [nzPlaceHolder]="'Selecione'" formControlName="municipioCodigo">
                        <nz-option *ngFor="let opt of comboMunicipio"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Situação</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="situacaoCadastral">
                        <nz-option [nzLabel]="'Cancelada'" [nzValue]="'Cancelada'"></nz-option>
                        <nz-option [nzLabel]="'Ativa'" [nzValue]="'Ativo'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="formImobiliaria.formGroup.get('municipioCodigo').value === '3556206'">
                <nz-form-label nzRequired [nzSpan]="8">Setor</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input
                           type="number"
                           formControlName="setor"
                           autocomplete="off">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formImobiliaria)">Cancelar</button>
        <button *ngIf="!editandoImobiliaria" nz-button nzType="primary" [nzLoading]="loadings.gravandoII"
                (click)="insertImobiliaria();">
            Inserir
        </button>
        <button *ngIf="editandoImobiliaria" nz-button nzType="primary" [nzLoading]="loadings.gravandoII"
                (click)="updateImobiliarias();">
            Alterar
        </button>
    </div>
</nz-modal>
