import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {AbstractService} from '@services/abstract.service';
import {Empresa} from '@models/empresa.model';
import {environment} from '../../../../../../environments/environment';
import {Observable} from "rxjs";
import {buildUrl, findComponentByUrl} from "../../../../../shared/components-helper";
import {Tab} from "@models/tab.model";

@Injectable({providedIn: 'root'})

export class ObrigacoesService extends AbstractService<Empresa> {

    urlCertificados = environment.apiUrl + '/cadastros/credenciais-acesso/certificados';
    urlAcessos = environment.apiUrl + '/cadastros/credenciais-acesso/acessos-estaduais';
    baseUrlIfs = environment.apiUrl + '/cadastros/empresas/inscricoesFederais';
    baseUrlIes = environment.apiUrl + '/cadastros/empresas/inscricoesEstaduais';
    baseUrlIms = environment.apiUrl + '/cadastros/empresas/inscricoesMunicipais';

    constructor(http: HttpClient) {
        super(http, '/administracao/empresa', {});
    }


    retornaComboAnos(cnpj: string) {
        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/competencia/anos/${cnpj}`);
    }

    // tslint:disable-next-line:variable-name
    getVencimentosOficiais(params: any, search: string = null, empresa_id: string, ano: number): Observable<any> {

        const {pageSize, pageIndex, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`pagina=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        } else {
            queryParams.push(`quantidade=10`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            queryParams.push(`ordenar=${sortField}`);
            queryParams.push(`sentido=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined' && search.length > 0) {
            queryParams.push(`procurar=${search}`);
        }

        if (params.filter) {

            params.filter.forEach((obj) => {

                if (obj === 'calendar' || obj === 'check' || obj === 'compliance') {

                    queryParams.push('modulos[]' + '=' + obj);

                } else if (obj.key && obj.value) {

                    queryParams.push(obj.key + '=' + obj.value);

                }

            });

        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimentos/empresa/${empresa_id}/${ano}${queryString}`);

    }

    getAgenda(params: any, search: string = null, empresa_id: string, ano: number): Observable<any> {

        const {pageSize, pageIndex, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`pagina=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        } else {
            queryParams.push(`quantidade=10`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            queryParams.push(`ordenar=${sortField}`);
            queryParams.push(`sentido=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined' && search.length > 0) {
            queryParams.push(`procurar=${search}`);
        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/empresa-agenda/${empresa_id}/${ano}${queryString}`);

    }

    removerVencimentosOficiais(ids: string[], todosAnos: boolean) {
        const dados = {
            ids,
            todosAnos
        };

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacoes-empresas/deletar/vencimento`, dados);
    }

    removerAgendas(ids: string[], todosAnos: boolean) {
        const dados = {
            ids,
            todosAnos
        };

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacoes-empresas/deletar/vencimento`, dados);
    }


    retornaObrigacoesEmpresas(dados): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacoes-empresas/obrigacoes-sugeridas/previa`, dados);

    }

    gravarSteps(dados: any, empresaId?: string): Observable<any> {
        if (empresaId) {
            dados.empresas = [empresaId];
        }

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacoes-empresas/obrigacoes-sugeridas-job`, dados);
    }

    adicionarAgedasSugeridas(empresa: string, ano: number): Observable<any> {
        return this.http.put(`${environment.apiUrl}/cadastros/obrigacoes-empresas/obrigacoes-sugeridas/${empresa}/${ano}`, {});
    }

    temPendente(ano: number, cnpj: string, obrigacaoId: string) {
        return this.http.post(`${environment.apiUrl}/calendar/tasks/temPendente`, {ano, cnpj: [cnpj], obrigacaoId});
    }

    editarAgenda(dadosFinais: any) {
        return this.http.put(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimento`, dadosFinais);
    }

    verificaAgendaPendente = (dados: any) => {
        return this.http.post(`${environment.apiUrl}/calendar/tasks/temPendente`, dados);
    }

    alterarEquipeMassaAgenda(dados: any) {
        return this.http.put(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimento/calendar/equipes`, dados);
    }

    alterarResponsavelMassaAgenda(dados: any) {
        return this.http.put(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimento/calendar/responsaveis`, dados);
    }
}
