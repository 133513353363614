import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Helpers} from '../../../core/helpers';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Injectable} from '@angular/core';

type Cabecalho = {
    quantidade_icmsDeclarado: number,
    quantidade_icmsParcelamento: number,
    quantidade_icmsPendencia: number,
    quantidade_ipva: number,
    quantidade_itcmd: number,
    quantidade_aimm: number,
    quantidade_total: number
};

@Injectable({
    providedIn: 'root'
})

export class RelatorioPendenciasFiscaisService {

    constructor(private http: HttpClient) {
    }

    retornaCabecalho(): Observable<Cabecalho> {
        return this.http.get<Cabecalho>(`${environment.apiUrl}/consolidado/pendencia-fiscal/cabecalho`);
    }

    retornaTabela(params: NzTableQueryParams, search: string = null): Observable<any> {

        if (search) {
            params.filter.push({
                key: 'procurar',
                value: search
            });
        }

        const queryString = Helpers.montaQueryStribgBYNzTable(params);

        return this.http.get(`${environment.apiUrl}/consolidado/pendencia-fiscal/tabela${queryString}`);
    }

    retornaUltimoRelatorioPendencias(cnpj: string, id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/consolidado/pendencia-fiscal/pdf?cnpj=${cnpj}&id=${id}`);
    }

    retornarSelectsEmpresas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    exportExcel(filtros: any, tipo: string, search?: string): any {

        if (search) {
            filtros.procurar = search;
        }

        filtros.tipoExportacao = tipo;

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/consolidado/pendencia-fiscal/tabela/exporta${queryStr}`,
            {responseType: 'blob'});

    }

    exportPdfs(params, search: string = null): Observable<any> {

        if (search) {
            params.filter.push({
                key: 'procurar',
                value: search
            });
        }

        const queryString = Helpers.montaQueryStribgBYNzTable(params);

        return this.http.get(`${environment.apiUrl}/consolidado/pendencia-fiscal/exportar/pdf${queryString}`,
            {responseType: 'blob'});

    }

    getHistorico(cnpj: string, params?: any): Observable<any> {
        const queryString = Helpers.montaQueryStribgBYNzTable(params);

        return this.http.get(`${environment.apiUrl}/consolidado/pendencia-fiscal/historico/${cnpj}${queryString}`);

    }

    reprocessarRegistro(empresaId): Observable<any> {
        return this.http.get(`${environment.apiUrl}/consolidado/pendencia-fiscal/solicitar/${empresaId}`);
    }
}
