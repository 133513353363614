<nz-page-header class="site-page-header" nzTitle="Configurações"
                nzSubtitle="Editar SAT"></nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-affix [nzOffsetTop]="offsetTop" [nzTarget]="target">
                <nz-card class="ant-card-meta-reduce" [nzLoading]="loadingConfigSAT">
                    <nz-card-meta [nzTitle]="empresa.nome ? modoDemo(empresa.nome, 'empresaCodigoNome') : '-'"
                                  [nzDescription]="empresa.cnpj ? 'CNPJ: ' + (empresa.cnpj | cpfCnpj) : '-'"></nz-card-meta>
                    <nz-divider></nz-divider>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="ID">
                                <nz-list-item-meta-title>
                                    {{ empresa.empresa_id ? empresa.empresa_id : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>

                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Alterado em">
                                <nz-list-item-meta-title>
                                    {{ empresa.updated_at ? (empresa.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list *ngIf="empresa.updated_from">
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Alterado por">
                                <nz-list-item-meta-title>
                                    {{ empresa.updated_from ? empresa.updated_from : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                </nz-card>
                <nz-anchor class="transparent-anchor" [nzAffix]="false" [nzShowInkInFixed]="true"
                           [nzContainer]="target">
                    <nz-link nzHref="#configuracoesSAT" nzTitle="Configurações SAT"></nz-link>
                    <nz-link nzHref="#equipamentos" nzTitle="Equipamentos"></nz-link>
                    <nz-link *ngIf="loteFiltrado" nzHref="#cardLotes" nzTitle="Lotes"></nz-link>
                </nz-anchor>
            </nz-affix>
        </div>
        <ng-template #vazio>
            <div>
                -
            </div>
        </ng-template>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">
            <nz-card nzTitle="Configurações SAT" [nzExtra]="extraConfigura" [nzLoading]="loadingConfigSAT">
                <ng-template #extraConfigura>
                    <button nz-button nzType="primary"
                            (click)="showModal(formConfiguracoesSAT)">
                        Alterar
                    </button>
                </ng-template>
                <nz-list id="configuracoesSAT">
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Ativa SAT">
                            <nz-list-item-meta-title>
                                {{ configuracoesSAT.ativo ? 'SIM' : 'NÃO' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Início Download SAT">
                            <nz-list-item-meta-title>
                                {{ configuracoesSAT.satDownloadData ? (configuracoesSAT.satDownloadData | date: 'dd/MM/yyyy') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Intervalo Consulta">
                            <nz-list-item-meta-title>
                                {{ configuracoesSAT.intervaloConsulta ? configuracoesSAT.intervaloConsulta : '24' }}h
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>

            <ng-template #navBar>
                <div class="nav-item d-flex">
                    <nz-input-group
                            nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                        <input [disabled]="loadingEquipamentos" type="text" nz-input placeholder="Buscar por Nº Série"
                               (keydown.enter)="buscarEquipamentos()"
                               [(ngModel)]="currentSearchEquip"/>
                    </nz-input-group>
                    <ng-template #suffixIconButton>
                        <button [disabled]="loadingEquipamentos" nz-button nzType="primary" nzSearch
                                (click)="buscarEquipamentos();"><i
                                nz-icon nzType="search"></i></button>
                    </ng-template>
                </div>
            </ng-template>

            <nz-card nzTitle="Equipamentos" [nzLoading]="loadingEquipamentos"
                     [nzExtra]="navBar">
                <nz-table #basicTableEquip
                          id="equipamentos"
                          nzSize="small"
                          [nzScroll]="{ x: 'auto' }"
                          [nzData]="equipamentos"
                          [nzFrontPagination]="false"
                          [nzTotal]="paginationEquip?.total"
                          [nzPageSize]="paginationEquip?.per_page"
                          [nzPageIndex]="paginationEquip?.current_page"
                          (nzQueryParams)="listByTableEquip($event)"
                          [nzFooter]="footer"
                >
                    <thead>
                    <tr>
                        <th nzColumnKey="numeroSerie" [nzSortFn]="true">Nº Série</th>
                        <th nzColumnKey="fabricante" [nzSortFn]="true">Fabricante</th>
                        <th nzColumnKey="marca" [nzSortFn]="true">Marca</th>
                        <th nzColumnKey="modelo" [nzSortFn]="true">Modelo</th>
                        <th nzColumnKey="situacao" [nzSortFn]="true">Status</th>
                        <th nzColumnKey="ativacaoData" [nzSortFn]="true">Ativação Data</th>
                        <th nzColumnKey="ultimoDownloadData" [nzSortFn]="true">Último Download Data</th>
                        <th nzColumnKey="ultimaConsultaData" [nzSortFn]="true">Última Consulta Data</th>
                        <th nzRight>Lotes</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of equipamentos">
                        <td nzAlign="center">{{ data?.numeroSerie }}</td>
                        <td nzAlign="left">{{ data?.fabricante }}</td>
                        <td nzAlign="center">{{ data?.marca }}</td>
                        <td nzAlign="center">{{ data?.modelo }}</td>
                        <td nzAlign="center">{{ data?.situacao === 'A' ? 'Ativo' : 'Inativo' }}</td>
                        <td nzAlign="center">
                            <div style="width: 150px">
                                {{ data?.ativacaoData | date:'dd/MM/YY - HH:mm:ss' }}
                            </div>
                        </td>
                        <td nzAlign="center">{{ data?.ultimoDownloadData | date:'dd/MM/YY' }}
                            <a nz-button nzType="link" *ngIf="currentUser.origem === 'console'"
                               [nz-tooltip]="'Alterar (User-console)'">
                                <span (click)="showModal(formAlterarEquipamento, data)" nz-icon
                                      nzType="edit" nzTheme="outline"></span>
                            </a>
                        </td>
                        <td nzAlign="center">
                            <div style="width: 150px">
                                {{ data?.ultimaConsultaData | date:'dd/MM/YY - HH:mm:ss' }}
                            </div>
                        </td>
                        <td nzAlign="center" nzRight>
                            <button (click)="showModalFiltraLotes(true,data)"
                                    nz-button
                                    nzType="link">
                                Listar
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <ng-template #footer>
                <span *ngIf="equipamentos && equipamentos.length > 0">{{ paginationEquip?.from }}
                    -{{ paginationEquip?.to }}
                    de {{ paginationEquip?.total }} registros</span>
                    </ng-template>
                </nz-table>
            </nz-card>

            <ng-template #navBarLotes>
                <div class="nav-item d-flex">
                    <nz-badge [nzCount]="qtdFiltrosLotesAtivos"
                              [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                        <button [disabled]="loadingLotes" nz-button nzType="primary" style="margin-right: 10px;"
                                (click)="showModalFiltraLotes(true);">
                            <i nz-icon nzType="search"></i>
                            Filtros
                        </button>
                    </nz-badge>
                    <nz-input-group
                            nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                        <input [disabled]="loadingLotes" type="text" nz-input placeholder="Buscar por Protocolo"
                               (keydown.enter)="buscarLotes()"
                               [(ngModel)]="currentSearchLotes"/>
                    </nz-input-group>
                    <ng-template #suffixIconButton>
                        <button [disabled]="loadingLotes" nz-button nzType="primary" nzSearch
                                (click)="buscarLotes();"><i
                                nz-icon nzType="search"></i></button>
                    </ng-template>
                </div>
            </ng-template>

            <div id="cardLotes" #cardLotes>
                <nz-card nzTitle="Lotes (Nº Série equipamento: {{serieSelecionado}})" [nzLoading]="loadingLotes"
                         *ngIf="loteFiltrado"
                         [nzExtra]="navBarLotes">
                    <nz-table #basicLotes
                              nzSize="small"
                              [nzScroll]="{ x: 'auto' }"
                              [nzData]="lotes"
                              [nzFrontPagination]="false"
                              [nzTotal]="paginationLotes?.total"
                              [nzPageSize]="paginationLotes?.per_page"
                              [nzPageIndex]="paginationLotes?.current_page"
                              (nzQueryParams)="listByTableLotes($event)"
                              [nzFooter]="footer"
                    >
                        <thead>
                        <tr>
                            <th nzColumnKey="protocolo" [nzSortFn]="true">Protocolo</th>
                            <th nzColumnKey="numeroSerie" [nzSortFn]="true">Nº Série</th>
                            <th nzColumnKey="enviadoData" [nzSortFn]="true">Data de Envio</th>
                            <th nzColumnKey="processamentoStatus" [nzSortFn]="true">Status do Processamento</th>
                            <th nzColumnKey="processamentoData" [nzSortFn]="true">Data de processamento</th>
                            <th nzColumnKey="tipo" [nzSortFn]="true">Tipo</th>
                            <th nzColumnKey="origem" [nzSortFn]="true">Origem</th>
                            <th>Cupons Extraídos</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let data of lotes">
                            <td nzAlign="center">{{ data?.protocolo }}</td>
                            <td nzAlign="center">{{ data?.numeroSerie }}</td>
                            <td nzAlign="center">{{ data?.enviadoData | date:'dd/MM/YY - HH:mm:ss' }}</td>
                            <td nzAlign="center">{{ data?.processamentoStatus }}</td>
                            <td nzAlign="center">{{ data?.processamentoData | date:'dd/MM/YY - HH:mm:ss' }}</td>
                            <td nzAlign="center">{{ data?.tipo }}</td>
                            <td nzAlign="center">{{ data?.origem }}</td>
                            <td nzAlign="center">
                                <div *ngIf="data.minCupom && data.maxCupom else vazio">
                                    {{data.minCupom === data.maxCupom ? data.maxCupom : data.minCupom + ' a ' + data.maxCupom }}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                        <ng-template #footer>
                <span *ngIf="lotes && lotes.length > 0">{{ paginationLotes?.from }}
                    -{{ paginationLotes?.to }}
                    de {{ paginationLotes?.total }} registros</span>
                        </ng-template>
                    </nz-table>
                </nz-card>
            </div>
        </div>
    </div>
</nz-content>

<nz-modal [(nzVisible)]="formConfiguracoesSAT.modalVisible"
          [nzTitle]="'Alterar Configurações SAT'" [nzClosable]="true"
          (nzOnCancel)="showModal(formConfiguracoesSAT)" [nzWidth]="650">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formConfiguracoesSAT.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="6">Ativa SAT</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="ativo">
                        <nz-option [nzLabel]="'SIM'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'NÃO'" [nzValue]="0"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Início Download</nz-form-label>
                <nz-form-control [nzSpan]="15" nzValidateStatus="success">
                    <nz-date-picker style="width: 100%;"
                                    [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="satDownloadData">
                    </nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="6">Intervalo Consulta</nz-form-label>
                <nz-form-control [nzSpan]="15" nzValidateStatus="success">
                    <nz-input-group [nzSuffix]="suffixIconSearch">
                        <input placeholder="Valor em horas" style="width: 100%;" formControlName="intervaloConsulta"
                               nz-input type="number" min="0" max="99">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <ng-template #suffixIconSearch>
                <span style="color: rgba(0,0,0,0.25)">h</span>
            </ng-template>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModal(formConfiguracoesSAT)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="this.loadingConfigSAT" (click)="confirmaAlterarSAT()">
            Alterar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAlterarEquipamento.modalVisible"
          nzTitle="Alterar Equipamento (Nº Série: {{serieSelecionado}})" [nzClosable]="true"
          (nzOnCancel)="showModal(formAlterarEquipamento)" [nzWidth]="650">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formAlterarEquipamento.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="6">Início Download</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-date-picker style="width: 100%;"
                                    nzFormat="dd/MM/yyyy"
                                    formControlName="ultimoDownloadData">
                    </nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModal(formAlterarEquipamento)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="this.loadingAlterarEquipamento" (click)="alterarEquiplamento()">
            Alterar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrarLotes.modalVisible"
          [nzTitle]="'Filtrar Lotes'" [nzClosable]="true"
          (nzOnCancel)="showModalFiltraLotes(false)" [nzWidth]="650">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formFiltrarLotes.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="6">Data Início</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-date-picker style="width: 100%;"
                                    [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio">
                    </nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="6">Data Fim</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-date-picker style="width: 100%;"
                                    [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim">
                    </nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalFiltraLotes(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="this.loadingLotes" (click)="filtrarLotes()">
            Filtrar
        </button>
    </div>
</nz-modal>