<nz-page-header class="site-page-header" nzTitle="Administração"
                nzSubtitle="Configurações empresas, usuários e acesso da conta">
</nz-page-header>

<nz-content>

    <div nz-row [nzGutter]="16">
        <div nz-col nzXs="24" nzMd="12" nzLg="6" *ngIf="contaConfiguracao?.ativo == 1">
            <nz-card [nzTitle]="avatarConfiguracoes" style="height: 95%;" class="card-admin">
                <p>
                    Gerencie e personalize as configurações e opções da sua conta.
                    <br/>
                    <br/>
                </p>
                <p>
                    <button nz-button nzType="primary" (click)="openTab('/administracao/configuracoes-conta')">Gerenciar Configurações
                    </button>
                </p>
            </nz-card>
        </div>

        <div nz-col nzXs="24" nzMd="12" nzLg="6">
            <nz-card [nzTitle]="avatarEmpresas" style="height: 95%;" class="card-admin">
                <p>
                    Cadastre e gerencie suas unidades, empresas e grupos
                    <br/>
                    <br/>
                </p>
                <p>
                    <button nz-button nzType="primary" (click)="openTab('/administracao/empresas')">Gerenciar Empresas
                    </button>
                </p>
            </nz-card>
        </div>
        <div nz-col nzXs="24" nzMd="12" nzLg="6">
            <nz-card [nzTitle]="avatarUsuarios" style="height: 95%;" class="card-admin">
                <p>
                    Inclua ou exclua usuários, defina a visibilidade às unidades e vincule perfis de acesso
                </p>
                <p>
                    <button nz-button nzType="primary" (click)="openTab('/administracao/usuarios')">Gerenciar Usuários
                    </button>
                </p>
            </nz-card>
        </div>
        <div nz-col nzXs="24" nzMd="12" nzLg="6">
            <nz-card [nzTitle]="avatarPerfil" style="height: 95%;" class="card-admin">
                <p>
                    Crie e gerencie perfis para definir permissões de acesso dos usuários
                    <br/>
                    <br/>
                </p>
                <p>
                    <button nz-button nzType="primary" (click)="openTab('/administracao/perfis-de-acesso')">Gerenciar
                        Perfil
                        de Acesso
                    </button>
                </p>
            </nz-card>
        </div>
        <div nz-col nzXs="24" nzMd="12" nzLg="6">
            <nz-card [nzTitle]="avatarGrupoEmpresarial" style="height: 95%;" class="card-admin">
                <p>
                    Organize suas unidades e empresas cadastradas em Grupos Empresariais
                    <br/>
                    <br/>
                </p>
                <p>
                    <button nz-button nzType="primary" (click)="openTab('/administracao/grupos-empresariais')">Gerenciar
                        Grupos Empresariais
                    </button>
                </p>
            </nz-card>
        </div>
        <div nz-col nzXs="24" nzMd="12" nzLg="6">
            <nz-card [nzTitle]="avatarVisibilidades" style="height: 95%;" class="card-admin">
                <p>
                    Cadastre e gerencie as visibilidades para determinar as unidades e empresas que o usuário terá
                    acesso
                    <br/>
                    <br/>
                </p>
                <p>
                    <button nz-button nzType="primary" (click)="openTab('/administracao/visibilidadess')">Gerenciar
                        Visibilidades
                    </button>
                </p>
            </nz-card>
        </div>
        <div nz-col nzXs="24" nzMd="12" nzLg="6" *ngIf="user?.origem == 'console'">
            <nz-card [nzTitle]="avatarCockpit" style="height: 95%;" class="card-admin">
                <p>
                    Monitor para acompanhamento de implantação das empresas cadastradas
                    <br/>
                </p>
                <p>
                    <button nz-button nzType="primary" (click)="openCockipit();">
                        Abrir Cockpit de Implantação
                    </button>
                </p>
            </nz-card>
        </div>
        <div nz-col nzXs="24" nzMd="12" nzLg="6" *ngIf="user?.origem == 'console' && user?.menuTipo == 'legado'">
            <nz-card [nzTitle]="avatarAdminLegado" style="height: 95%;" class="card-admin">
                <p>
                    Acesso ao menu de Administração do sistema antigo
                    <br/>
                </p>
                <p>
                    <button nz-button nzType="primary" (click)="modalMenuAdminLegado(true);">
                        Abrir menu
                    </button>
                </p>
            </nz-card>
        </div>
        <div nz-col nzXs="24" nzMd="12" nzLg="6" *ngIf="user?.origem == 'console'">
            <nz-card [nzTitle]="avatarSincronizarEmpresas" style="height: 95%;" class="card-admin">
                <p>
                    Sincronizar empresas APP => Embedded
                    <br/>
                </p>
                <p>
                    <button nz-button nzType="primary" (click)="showConfirmSincronizarEmpresa();"
                            [nzLoading]="loadings.sincronizandoEmpresas">
                        Sincronizar
                    </button>
                </p>
            </nz-card>
        </div>
        <div nz-col nzXs="24" nzMd="12" nzLg="6">
            <nz-card [nzTitle]="avatarHistorico" style="height: 95%;" class="card-admin">
                <p>
                    Tela para visualização do histórico de acessos dos usuários
                    <br/>
                </p>
                <p>
                    <button nz-button nzType="primary" (click)="openTab('/monitoramento/historico-de-acessos')">
                        Visualizar Histórico
                    </button>
                </p>
            </nz-card>
        </div>
        <div nz-col nzXs="24" nzMd="12" nzLg="6">
            <nz-card [nzTitle]="avatarLogs" style="height: 95%;" class="card-admin">
                <p>
                    Logs de alterações de Credenciais de Acesso, Inscrições e Regimes e Notas
                    <br/>
                </p>
                <p>
                    <button nz-button nzType="primary" (click)="openTab('/administracao/logs-alteracoes')"
                            [nzLoading]="loadings.sincronizandoEmpresas">
                        Acessar Tela
                    </button>
                </p>
            </nz-card>
        </div>
    </div>
    <ng-template #avatarConfiguracoes>
        <i class="fa-solid fa-gears"></i> Configurações
    </ng-template>
    <ng-template #avatarEmpresas>
        <em class="fas fa-building"></em> Empresas
    </ng-template>
    <ng-template #avatarUsuarios>
        <em class="fas fa-user"></em> Usuários
    </ng-template>
    <ng-template #avatarPerfil>
        <em class="fas fa-user-tag"></em> Perfil de Acesso
    </ng-template>
    <ng-template #avatarGrupoEmpresarial>
        <em class="fas fa-building"></em> Grupos Empresariais
    </ng-template>
    <ng-template #avatarVisibilidades>
        <i class="far fa-eye"></i> Visibilidades
    </ng-template>
    <ng-template #avatarAdminLegado>
        <i class="fas fa-fw fa-cog"></i> Legado
        <nz-tag nzColor="error">Usuário console</nz-tag>
    </ng-template>

    <ng-template #avatarSincronizarEmpresas>
        <i class="fa fa-refresh"></i> Sincronizar Empresas
        <nz-tag nzColor="error">Usuário console</nz-tag>
    </ng-template>

    <ng-template #avatarLogs>
        <div>
            <i class="fa-solid fa-magnifying-glass"></i> Logs de alterações
        </div>
    </ng-template>

    <ng-template #avatarHistorico>
        <div>
            <i class="fa-solid fa-magnifying-glass"></i> Logs de acessos
        </div>
    </ng-template>
    <ng-template #avatarCockpit>
        <i class="fas fa-tv"></i> Cockpit de Implantação
    </ng-template>

</nz-content>


<nz-modal [(nzVisible)]="menuAdminLegado.modalVisible"
          [nzTitle]="'Menu Administração - Legado'" [nzClosable]="true"
          (nzOnCancel)="modalMenuAdminLegado(false)"
          [nzFooter]="null"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="menuAdminLegado.carregando"></nz-skeleton>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" *ngIf="!menuAdminLegado.carregando">
            <div class="megamenu-pesquisar" nz-row nzGutter="8">
                <div nz-col nzFlex="auto">
                    <nz-input-group [nzSuffix]="suffixIconSearch" id="input-search-menu">
                        <input [(ngModel)]="menuAdminLegado.searchValue" autocomplete="off" nz-input
                               placeholder="Pesquisar"
                               title="procurar item no menu" type="text">
                    </nz-input-group>
                    <ng-template #suffixIconSearch>
                        <em (click)="menuAdminLegado.searchValue=''" nz-icon nzType="close"></em>
                    </ng-template>
                </div>
                <div nz-col nzFlex="100px">
                    <button (click)="expandAllHandler(true)" nz-button>
                        <em class="fas fa-angle-double-down"></em>
                    </button>
                    <button (click)="expandAllHandler(false)" nz-button>
                        <em class="fas fa-angle-double-up"></em>
                    </button>
                </div>
            </div>

            <div class="megamenu-arvore" style="margin-top: 20px;">

                <ul class="">
                    <li *ngFor="let menu of menuAdminLegado.menus">
                        <h5 nz-typography>
                            <em class="{{ menu.icon }}"></em> {{ menu.title }}
                        </h5>
                        <nz-tree #treeMegaMenu
                                 [nzData]="menu?.children"
                                 (nzClick)="nzEvent($event)"
                                 [nzExpandAll]="expandAll"
                                 [nzSearchValue]="menuAdminLegado.searchValue"
                                 [nzTreeTemplate]="nzTreeTemplate"
                                 nzBlockNode="false"
                        ></nz-tree>
                        <ng-template #nzTreeTemplate let-node let-origin="origin">
                          <span id="{{ node.key }}" title="{{ origin.url }}">
                             <span [ngStyle]="node.level == 0 && node.children.length == 0 ? {'margin-left': '25px'} : {'margin-left': 35 * node.level + 'px'}">
                                 {{ node.title }}
                             </span>
                          </span>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nz-modal>
