import {ChangeDetectorRef, Component, HostListener, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TabService} from '@services/tab.service';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import * as fileSaver from 'file-saver-es';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import * as Highcharts from 'highcharts/highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import {DomicilioEletronicoService} from './domicilioEletronico.service';
import {StickyHeaderDirective} from '@components/directive/directive';
import {NzModalService} from 'ng-zorro-antd/modal';
import {DataService} from '@services/data.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {CpfCnpjPipe} from '../../../shared/pipes/cpfCnpj.pipe';
import moment from 'moment';
import {ContaService} from '@services/conta.service';
import {buildUrl, findComponentByUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {environment} from '../../../../environments/environment';
import {AnexosListService} from '@components/anexos-list/anexos-list.service';
import {Subscription} from 'rxjs';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-domicilioEletronico',
    templateUrl: './domicilioEletronico.component.html',
    styleUrls: ['./domicilioEletronico.component.scss']
})
export class DomicilioEletronicoComponent extends AbstractListTable<any> implements OnInit {

    @Input() data: any[];

    checked = false;
    arrayDecs = [];
    objectInfos: any;
    qtdFiltrosAtivos = 0;
    qtdFiltrosAtivosTwo = 0;
    formFiltros: FormStack;
    formFiltrosCabecalho: FormStack;
    formFiltrosMensagens: FormStack;
    formExportar: FormStack;
    offsetTop = 0;
    target: any;
    statusOne = false;
    statusTwo = false;
    statusThree = false;
    tabNumber = 0;

    arraySelectDomicilios: any = [];
    arraySelectCategorias = [];
    arraySelectUnidades = [];

    modalMensagemFlag = false;
    conteudoMensagem = '';
    leituraSolicitacaoUsuarioNome = null;
    leituraProcessamentoData = null;

    modalLogFlag = false;
    conteudoLog = '';
    ultimoProcessamento = '';
    registroSelecionado: any = null;

    screenHeight = 0;
    screenWidth = 0;
    screenModalTop = 0;

    totDisabled = 0;

    loadingCardDecs = false;
    loadingContadores = false;

    currentUser: any;

    textAreaValue: FormStack;
    iframeUrl: SafeResourceUrl = '';
    isPdf = false;

    loadings = {
        exportar: false,
        verAnexos: false,
        downloadAnexo: {}
    };

    processamentoFlag = {};
    loadingLeituraEmpresasConsole = false;
    loadingLeituraMensagensConsole = false;

    arrayModulos = [];
    showAlert = false;
    showContent = false;

    itemsMensagens = [];

    paginationEmpresas: Pagination = {
        current_page: 1,
        total: 50,
        per_page: 50,
        last_page: 50,
        from: 1,
        to: 50
    };

    domicilioEletronicoEmpresaIdSelected = null;

    listOfAnexos = [];
    loadingModalAnexos = {};
    modalVerAnexosVisible = false;

    taskSelected = null;
    consultasNaoRealizadas: any = {};
    solicitarLeituraDisabled = true;

    private screenPermissoes: {
        solicitarLeitura: string
    } = {
        solicitarLeitura: '4745fe5ea2'
    };
    disabledProximaPagina = false;

    qtdFiltrosCabecalho = 0;
    arraySelectGrupos = [];

    reqCabecalho: Subscription;
    reqEmpresasMensagens: Subscription;
    reqDecs: Subscription;

    loadingJson = {};

    constructor(
        private fb: UntypedFormBuilder,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        private modalService: NzModalService,
        private service: DomicilioEletronicoService,
        private contaService: ContaService,
        private dataService: DataService,
        public sanitizer: DomSanitizer,
        public anexosListService: AnexosListService,
        private i18n: NzI18nService) {

        super(service, null, toastService);

        this.virificarStatusModulo();

        this.i18n.setLocale(pt_BR);

        this.getScreenSize();

        this.formFiltros = {
            modalVisible: false,
            formGroup: this.fb.group({
                domicilio: [null, null],
                unidade: [null, null],
                ultimaConsulta: [null, null],
                status: [null, null],
            })
        };

        this.formFiltrosCabecalho = {
            modalVisible: false,
            formGroup: this.fb.group({
                grupoEmpresarial_id: [null, null]
            })
        };

        this.formFiltrosMensagens = {
            modalVisible: false,
            formGroup: this.fb.group({
                domicilio: [null, null],
                unidade: [null, null],
                ie: [null, null],
                im: [null, null],
                dataDe: [null, null],
                dataAte: [null, null],
                categoria: [null, null],
                prazoTacito: [null, null],
                statusLeitura: [null, null],
                statusVisualizacao: [null, null],
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.textAreaValue = {
            modalVisible: false,
            formGroup: this.fb.group({
                json: [null, null],
            })
        };
    }

    ngOnInit() {
        this.currentParams = {
            pageIndex: 1,
            pageSize: 50,
            sort: [],
            filter: []
        };

        this.queryTable(this.currentParams);

        this.retornaGrupos();

        this.getPermissoesPerfil().then(() => {
            this.solicitarLeituraDisabled = !this.hasPermissao(this.screenPermissoes.solicitarLeitura, this.currentPermissoes);
        });

    }

    retornaGrupos(): void {

        this.service.retornaselectsGrupos().subscribe({
            next: (retorno: any) => {
                this.arraySelectGrupos = retorno.grupoEmpresarial;
            },
            error: (err) => {
                this.toastService.error('Erro ao retornar grupos!');
            }
        });
    }

    filtrarCabecalho() {
        this.calculaBadgeFiltros();
        this.retornaInfos();
        this.retornaDecs();
        this.retornaDomicilios(this.currentParams);
        this.fechar(this.formFiltrosCabecalho);
    }


    changeTabs(event: any = null) {
        // this.queryTable(this.currentParams);
        this.checked = false;
        this.checkedItems.clear();
        this.totDisabled = 0;

        if (!this.formFiltrosMensagens.formGroup.get('domicilio').value) {
            const today = new Date();
            const thirtyDaysAgo = new Date(today);
            thirtyDaysAgo.setDate(today.getDate() - 30);

            this.formFiltrosMensagens.formGroup.get('dataDe').setValue(thirtyDaysAgo);
            this.formFiltrosMensagens.formGroup.get('dataAte').setValue(today);
        }
    }

    clickEvent(card) {

        switch (card) {

            case 'one':
                this.statusOne = !this.statusOne;
                break;

            case 'two':
                this.statusTwo = !this.statusTwo;
                break;

        }
    }

    toggleCard() {
        this.statusThree = !this.statusThree;
    }

    virificarStatusModulo() {

        this.contaService.modulosAtivos().subscribe((response) => {

                this.arrayModulos = response;
                if (this.arrayModulos.includes('dec')) {
                    this.showContent = true;
                } else {
                    this.showAlert = true;
                }

                if (!this.showAlert) {

                    this.changeSelectDomicilios(null);

                    this.changeSelectUnidades(null);

                    this.service.retornarSelectCategorias().subscribe((retorno: any) => {
                        this.arraySelectCategorias = retorno;

                    });

                    if (this.data && this.data[0]?.tabNumber) {
                        this.tabNumber = this.data[0].tabNumber;
                    }

                    if (this.data && this.data[0]?.nLidas) {
                        this.formFiltrosMensagens.formGroup.get('statusLeitura').setValue('nLidas');
                    }

                    this.retornaDecs();
                    this.retornaInfos();

                    this.target = StickyHeaderDirective.target;
                    this.cdr.detectChanges();

                    this.dataService.currentUser.subscribe((data) => {
                        this.currentUser = data;
                        this.cdr.detectChanges();
                    });
                }
            },
            (response) => {
                this.toastrService.error(response.error.message);

            }
        );

    }

    retornaDecs() {
        this.reqDecs?.unsubscribe();

        this.loadingCardDecs = true;

        this.reqDecs = this.service.retornaDecs(this.formFiltrosCabecalho.formGroup.value).subscribe((response) => {

                this.arrayDecs = response;

                let index = response.length;

                this.arrayDecs.forEach((value) => {
                    value.novasMensagens = this.getRandomInt(0, 3);
                    value.bgColor = this.stringToColour(value.descricao);
                    value.zIndex = index;
                    value.qtdeNaoLida = Number(value.qtdeNaoLida);
                    index--;

                    if (value.processamentoAtrasado) {
                        this.consultasNaoRealizadas[value.id] = value.processamentoAtrasado;
                    }
                });

                this.arrayDecs.sort((a, b) => {

                    if (a.qtdeNaoLida !== b.qtdeNaoLida) {
                        return b.qtdeNaoLida - a.qtdeNaoLida;
                    }

                    return b.qtdeTotal - a.qtdeTotal;
                });


                this.loadingCardDecs = false;

            },
            (response) => {
                this.toastrService.error(response.error.message);
            }
        );

    }

    retornaInfos() {

        this.reqCabecalho?.unsubscribe();

        this.loadingContadores = true;

        this.reqCabecalho = this.service.retornaInfos(this.formFiltrosCabecalho.formGroup.value).subscribe((response) => {

                this.objectInfos = response;

                this.loadingContadores = false;

            },
            (response) => {

                this.loadingContadores = false;

                this.toastrService.error(response.error.message);

            }
        );

    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    limparFiltros(): void {
        this.checkedItems.clear();
        this.checked = false;
        this.formFiltros.formGroup.reset();
        this.formFiltrosMensagens.formGroup.reset();
        this.domicilioEletronicoEmpresaIdSelected = null;
        this.currentSearch = null;
        this.currentParams.pageIndex = 1;
        this.retornaDomicilios(this.currentParams);
    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
    }

    exportarMensagens(): void {


        const url = `${environment.apiUrl}/exportador`;

        for (const [chave, valor] of Object.entries(this.formFiltrosMensagens.formGroup.value)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.service.exportarMensagens(this.currentParams, this.currentSearch, url).subscribe((res) => {

            const message = res.message + ' Acompanhe na central de downloads.';

            this.toastService.success(message);

            this.openTabByComponent('DownloadCenterComponent', '', {});


        }, (res) => {
            this.toastService.error(res.error.message);
        });
    }

    downloadArquivo(base64String = null, fileName = null, formato) {

        if (base64String) {
            const source = `data:application/${formato};base64,${base64String}`;
            const link = document.createElement('a');
            link.href = source;
            link.download = fileName ? `${fileName}.${formato}` : 'download.' + formato;
            link.click();

        } else {

            this.toastService.error('Arquivo não encontrado.');
        }

    }

    async modalMensagem(visible: boolean, task = null) {

        this.taskSelected = task;

        if (task) {

            if (!task.vistoEm && !task.vistoPor) {

                const res = await this.service.marcarComoLida(task.domicilioEletronicoLeitura_id).toPromise();

            }

            if (task.arquivoExt && task.arquivoExt !== 'pdf' && task.arquivoExt !== 'html' && task.projeto !== 'dec-nodejs') {
                this.toastService.warning('Formato de arquivo incompatível. Será baixado automaticamente');

                this.downloadArquivo(task.txtMsg, 'Mensagem', task.arquivoExt);

                visible = false;
            }

            if (task.arquivoExt === 'pdf' || (task.projeto === 'dec-nodejs' && task.tipoMensagem === 'pdf')) {


                this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + task.txtMsg);
                this.isPdf = true;

                this.modalMensagemFlag = visible;

                return;
            }


            this.leituraSolicitacaoUsuarioNome = task.leituraSolicitacaoUsuarioNome;
            this.leituraProcessamentoData = task.leituraSolicitacaoData;
        }


        if (!task?.arquivoExt || task?.arquivoExt === 'html' || task?.projeto === 'dec-nodejs') {

            this.conteudoMensagem = task?.txtMsg ? task.txtMsg.replaceAll('\n', '<br>') : '';
            this.isPdf = false;

        }

        this.modalMensagemFlag = visible;

    }

    modalLog(visible: boolean, registro = null): void {
        this.registroSelecionado = registro;

        if (registro) {
            this.conteudoLog = '<pre>';
            this.conteudoLog += registro.processamentoLog ? registro.processamentoLog : '';
            this.conteudoLog += '</pre>';
            this.ultimoProcessamento = registro.ultimoProcessamento;
        }

        this.modalLogFlag = visible;

    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.currentSearch = search;

        this.retornaDomicilios(params);

        this.resetCheckedSet();

    }

    listByTableEmpresas(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginationEmpresas.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginationEmpresas.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginationEmpresas.current_page) {
            this.queryTable(params);
            this.paginationEmpresas.current_page = params.pageIndex;
        }

    }

    retornaDomicilios(params: NzTableQueryParams) {
        this.reqEmpresasMensagens?.unsubscribe();

        this.calculaBadgeFiltros();

        this.loading = true;

        const order = params ? this.service.montaOrder(params.sort) : {};

        let url = '';
        let filtros: any = {};

        switch (this.tabNumber) {

            case 0 :
                url = '/domicilio/unidades';
                filtros = this.formFiltros.formGroup.value;
                break;

            case 1 :
                url = '/domicilio/mensagens';
                filtros = this.formFiltrosMensagens.formGroup.value;
                if (this.domicilioEletronicoEmpresaIdSelected) {
                    filtros.domicilioEletronicoEmpresa_Id = this.domicilioEletronicoEmpresaIdSelected;
                }
                break;

        }

        const busca = this.currentSearch?.length > 0 ? this.currentSearch : null;

        if (this.formFiltrosCabecalho.formGroup.value) {
            filtros = {...filtros, ...this.formFiltrosCabecalho.formGroup.value};
        }

        this.reqEmpresasMensagens = this.service.retornaDomicilios(filtros, order, url, params.pageIndex, busca).subscribe((response) => {

            this.totDisabled = 0;

            switch (this.tabNumber) {

                case 0 :
                    this.items = [];

                    this.items = response?.data || response;

                    this.items.forEach((value) => {

                        if (typeof value.preRequisitos === 'string' || value.preRequisitos === false) {
                            value.exibirPreRequisito = true;
                        }

                        this.processamentoFlag[value.processamento_id] = false;

                        value.proximaConsulta = value.proximaConsulta ? this.timeBetweenDates(value.proximaConsulta) : null;

                        value.disabled = value.modoLeitura === 0 || value.modoLeitura === null ?
                            true : (value.modoLeitura === 1 && false);

                        this.totDisabled = value.disabled ? this.totDisabled + 1 : this.totDisabled;

                    });

                    break;
                case 1 :

                    this.itemsMensagens = [];

                    this.itemsMensagens = response?.data || response;

                    this.itemsMensagens.forEach((value) => {

                        const solicitarLeitura = (!value.anexo && !value.txtMsg) || (value.anexo === 1 && !value.arquivoNome);

                        value.disabled = !solicitarLeitura;

                        this.totDisabled = value.disabled ? this.totDisabled + 1 : this.totDisabled;
                    });

                    break;

            }

            if (this.tabNumber === 0) {
                this.paginationEmpresas = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50);

            } else {
                this.disabledProximaPagina = !response.next_page_url;
            }


            this.loading = false;

        });

    }

    filtrar() {

        this.formFiltros.modalVisible = false;

        this.formFiltrosMensagens.modalVisible = false;

        this.currentParams.pageIndex = 1;

        this.retornaDomicilios(this.currentParams);

    }

    stringToColour(str) {

        let hash = 0;

        for (let i = 0; i < str.length; i++) {
            // tslint:disable-next-line:no-bitwise
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        let colour = '#';

        for (let i = 0; i < 3; i++) {
            // tslint:disable-next-line:no-bitwise
            const value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }

        return colour;

    }

    calculaBadgeFiltros(): void {

        let qtd = 0;

        Object.entries(this.formFiltros.formGroup.value).forEach((value) => {

            if (value[0] !== 'sort' && value[0] !== 'page' && value[1] !== null) {
                qtd += 1;
            }

        });

        this.qtdFiltrosAtivos = qtd;


        qtd = 0;

        Object.entries(this.formFiltrosMensagens.formGroup.value).forEach((value) => {

            if (value[0] !== 'sort' && value[0] !== 'page' && value[1] !== null) {
                qtd += 1;
            }

        });

        this.qtdFiltrosAtivosTwo = qtd;

        qtd = 0;

        Object.entries(this.formFiltrosCabecalho.formGroup.value).forEach((value) => {

            // @ts-ignore
            if (value[0] === 'grupoEmpresarial_id' && value[1]?.length) {
                qtd += 1;
            }

        });

        this.qtdFiltrosCabecalho = qtd;

    }

    onClickDec(decId: string): void {
        this.formFiltros.formGroup.get('domicilio').setValue(decId);
        this.formFiltrosMensagens.formGroup.get('domicilio').setValue(decId);
        this.formFiltros.formGroup.get('unidade').reset();
        this.formFiltrosMensagens.formGroup.get('unidade').reset();
        this.filtrar();
    }

    confirmaExportar(): void {

        if (this.formExportar.formGroup.valid) {


            this.loadings.exportar = true;
            const tipo = this.formExportar.formGroup.value.tipo;

            let url = '';
            let filtros: any = {};
            switch (this.tabNumber) {
                case 0: {
                    url = '/domicilio/unidades';
                    filtros = this.formFiltros.formGroup.value;
                    break;
                }
                case 1: {
                    url = '/domicilio/mensagens';
                    filtros = this.formFiltrosMensagens.formGroup.value;
                    delete filtros.page;
                    break;
                }
            }

            if (this.formFiltrosCabecalho.formGroup.value) {
                filtros = {...filtros, ...this.formFiltrosCabecalho.formGroup.value};
            }

            this.service.exportExcel(url, filtros, this.currentSearch, tipo).subscribe((res) => {

                if (this.formExportar.formGroup.value.tipo) {

                    const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                    const name = this.tabNumber === 0 ? 'Domicílio Eletrônico: Empresas' : 'Domicílio Eletrônico: Mensagens';

                    fileSaver.saveAs(blob, name + '.' + this.formExportar.formGroup.value.tipo);

                    this.formExportar.modalVisible = false;
                }
                this.loadings.exportar = false;

            }, (res) => {
                this.toastService.error(res.error.message);
                this.loadings.exportar = false;
            });

        } else {
            for (const key in this.formExportar.formGroup.controls) {

                if (key) {

                    const campo = this.formExportar.formGroup.get(key);

                    campo.markAsDirty();
                    campo.updateValueAndValidity();

                }
            }
        }

    }


    filtrarMensagens(dec: string, empresaId: any, domicilioEletronicoEmpresaId: number): void {

        this.domicilioEletronicoEmpresaIdSelected = domicilioEletronicoEmpresaId;

        this.formFiltrosMensagens.formGroup.get('domicilio').setValue(dec);
        this.formFiltrosMensagens.formGroup.get('unidade').setValue(empresaId);

        this.tabNumber = 1;
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.resizeModal();
    }

    resizeModal() {

        this.screenHeight = Math.round((window.innerHeight / 100) * 73);
        this.screenWidth = Math.round((window.innerWidth / 100) * 90);
        this.screenModalTop = Math.round((window.innerHeight / 100) * 9);

    }

    atualizarTela() {
        this.retornaDecs();
        this.retornaInfos();
        this.queryTable(this.currentParams, this.currentSearch);
    }

    modalJson(visible) {
        this.textAreaValue.modalVisible = visible;
    }

    retornaJson(id) {

        this.loadingJson[id] = true;

        this.service.retornaJson(id).subscribe((res) => {

            this.textAreaValue.formGroup.get('json').setValue(JSON.stringify(res, null, '\t'));
            this.textAreaValue.modalVisible = true;
            this.loadingJson[id] = false;

        }, error => {
            this.loadingJson[id] = false;
            this.toastrService.error(error.error.message);
        });

    }

    processarMensagensConfirm() {

        const msg = this.checkedItems.size === 1 ?
            'Deseja realmente realizar a leitura da mensagem selecionada?' :
            (this.checkedItems.size > 1 && 'Deseja realmente realizar a leitura de todas as mensagens selecionadas?');

        this.modalService.confirm({
            nzTitle: msg,
            nzOkText: 'Processar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.processarMensagens()
        });

    }

    processarMensagemConfirm(id = null) {

        this.modalService.confirm({
            nzTitle: 'Deseja realmente realizar a leitura da mensagem selecionada?',
            nzOkText: 'Processar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.processarMensagens(id)
        });

    }

    processarMensagemUserConsoleConfirm() {

        this.modalService.confirm({
            nzTitle: 'Deseja realmente realizar a leitura das mensagens selecionadas?',
            nzOkText: 'Processar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.processarMensagensUserConsole()
        });

    }

    orLerNOvamente(id = null) {

        this.modalService.confirm({
            nzTitle: 'Deseja realmente solicitar nova leitura dessa(s) mensage(s)?',
            nzOkText: 'Processar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.confirmLerNovamente(id)
        });

    }

    processarMensagens(id = null) {

        const data = id ? [id] : Array.from(this.checkedItems);

        this.service.processarMensagens(data).subscribe((res) => {

            this.toastrService.success(res.message);

            this.queryTable(this.currentParams, this.currentSearch);

            this.resetCheckedSet();

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    processarMensagensUserConsole() {

        this.service.processarMensagens(Array.from(this.checkedItems), true).subscribe((res) => {

            this.toastrService.success(res.message || 'Mensagens lidas com sucesso.');

            // this.queryTable(this.currentParams, this.currentSearch);

            this.resetCheckedSet();

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }


    confirmLerNovamente(id = null) {

        const data = id ? [id] : Array.from(this.checkedItems);

        this.service.lerNovamente(data, true).subscribe((res) => {

            this.toastrService.success('Solicitação de leitura efetuada com sucesso.');

            // this.queryTable(this.currentParams, this.currentSearch);

            this.resetCheckedSet();

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    processarMensagensEmpresasConfirm() {

        const msg = this.checkedItems.size === 1 ?
            'Deseja realmente realizar a leitura de toda a caixa de mensagens desta Empresa?' :
            (this.checkedItems.size > 1 && 'Deseja realmente realizar a leitura de todas as caixas de mensagens dessas empresas?');

        this.modalService.confirm({
            nzTitle: msg,
            nzOkText: 'Processar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.processarMensagensEmpresas()
        });

    }

    processarMensagensEmpresas() {

        this.service.processarMensagensEmpresas(Array.from(this.checkedItems)).subscribe((res) => {

            this.toastrService.success(res.message);

            this.queryTable(this.currentParams, this.currentSearch);

            this.resetCheckedSet();

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    processarMensagensEmpresaConfirm(id, cnpj) {

        this.modalService.confirm({
            nzTitle: 'Deseja realmente realizar a leitura de toda a caixa de mensagens desta empresa '
                + CpfCnpjPipe.prototype.transform(cnpj) + '?',
            nzOkText: 'Processar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.processarMensagensEmpresa(id)
        });

    }

    confirmProcessarMensagensEmpresaUserConsole() {

        this.modalService.confirm({
            nzTitle: 'Deseja realmente realizar a leitura de toda a caixa de mensagen das empresas selecionadas ?',
            nzOkText: 'Processar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.processarMensagensEmpresaUserConsole()
        });

    }

    processarMensagensEmpresa(id) {

        this.service.processarMensagensEmpresa(id).subscribe((res) => {

            this.queryTable(this.currentParams, this.currentSearch);

            this.toastrService.success(res.message);

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    processarMensagensEmpresaUserConsole() {

        this.loadingLeituraEmpresasConsole = true;

        this.service.processarMensagensEmpresa(Array.from(this.checkedItems), true).subscribe((res) => {

            // this.queryTable(this.currentParams, this.currentSearch);
            this.loadingLeituraEmpresasConsole = false;


            this.toastrService.success('Mensagens lidas com sucesso!');

        }, (response) => {

            this.loadingLeituraEmpresasConsole = false;
            this.toastrService.error(response.error.message);

        });

    }

    changeSelectUnidades(domicilio) {

        this.service.retornarSelectUnidades(domicilio).subscribe((retorno) => {

            this.arraySelectUnidades = retorno;

        }, (retorno) => {

            this.toastrService.error(retorno.error.message);

        });

    }

    changeSelectDomicilios(domicilio) {

        this.service.retornarSelectDomicilios(domicilio).subscribe((retorno: object) => {


            this.arraySelectDomicilios = retorno;


        }, (retorno) => {

            this.toastrService.error(retorno.error.message);

        });

    }

    showConfirm(id: any): void {

        this.modalService.confirm({
            nzTitle: 'Deseja reprocessar o registro?',
            nzOkText: 'Reprocessar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.reprocessarRegistro(id)
        });

    }

    reprocessarRegistro(id) {

        this.processamentoFlag[id] = true;

        this.toastService.info('Processamento iniciado.');

        this.service.reprocessarRegistro(id).subscribe((retorno: any) => {

            this.toastService.success(retorno.message);

            this.processamentoFlag[id] = false;

            this.modalLog(false);

        }, (retorno) => {

            this.toastrService.error(retorno.message || 'Erro ao reprocessar registro');

            this.processamentoFlag[id] = false;

        });

    }

    timeBetweenDates(toDate) {

        const now = new Date();
        const dateEntered = new Date(toDate);

        if (now.getTime() > dateEntered.getTime()) {
            // dateEntered = new Date();
            // dateEntered.setDate(now.getDate() + 1);

            return moment(dateEntered).format('DD/MM/YYYY HH:mm');
        }

        const hoje = now.getDate();
        const diaProximaConsulta = dateEntered.getDate();
        const horas = dateEntered.getHours();
        const minutos = dateEntered.getMinutes();


        const diferenca = diaProximaConsulta - hoje;

        let text = '';

        if (diferenca === 0) {
            text = 'Hoje às ' + horas + 'h';
            minutos > 0 ? text += minutos + 'm' : text = text;
        }

        if (diferenca === 1) {
            text = 'Amanhã às ' + horas + 'h';
            minutos > 0 ? text += minutos + 'm' : text = text;
        }

        if (diferenca > 1) {
            text = 'Em ' + diferenca + ' dias';
        }

        return text;

    }

    openTab(componentName: string, queryParams?: string, data?: any) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        let newTab: any;
        if (data.cnpj) {
            const cnpj = data.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
            newTab = new Tab(component.name, cnpj + ' - ' + component.title, url, component.urlType, data);
        } else {
            newTab = new Tab(component.name, component.title, url, component.urlType, data);
        }

        this.tabService.closeAndAddTab(newTab);
    }

    onAllCheckedMsg(checked: boolean): void {
        this.checked = checked;

        this.itemsMensagens.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSet(id, checked));

    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);
    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        if ((this.tabNumber === 0 && this.items.length - this.totDisabled !== this.checkedItems.size)
            || (this.tabNumber === 1 && this.itemsMensagens.length - this.totDisabled !== this.checkedItems.size)) {
            this.checked = false;
        } else {
            this.checked = true;
        }
    }

    openTabByComponent(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    modalAnexos(visible: boolean, anexos = []): void {
        if (visible && (!anexos || anexos.length === 0)) {
            this.listOfAnexos = [];
            this.toastService.warning('Sem arquivos para exibir');
        } else {
            this.listOfAnexos = anexos;
            this.modalVerAnexosVisible = visible;
        }
    }

    downloadAanexo(task = null) {

        this.taskSelected = task || this.taskSelected;

        this.loadings.downloadAnexo[this.taskSelected.id] = true;

        this.service.baixaAnexo(this.taskSelected.id).subscribe((res) => {

            fileSaver.saveAs(res, this.taskSelected.arquivoNome);

            this.loadings.downloadAnexo[this.taskSelected.id] = false;

        }, (res) => {
            this.toastService.error('Falha ao fazer download.');
            this.loadings.downloadAnexo[this.taskSelected.id] = false;
        });

    }

    paginaAnterior() {

        this.currentParams.pageIndex--;
        this.queryTable(this.currentParams, this.currentSearch);


    }

    paginaProxima() {
        this.currentParams.pageIndex++;
        this.queryTable(this.currentParams, this.currentSearch);
    }
}
