import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import moment from 'moment';
import {MonitorDeEventosService} from './monitor-de-eventos.service';
import {Helpers} from "../../../core/helpers";

@Component({
    selector: 'app-monitor-de-eventos',
    templateUrl: './monitor-de-eventos.component.html',
    styleUrls: ['./monitor-de-eventos.component.scss']
})
export class MonitorDeEventosComponent implements OnInit, OnDestroy {

    currentParams: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };

    currentSearch = null;

    paginations = new Pagination();

    loadingTable = false;
    statusCard = false;

    items = [];

    checkedItems = new Set<string>();
    checked = false;

    loadingEventos = [];

    intervals = [];

    tabs = [
        {key: 'Pendentes', value: '0'},
        {key: 'Concluídos', value: 2},
    ];

    // se 2 vem dos úmtimos 30 dias
    // Vem oque é Pendente ou Processando (0,1)

    showTable = '0';

    constructor(
        private toastService: ToastrService,
        private service: MonitorDeEventosService
    ) {
    }

    ngOnInit() {
        this.queryTable(this.currentParams);
    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        if (this.items.length === this.checkedItems.size) {
            this.checked = true;
        } else {
            this.checked = false;
        }
    }

    onAllChecked(checked: boolean): void {

        this.checked = checked;

        this.items.forEach(({id}) => this.updateCheckedSet(id, checked));

    }

    changeTable(tabela) {

        this.checkedItems.clear();
        this.checked = false;

        this.showTable = tabela;
        this.queryTable(this.currentParams, this.currentSearch);

    }

    clickEvent() {
        this.statusCard = !this.statusCard;
    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.current_page) {
            this.queryTable(params);
            this.paginations.current_page = params.pageIndex;
        }

    }

    buscar() {
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }


    queryTable(params: NzTableQueryParams, search: string = null) {

        this.currentParams = params;

        this.currentSearch = search;

        this.currentParams.filter = [];

        this.loadingTable = true;

        this.currentParams.filter.push({key: 'status', value: this.showTable || '0'});

        if (this.currentSearch) {
            this.currentParams.filter.push({key: 'procurar', value: this.currentSearch});
        }

        this.service.listaEventos(this.currentParams).subscribe({
            next: (response) => {

                this.items = [];
                this.items = response.data;

                this.items.forEach(i => {

                    i.detalhesProcessamento = i.usuario_nome ?
                        `Reprocessamento solicitado por ${i.usuario_nome} em ${moment(i.created_at).format('DD/MM/yyyy HH:mm')}.` : null;

                    if (i.status?.toString() === '0' && i.data_executar) {
                        const intervalo = setInterval(() => {
                            i.cronometro = this.cronometro(i.data_executar);
                            i.cronometro = i.cronometro.includes('-') ? 'instantes' : i.cronometro;
                        }, 1000);

                        this.intervals.push(intervalo);
                    }
                });

                this.paginations = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50);

                this.loadingTable = false;

            }, error: (err) => {
                this.loadingTable = false;
                this.toastService.error(err.error.message);
            }
        });
    }

    btnResetSearch() {

        this.checkedItems.clear();
        this.checked = false;

        this.currentSearch = null;
        this.currentParams.pageIndex = 1;
        this.currentParams.filter = [];

        this.queryTable(this.currentParams, this.currentSearch);

    }

    reloadTable() {
        this.queryTable(this.currentParams, this.currentSearch);
    }

    confirmaEvento(ids: string[] = null): void {

        let eventos = [];

        if (!ids) {
            eventos = Array.from(this.checkedItems);
        } else {
            eventos = ids;
        }

        eventos.forEach(e => {
            this.loadingEventos.push(e);
        });

        const message = eventos.length > 1 ? eventos.length + ' eventos confirmados com sucesso.' : 'Evento confirmado com sucesso';


        this.service.confirmaEvento(Array.from(eventos)).subscribe({
            next: (response) => {
                this.toastService.success(message);
                this.checkedItems.clear();
                this.checked = false;
                this.loadingEventos = [];
                this.queryTable(this.currentParams);

            }, error: (err) => {
                this.loadingTable = false;
                this.toastService.error(err.error.message);
            }
        });

    }

    reprocessar(id): void {

        this.loadingEventos.push(id);

        this.service.reprocessar([id]).subscribe({
            next: (response) => {
                this.toastService.success(response.message);
                this.loadingEventos = [];
                this.queryTable(this.currentParams);

            }, error: (err) => {
                this.loadingEventos = [];
                this.toastService.error(err.error.message);
            }
        });

    }

    loadingButton(arr: string[]) {
        return arr.some(item => this.loadingEventos.includes(item));
    }

    cronometro(data: Date) {
        const agr = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
        const dataConfirmacao = moment(data).format('DD/MM/YYYY HH:mm:ss');

        const ms = moment(dataConfirmacao, 'DD/MM/YYYY HH:mm:ss').diff(moment(agr, 'DD/MM/YYYY HH:mm:ss'));
        const d = moment.duration(ms);

        const dias = Math.floor(d.asDays());

        const horas = Math.floor(d.asHours()) % 24;  // Para obter as horas do dia, excluindo os dias completos
        const minutos = moment.utc(ms).format('mm');
        const segundos = moment.utc(ms).format('ss');

        if (dias > 0) {
            return `${dias} ${dias > 1 ? 'dias' : 'dia'} ${horas}:${minutos}:${segundos}`;
        } else {
            return `${horas}:${minutos}:${segundos}`;
        }
    }

    ngOnDestroy() {
        this.intervals.forEach(i => {
            clearInterval(i);
        });
    }

}
