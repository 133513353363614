import {Component, HostListener, OnInit} from '@angular/core';
import {RelatorioPendenciasFiscaisService} from './relatorio-pendencias-fiscais.service';
import {ToastrService} from 'ngx-toastr';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Helpers} from '../../../core/helpers';
import * as fileSaver from 'file-saver-es';
import {DataService} from '@services/data.service';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-relatorio-pendencias-fiscais',
    templateUrl: './relatorio-pendencias-fiscais.component.html',
    styleUrls: ['./relatorio-pendencias-fiscais.component.scss']
})
export class RelatorioPendenciasFiscaisComponent implements OnInit {

    loadingContadores = true;
    loadingTabela = true;
    loadingExportarPlanilha = false;
    loadingExportarPDF = false;
    loadingHistorico = {};

    contadores: any = {};
    items: any[] = [];
    itemsHistorico = [];

    expand = false;

    currentParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
        }
    };

    pagination = new Pagination();

    currentSearch: string;

    qtdFiltrosAtivos = 0;

    formFiltros = this.fb.group({
        empresaId: [null],
        statusEmissao: [null],
        dataUltimaEmissao: [null]
    });

    formExportar = {
        modalVisible: false,
        formGroup: this.fb.group({
            tipo: [null, Validators.required],
        })
    };

    visible = {
        filtrar: false,
        exportPDF: false,
        exportPlanilha: false,
        modalHistorico: false,
    };

    loadingVerRelatorio = {};

    conteudoUltimoRelatorio = '';
    base64Selecionado = '';
    iframeUrl: SafeResourceUrl = '';

    modalRelatorioVisible = false;

    screenHeight = 0;
    screenWidth = 0;
    screenModalTop = 0;

    arraySelectEmpresa = [];

    cnpjHistorico = '';
    processamentoFlag = {};

    currentUser: any;

    constructor(
        private service: RelatorioPendenciasFiscaisService,
        private toastrService: ToastrService,
        private fb: UntypedFormBuilder,
        public sanitizer: DomSanitizer,
        private dataService: DataService,
        private modalService: NzModalService
    ) {

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
        });

    }

    ngOnInit() {
        this.service.retornarSelectsEmpresas().subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
        });
        this.getContadores();
    }

    ngAfterViewInit() {
        this.resizeModal();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.resizeModal();
    }

    resizeModal() {

        this.screenHeight = Math.round((window.innerHeight / 100) * 73);
        this.screenWidth = Math.round((window.innerWidth / 100) * 90);
        this.screenModalTop = Math.round((window.innerHeight / 100) * 9);

        const toDivide = this.screenHeight > 800 ? 10 :
            this.screenHeight > 630 ? 9 :
                this.screenHeight > 530 ? 8 :
                    this.screenHeight > 430 ? 7 :
                        this.screenHeight > 330 ? 6 :
                            this.screenHeight > 230 ? 5 : 4;

    }

    formatOne = (percent: number) => `${percent}%`;

    getContadores() {

        this.loadingContadores = true;

        this.service.retornaCabecalho().subscribe({
            next: res => {

                this.contadores = res;

                this.contadores.porcent_icms_declarado = res.quantidade_icmsDeclarado * res.quantidade_total / 100;
                this.contadores.porcent_icms_pendencia = res.quantidade_icmsPendencia * res.quantidade_total / 100;
                this.contadores.porcent_ipva = res.quantidade_ipva * res.quantidade_total / 100;
                this.contadores.porcent_aimm = res.quantidade_aimm * res.quantidade_total / 100;
                this.contadores.porcent_itcmd = res.quantidade_itcmd * res.quantidade_total / 100;
                this.contadores.porcent_icms_parcelamento = res.quantidade_icmsParcelamento * res.quantidade_total / 100;

                this.loadingContadores = false;

                this.getTabela(this.currentParams, this.currentSearch);

            },
            error: err => {
                this.loadingContadores = false;
                this.toastrService.error(err.error.message || 'Erro inesperado ao tentar obter dados do cabecalho');
            }
        });
    }

    getTabela(params: NzTableQueryParams, search: string = null) {

        this.loadingTabela = true;
        this.items = [];
        this.currentParams = params;
        this.currentSearch = search;

        this.calculaBadgeFiltros();

        this.service.retornaTabela(params, this.currentSearch).subscribe({
            next: res => {

                this.items = res.data;

                this.pagination = new Pagination(
                    res?.per_page || 50,
                    res?.current_page || 1,
                    res?.last_page || 1,
                    res?.total || 50,
                    res?.from || 1,
                    res?.to || 1);

                this.loadingTabela = false;

            },
            error: err => {
                this.loadingTabela = false;
                this.toastrService.error(err.error.message || 'Erro inesperado ao tentar obter dados do cabecalho');
            }
        });
    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params?.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params?.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            params.filter = this.currentParams.filter;
            this.getTabela(params);
            this.pagination.current_page = params?.pageIndex;
        }

    }

    btnResetSearch() {

        this.currentSearch = null;

        this.currentParams = {
            pageIndex: 1,
            pageSize: 50,
            sort: [],
            filter: [],
        };

        this.formFiltros.reset();

        this.getTabela(this.currentParams, this.currentSearch);

    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltros !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltros.value)) {

                if (valor && chave !== 'page') {
                    this.qtdFiltrosAtivos++;
                }
            }
        }
    }

    expandEvent() {
        this.expand = !this.expand;
    }

    modalFiltrar(visible) {
        this.visible.filtrar = visible;
    }

    filtrar(): any {

        const params = this.currentParams;
        params.filter = [];
        params.pageIndex = 1;

        for (const [chave, valor] of Object.entries(this.formFiltros.value)) {
            if (chave === 'dataUltimaEmissao' && valor) {
                params.filter.push({key: chave, value: Helpers.formataDateBD(valor.toString())});
            } else {
                params.filter.push({key: chave, value: valor});
            }
        }

        this.modalFiltrar(false);

        this.getTabela(params, this.currentSearch);
    }

    confirmaExportar(): void {

        if (this.formExportar.formGroup.valid) {

            this.loadingExportarPlanilha = true;
            const tipo = this.formExportar.formGroup.value.tipo;

            const filtros = this.formFiltros.value;
            filtros.pagina = this.pagination.current_page;
            filtros.quantidade = this.pagination.per_page;

            this.service.exportExcel(
                filtros, tipo, this.currentSearch
            ).subscribe((res) => {

                if (this.formExportar.formGroup.value.tipo) {

                    const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                    const name = 'Relatorio pendências fiscais';

                    fileSaver.saveAs(blob, name + '.' + this.formExportar.formGroup.value.tipo);

                    this.formExportar.modalVisible = false;
                    this.modalExportarPlanilha(false);
                }
                this.loadingExportarPlanilha = false;

            }, (res) => {
                this.loadingExportarPlanilha = false;
            });
        } else {
            this.loadingExportarPlanilha = false;
            Object.values(this.formExportar.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });

        }
    }

    exportarPdfs() {

        this.loadingExportarPDF = true;
        const filtros: any = this.formFiltros.value;

        this.currentParams.filter = [];
        Object.keys(filtros).forEach((key) => {
            if (filtros[key]) {
                this.currentParams.filter.push({key: String(key), value: filtros[key]});
            }
        });

        this.service.exportPdfs(this.currentParams, this.currentSearch).subscribe({
            next: (res) => {

                const blob = new Blob([res], {type: 'application/zip; charset=utf-8'});

                fileSaver.saveAs(blob, 'ultimos_relatorios_penencias');

                this.loadingExportarPDF = false;
            }, error: (err) => {
                this.toastrService.error(err.error.message || 'Falha ao fazer download.');
                this.loadingExportarPDF = false;
            }
        });
    }

    modalExportarPlanilha(visible) {
        this.visible.exportPlanilha = visible;
    }

    visualizarRelatorio(item: any) {

        this.loadingVerRelatorio[item.id] = true;

        this.service.retornaUltimoRelatorioPendencias(item.cnpj, item.id).subscribe({
            next: res => {

                if (!res) {
                    this.toastrService.warning('Nenhum arquivo para exibir');
                    return;
                }

                this.conteudoUltimoRelatorio = '';
                this.iframeUrl = '';

                if (encodeURI(res).split(/%..|./).length > 99900 &&
                    navigator.userAgent.indexOf('Chrome') > -1) {
                    this.toastrService.warning('Arquivo muito grande para ser exibido no seu navegador atual.' +
                        ' Será baixado automaticamente.');

                    this.base64Selecionado = res;

                    this.onClickDownloadPdf();

                    this.loadingVerRelatorio[item.id] = false;

                    return;

                }

                this.base64Selecionado = res.arquivo;
                this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + res.arquivo);

                this.showModalRalatorio(true);

            },
            error: err => {
                this.loadingVerRelatorio[item.id] = false;
                this.toastrService.error(err.error.message || 'Erro inesperado ao tentar obter relatŕio');
            }
        });
    }

    showModalRalatorio(visible: boolean) {
        this.modalRelatorioVisible = visible;
    }

    onClickDownloadPdf() {
        this.downloadPdf(this.base64Selecionado);
    }

    downloadPdf(base64String = null, fileName = null) {

        if (base64String) {

            const source = `data:application/pdf;base64,${base64String}`;
            const link = document.createElement('a');
            link.href = source;
            link.download = fileName ? `${fileName}.pdf` : 'download.pdf';
            link.click();

        } else {

            this.toastrService.error('Arquivo não encontrado.');

        }

    }

    showConfirm(data: any): void {
        this.modalService.confirm({
            nzTitle: 'Deseja reprocessar o registro?',
            nzOkText: 'Reprocessar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.reprocessarRegistro(data.empresa_id)
        });
    }

    reprocessarRegistro(empresaId: string) {

        this.processamentoFlag[empresaId] = true;

        this.service.reprocessarRegistro(empresaId).subscribe({
            next: (retorno: any) => {

                this.toastrService.success(retorno.message);

                this.processamentoFlag[empresaId] = false;

                this.getTabela(this.currentParams, this.currentSearch);

            }, error: (e) => {
                this.toastrService.error(e.error.message);

                this.processamentoFlag[empresaId] = false;

            }
        });

    }

    showModalHistorico(visible: boolean) {
        this.visible.modalHistorico = visible;
    }

    listHistoricoByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params?.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if (buscar) {
            this.getHistorico(this.cnpjHistorico, params);
        }

    }

    getHistorico(cnpj, params?: NzTableQueryParams) {
        this.itemsHistorico = [];
        this.loadingHistorico[cnpj] = true;

        this.cnpjHistorico = cnpj;

        this.service.getHistorico(cnpj, params || this.currentParams).subscribe({
            next: (response: any) => {

                this.itemsHistorico = response;

                this.loadingHistorico[cnpj] = false;

                this.showModalHistorico(true);

            }, error: (err) => {

                this.loadingHistorico[cnpj] = false;
                this.toastrService.error(err.error.message);
            }
        });
    }
}
