import {ChangeDetectorRef, Component} from '@angular/core';
import {AppComponent} from '../../../app.component';

@Component({
    selector: 'app-conta-nao-encontrada',
    templateUrl: './conta-nao-encontrada.component.html',
    styleUrls: ['./conta-nao-encontrada.component.scss']
})
export class ContaNaoEncontradaComponent {

    backgroundImageUrl;
    logoUrl;

    constructor(
        private cdr: ChangeDetectorRef,
    ) {
    }


    ngOnInit() {
        if (AppComponent.logoUrl && AppComponent.backgroundImageUrl) {
            this.logoUrl = AppComponent.logoUrl;
            this.backgroundImageUrl = AppComponent.backgroundImageUrl;
            this.cdr.detectChanges();
            return;
        }
    }

}
