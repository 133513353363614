import {Component, DoCheck} from '@angular/core';
import {AuthenticationService} from './core/auth/authentication.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {select, Store} from '@ngrx/store';
import {NavigationStart, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {UrlHelperService} from '@services/url-helper.service';
import {ptBR} from 'date-fns/locale';
import {NzI18nService} from 'ng-zorro-antd/i18n';
import {UserMenuComponent} from '@components/header/user-menu/user-menu.component';
import {UserService} from '@services/user.service';
import {Tab} from '@models/tab.model';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    styles: [`
        :host {
            display: flex;
            height: 100%;
            width: 100%;
        }
    `
    ]
})
export class AppComponent implements DoCheck {

    constructor(private authService: AuthenticationService, private titleService: Title,
                private store: Store,
                private router: Router,
                private urlHelperService: UrlHelperService,
                private i18n: NzI18nService,
                private userService: UserService,
    ) {

        this.i18n.setDateLocale(ptBR);

        this.setHistory();

        this.retornaTema((response) => {
                AppComponent.setBrand(response, callback => {
                    this.setTitle(AppComponent.appName);
                });
            }
        );

    }

    tabEmbedded: Tab = JSON.parse(localStorage.getItem('tabEmbedded'));

    static miniLogoUrl;
    static backgroundImageUrl;
    static logoUrl;
    static backgroundColor;
    static highlightColor;
    static appName = environment.applicationName;
    static theme = 'mastertax';
    static showGuide = false;

    get staticGuide() {
        return AppComponent.showGuide;
    }

    get getUserName() {
        return UserMenuComponent.userName;
    }

    isLogged = false;

    static setBrand(response, callback) {

        const partesAppUrl = window.location.href.split('/');

        if (partesAppUrl.length > 3 && (
            partesAppUrl[2].search('mastertax.app') >= 0 ||
            partesAppUrl[2].search('mastertax.local') >= 0)
        ) {

            const identificador = partesAppUrl[2].split('.')[0];

            if (identificador) {
                this.theme = identificador;
                localStorage.setItem('identificador', this.theme.toString());
            }

            this.appName = response[identificador] && response[identificador].appName ?
                response[identificador].appName :
                response.mastertax.appName;

            const url = '/assets/brands/' + (response[identificador] ? this.theme : 'mastertax') + '/';

            document.documentElement.style.setProperty('--backgroundPrimary',
                (response[identificador] && response[identificador].backgroundPrimary ?
                    response[identificador].backgroundPrimary :
                    response.mastertax.backgroundPrimary));

            document.documentElement.style.setProperty('--backgroundSecondary',
                (response[identificador] && response[identificador].backgroundSecondary ?
                    response[identificador].backgroundSecondary :
                    response.mastertax.backgroundSecondary));

            document.documentElement.style.setProperty('--highlightColor',
                (response[identificador] && response[identificador].highlightColor ?
                    response[identificador].highlightColor :
                    response.mastertax.highlightColor));

            this.logoUrl = url + 'login.png';
            this.backgroundImageUrl = url + 'pattern.png';
            this.miniLogoUrl = url + 'logo-icone.png';

            this.backgroundColor = document.documentElement.style.getPropertyValue('--backgroundPrimary');
            this.highlightColor = document.documentElement.style.getPropertyValue('--highlightColor');

            callback(true);

        } else {

            this.logoUrl = '/assets/logo.png';
            this.backgroundImageUrl = '/assets/pattern.png';
            this.miniLogoUrl = '/assets/logo-icone.png';

            this.backgroundColor = '#5e5047';
            this.highlightColor = '#ffa646';

        }

    }

    static createCSSSelector(selector, style) {

        if (!document.styleSheets) {
            return;
        }

        if (document.getElementsByTagName('head').length === 0) {
            return;
        }

        let styleSheet;
        let mediaType;

        if (document.styleSheets.length > 0) {
            for (let i = 0, l = document.styleSheets.length; i < l; i++) {
                if (document.styleSheets[i].disabled) {
                    continue;
                }
                const media = document.styleSheets[i].media;
                mediaType = typeof media;

                if (mediaType === 'string') {
                    // @ts-ignore
                    if (media === '' || (media.indexOf('screen') !== -1)) {
                        styleSheet = document.styleSheets[i];
                    }
                } else if (mediaType === 'object') {
                    if (media.mediaText === '' || (media.mediaText.indexOf('screen') !== -1)) {
                        styleSheet = document.styleSheets[i];
                    }
                }

                if (typeof styleSheet !== 'undefined') {
                    break;
                }
            }
        }

        if (typeof styleSheet === 'undefined') {
            const styleSheetElement = document.createElement('style');
            styleSheetElement.type = 'text/css';
            document.getElementsByTagName('head')[0].appendChild(styleSheetElement);

            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < document.styleSheets.length; i++) {
                if (document.styleSheets[i].disabled) {
                    continue;
                }
                styleSheet = document.styleSheets[i];
            }

            mediaType = typeof styleSheet.media;
        }

        if (mediaType === 'string') {
            for (let i = 0, l = styleSheet.rules.length; i < l; i++) {
                if (styleSheet.rules[i].selectorText && styleSheet.rules[i].selectorText.toLowerCase() === selector.toLowerCase()) {
                    styleSheet.rules[i].style.cssText = style;
                    return;
                }
            }
            styleSheet.addRule(selector, style);
        } else if (mediaType === 'object') {
            const styleSheetLength = (styleSheet.cssRules) ? styleSheet.cssRules.length : 0;
            for (let i = 0; i < styleSheetLength; i++) {
                if (styleSheet.cssRules[i].selectorText && styleSheet.cssRules[i].selectorText.toLowerCase() === selector.toLowerCase()) {
                    styleSheet.cssRules[i].style.cssText = style;
                    return;
                }
            }
            styleSheet.insertRule(selector + '{' + style + '}', styleSheetLength);
        }
    }

    static showGuideOnApp() {
        AppComponent.showGuide = true;
    }

    static hideGuideOnApp() {
        AppComponent.showGuide = false;
    }

    retornaTema(callback) {
        callback({
            contasped: {
                identificador: 'contasped',
                appName: 'ContaSped',
                backgroundPrimary: '#2c2c2c',
                backgroundSecondary: '#4c4c4c',
                highlightColor: '#ffa646'
            },
            haize: {
                identificador: 'haize',
                appName: 'Haize Tax',
                backgroundPrimary: '#2c2c2c',
                backgroundSecondary: '#4c4c4c',
                highlightColor: '#ffa646'
            },
            mastertax: {
                identificador: 'mastertax',
                appName: 'MasterTax',
                backgroundPrimary: '#5e5047',
                backgroundSecondary: '#ffa646',
                highlightColor: '#ffa646'
            },
            desenvolvimento: {
                identificador: 'desenvolvimento',
                appName: 'MasterTax',
                backgroundPrimary: '#5e5047',
                backgroundSecondary: '#ffa646',
                highlightColor: '#ffa646'
            },
            b7solutions: {
                identificador: 'b7solutions',
                appName: 'MasterTax',
                backgroundPrimary: '#265078',
                backgroundSecondary: '#265078',
                highlightColor: '#c1b41a'
            }
        });
    }

    setHistory() {
        setTimeout(() => {
            const urlAtual = window.location.href;
            if (!urlAtual.includes('.local') && !urlAtual.includes('.dev') && !urlAtual.includes('.test')) {

                this.userService.setHistory(urlAtual).subscribe(
                    (res) => {
                    },
                    (error) => console.log('Erro ao gravar log de acesso ' + error)
                );
            }
        }, 600);

        this.router.events.pipe(first()).subscribe((navigation: NavigationStart) => {
            const index = this.urlHelperService.checkUrl(navigation.url, this.isLogged);
            this.isLogged = this.authService.isLogged();
            // if (this.isLogged) {
            //     const index = this.urlHelperService.checkUrl(navigation.url);
            // }
        });
    }

    // @ts-ignore
    getTabs = () => this.store.pipe(select('tabs'));

    ngDoCheck() {
        this.isLogged = this.authService.isLogged();

        if (this.isLogged === null && this.router.url.includes('/home')) {
            localStorage.removeItem('app_mastertax');

            setTimeout(() => {
                this.router.navigate(['/login']);
            }, 300);
        }
    }

    ngOnInit() {
    }

    public setTitle(newTitle: string) {

        if (localStorage.getItem('identificador')) {
            this.authService.getContaNomeByIdentificador(localStorage.getItem('identificador')).subscribe(res => {
                if (res?.message) {
                    localStorage.setItem('conta_nome', res?.message);
                    const nome = res?.message;
                    this.titleService.setTitle(nome + (newTitle ? ' | ' + newTitle : ''));
                } else {
                    this.router.navigate(['/conta-nao-encontrada']);
                }
            }, (res) => {
                this.titleService.setTitle(newTitle);
            });
        } else {
            this.titleService.setTitle(newTitle);
        }

    }

}
