import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const currentToken = this.authenticationService.currentTokenValue;

        if (currentToken && currentToken.access_token) {

            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentToken.access_token}`
                }
            });

        } else {

            if (!window.location.pathname.includes('change-password')
                && !window.location.pathname.includes('aprovacoes')
                && !window.location.pathname.includes('compliance/bi-dashboard-pdf')
                && !window.location.pathname.includes('login')
                && !window.location.pathname.includes('obrigacao-empresa-detalhe')
                && !window.location.pathname.includes('conta-nao-encontrada')) {
                this.router.navigate(['/login']);
            }

        }

        return next.handle(request);
    }
}
