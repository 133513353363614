import {Component, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import * as fileSaver from 'file-saver-es';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import {Empresa} from '@models/empresa.model';
import {EmpresaService} from '@services/empresa.service';
import Visibilidade from '@models/visibilidade.model';
import {VisibilidadeService} from '@services/visibilidade.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {GrupoEmpresarialService} from '@services/grupoEmpresarial.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {NzModalService} from 'ng-zorro-antd/modal';
import {UserService} from '@services/user.service';
import {Helpers} from '../../../core/helpers';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';
import {ConfiguracoesContaService} from "./configuracoes-conta.service";

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-configuracoes-conta',
    templateUrl: './configuracoes-conta.component.html',
    styleUrls: ['./configuracoes-conta.component.scss']
})

export class ConfiguracoesContaComponent extends AbstractListTable<Empresa> implements OnInit {
    tabAtiva = 0;

    constructor(
        private fb: UntypedFormBuilder,
        private service: ConfiguracoesContaService,
        private toastService: ToastrService) {

        super(service, {}, toastService);


    }

    ngOnInit() {

    }


    changeTabs(event: any) {
        console.log(event)
    }
}
