import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class DecCndService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/calendar/tasks', {});
    }

    retornaCabecalho(filtros: any): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoes/cabecalho${queryStr}`);

    }

    retornaSituacaoFiscalRFB(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/consolidado/situacao-fiscal/ecac/relatorio/cabecalho`);
    }

    retornaDomicilios(filtros: any): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.post(`${environment.apiUrl}/domicilio/domicilioEletronico${queryStr}`, filtros);

    }

    retornaDomiciliosTotal(filtros: any): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.post(`${environment.apiUrl}/domicilio/domicilioEletronico/cabecalho${queryStr}`, filtros);

    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/combo/empresas`);
    }

    retornarSelectsCertidao(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/combo/certidoes`);
    }

    retornarOptionEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresasAtivas/select`);
    }

    retornaEstados(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);

    }

    retornaComboMunicipios(uf: string = null): Observable<any> {

        if (uf) {
            return this.http.get(`${environment.apiUrl}/filtros/cidades/select/${uf}`);
        } else {
            return this.http.get(`${environment.apiUrl}/filtros/cidades/select`);
        }

    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            if (valor) {
                retorno = valor.toString();
            } else {
                retorno = valor;
            }

        }

        return retorno;
    }

}
