<nz-page-header class="site-page-header" nzTitle="Compliance" nzSubtitle="Configurações"
                xmlns="http://www.w3.org/1999/html">
</nz-page-header>

<nz-content>
    <nz-card nzTitle="Configurações" [nzLoading]="loadingTable"
             [nzBodyStyle]="{'padding': '25px'}">

        <nz-list>
            <nz-list-item>
                <nz-list-item-meta nzDescription="Responsáveis">
                    <nz-list-item-meta-title>
                        {{ responsaveisDefinidos + '/' + qtdEmpresas }}
                    </nz-list-item-meta-title>
                </nz-list-item-meta>
                <ul nz-list-item-actions>
                    <nz-list-item-action>
                        <button (click)="modalResponsaveis()" nz-button nzType="link">Editar
                        </button>
                    </nz-list-item-action>
                </ul>
            </nz-list-item>
            <nz-list-item>
                <nz-list-item-meta nzDescription="Competência Inicial das Análises Automáticas">
                    <nz-list-item-meta-title>
                        {{ inicioAnalise | date : 'MM/yyyy' }}
                    </nz-list-item-meta-title>
                </nz-list-item-meta>
                <ul nz-list-item-actions>
                    <nz-list-item-action>
                        <button (click)="modalCompetenciaAnalises()" nz-button nzType="link">Editar
                        </button>
                    </nz-list-item-action>
                </ul>
            </nz-list-item>
            <nz-list-item>
                <nz-list-item-meta nzDescription="Marcador para BI">
                    <nz-list-item-meta-title>
                        {{ marcadorBi }}
                    </nz-list-item-meta-title>
                </nz-list-item-meta>
            </nz-list-item>
            <nz-list-item>
                <nz-list-item-meta nzDescription="Início das Análises Automáticas">
                    <nz-list-item-meta-title>
                        {{ diaAnalise || '-' }}
                    </nz-list-item-meta-title>
                </nz-list-item-meta>
                <ul nz-list-item-actions>
                    <nz-list-item-action>
                        <button (click)="modalDiaAnalises()" nz-button nzType="link">Editar
                        </button>
                    </nz-list-item-action>
                </ul>
            </nz-list-item>
        </nz-list>


    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="showModalCompetenciaAnalises" [nzTitle]="'Competência Inicial das Análises Automática'"
          (nzOnCancel)="modalCompetenciaAnalises()">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">

            <form nz-form [nzNoColon]="true" [formGroup]="formEditarCompetenciaAnalise">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzRequired>Data:</nz-form-label>
                    <nz-form-control [nzSpan]="16">

                        <nz-date-picker (keyup)="Helpers.maskNZDatePicker($event)" style="width: 100%;"
                                        formControlName="dataInicioAnalise" nzMode="month"
                                        nzFormat="MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCompetenciaAnalises()">Cancelar</button>
        <button [nzLoading]="loadings.editando" nz-button nzType="primary"
                (click)="editarInicioAnalises()">Editar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="showModalResponsaveis" [nzTitle]="'Alterar Responsável'"
          (nzOnCancel)="modalResponsaveis()" [nzWidth]="850" [nzBodyStyle]="{'padding': '0'}">
    <nz-row *nzModalContent>

        <nz-card [nzExtra]="navBar"
                 [nzBodyStyle]="{'padding': '0 15px', 'width': '845px'}">

            <ng-template #navBar>
                <div class="nav-item d-flex">
                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                        <input (keydown.enter)="buscar()" type="text"
                               nz-input
                               placeholder="Busca" [(ngModel)]="currentSearch">
                    </nz-input-group>
                    <ng-template #suffixIconButton>
                        <button nz-button nzType="primary" nzSearch
                                (click)="buscar();">
                            <i nz-icon nzType="search"></i></button>
                    </ng-template>
                    <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                            [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
                        Opções
                        <em nz-icon nzType="down"></em>
                    </button>
                    <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-submenu nzTitle="Manutenção Coletiva">
                                <ul>
                                    <li nz-menu-item nzDisabled>Ações</li>
                                    <li nz-menu-divider></li>
                                    <li (click)="checkedItems.size > 0 ? modalResponsavelMassa() : ''" nz-menu-item
                                        [nzDisabled]="checkedItems.size === 0">
                                        Alterar responsável em massa
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </div>
            </ng-template>

            <nz-table #basicTable
                      nzSize="small"
                      [nzLoading]="loadings.tabelaResponsaveis"
                      [nzData]="responsavelPorEmpresa"
                      [nzFrontPagination]="false"
                      [nzTotal]="pagination?.total"
                      [nzPageSize]="pagination?.per_page"
                      [nzPageIndex]="pagination?.current_page"
                      [nzShowTotal]="rangeTemplateTwo"
                      (nzQueryParams)="listByTable($event)"
                      class="table-small"
            >
                <thead>
                <tr>
                    <th style="width: 50px;" (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked"
                        id="check-all">
                    </th>
                    <th>Empresa</th>
                    <th>Responsável</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of basicTable.data">
                    <td nzLeft (nzCheckedChange)="onItemChecked(item.empresa.id, $event)"
                        [nzChecked]="checkedItems.has(item.empresa.id)">
                    </td>
                    <td style="width: 450px">
                        {{ item.empresa.nome }}
                        <br>
                        <small>({{ item.empresa.cnpj | cpfCnpj }})</small>
                    </td>
                    <td>
                        <input style="width: 400px"
                               nz-input [(ngModel)]="item.responsavel.email"
                               type="email" size="60"
                               maxlength="60">
                    </td>
                </tr>
                </tbody>
            </nz-table>
            <ng-template #rangeTemplateTwo let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-card>

    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalResponsaveis()">Cancelar</button>
        <button [nzLoading]="loadings.editando" nz-button nzType="primary"
                (click)="editarResponsaveis()">Editar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="showModalResponsalMassa" [nzTitle]="'Alterar Responsável em massa'"
          (nzOnCancel)="modalResponsavelMassa()" [nzWidth]="520" [nzBodyStyle]="{'padding': '30px 0'}">
    <nz-row *nzModalContent style="display: flex; justify-content: center; align-items: center">
        <label style="padding-right: 10px">Responsável:</label>
        <input style="width: 400px" placeholder="Informe o email do responsável"
               nz-input [(ngModel)]="responsavelEmailMassa"
               type="email" size="60"
               maxlength="60">
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalResponsavelMassa()">Cancelar</button>
        <button [nzLoading]="loadings.editando" nz-button nzType="primary"
                (click)="confirmAtualizarEmMassa()">Editar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="showModalDiaAnalises" [nzTitle]="'Início das Análises Automática'"
          (nzOnCancel)="modalDiaAnalises()" [nzWidth]="350">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">

            <form nz-form [nzNoColon]="true" [formGroup]="formEditarDiaAnalise">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzRequired>Dia:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-select nzShowSearch nzAllowClear style="width: 100%;"
                                   [nzPlaceHolder]="'Selecione'" formControlName="diaAnalise">
                            <nz-option *ngFor="let d of dias" [nzLabel]="d" [nzValue]="d"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalDiaAnalises()">Cancelar</button>
        <button [nzLoading]="loadings.editando" nz-button nzType="primary"
                (click)="editarDiaAnalises()">Editar
        </button>
    </div>
</nz-modal>

