import {UrlTypeEnum} from '../enums/url-type.enum';
import {NfeTerceirosComponent} from '../../modules/notas/nfe-terceiros/nfe-terceiros.component';
import {
    ControleDeCertificadosComponent
} from '../../modules/dashboards/controle-de-certificados/controle-de-certificados.component';
import {
    RegrasAnaliseXmlSaidaComponent
} from '../../modules/cadastros/regras-analise-xml-saida/regras-analise-xml-saida.component';
import {AnalisesXMLSaidasComponent} from '../../modules/notas/AnalisesXMLSaidas/AnalisesXMLSaidas.component';
import {
    AnalisesXMLSaidasDetalhesComponent
} from '../../modules/notas/AnalisesXMLSaidasDetalhesComponent/AnalisesXMLSaidasDetalhes.component';
import {
    ConfiguracoesContaComponent
} from '../../modules/administracao/configuracoes-conta/configuracoes-conta.component';

export const componentsConfig = {
    LogsAlteracoesComponent: {
        url: '/administracao/logs-alteracoes',
        urlType: UrlTypeEnum.nativo,
        title: 'Logs',
        name: 'LogsAlteracoesComponent',
    },
    ConsolidadoEcacComponent: {
        url: '/situacaoFiscal/ecac',
        urlType: UrlTypeEnum.embedded,
        title: 'Situacao Fiscal ECAC',
        name: 'ConsolidadoEcacComponent',
    },
    TransmissoesDetalhesComponent: {
        url: '/delivery/transmissoesDetalhes',
        urlType: UrlTypeEnum.embedded,
        title: 'Delivery Transmissões',
        name: 'TransmissoesDetalhesComponent',
    },
    TransmissoesComponent: {
        url: '/delivery/transmissoes',
        urlType: UrlTypeEnum.embedded,
        title: 'Delivery Transmissões',
        name: 'TransmissoesComponent',
    },
    ArmazenamentosDetalhesComponent: {
        url: '/check/armazenamentosDetalhes',
        urlType: UrlTypeEnum.embedded,
        title: 'Check Armazenamentos',
        name: 'ArmazenamentosDetalhesComponent',
    },
    ArmazenamentosComponent: {
        url: '/check/armazenamentos',
        urlType: UrlTypeEnum.embedded,
        title: 'Check Armazenamentos',
        name: 'ArmazenamentosComponent',
    },
    ConciliacoesDetalhesComponent: {
        url: '/check/conciliacaoDetalhes',
        urlType: UrlTypeEnum.embedded,
        title: 'Check Conciliação Detalhes',
        name: 'ConciliacoesDetalhesComponent',
    },
    ConciliacoesComponent: {
        url: '/check/conciliacao',
        urlType: UrlTypeEnum.embedded,
        title: 'Check Conciliação',
        name: 'ConciliacoesComponent',
    },
    DownloadCenterComponent: {
        url: '/downloadCenter',
        urlType: UrlTypeEnum.embedded,
        title: 'Central de Downloads',
        name: 'DownloadCenterComponent',
    },
    CadastrosCategoriasComponent: {
        url: '/cadastros/categorias',
        urlType: UrlTypeEnum.embedded,
        title: 'Categorias',
        name: 'CadastrosCategoriasComponent',
    },
    FlowAprovacoesComponent: {
        url: '/flow/flowAprovacoes',
        urlType: UrlTypeEnum.embedded,
        title: 'Flow Aprovações',
        name: 'FlowAprovacoesComponent',
    },
    CadastrosEditarGatilhosComponent: {
        url: '/cadastros/gatilhos',
        urlType: UrlTypeEnum.embedded,
        title: 'Gatilhos',
        name: 'CadastrosEditarGatilhosComponent',
    },
    // Tela inutilizada. URL levando para a entigaa editar-gatilhos
    // CadastrosGatilhosComponent: {
    //     url: '/cadastros/gatilhos',
    //     urlType: UrlTypeEnum.embedded,
    //     title: 'Gatilhos',
    //     name: 'CadastrosGatilhosComponent',
    // },
    CadastrosStepsObrigacoesEmpresasComponent: {
        url: '/cadastros/steps-obrigacoes-empresas',
        urlType: UrlTypeEnum.embedded,
        title: 'Obrigações Empresas',
        name: 'CadastrosStepsObrigacoesEmpresasComponent',
    },
    SefazErpDetalhesComponent: {
        url: '/notas/sefazErpDetalhes',
        urlType: UrlTypeEnum.embedded,
        title: 'Notas Sefaz x ERP Detalhes',
        name: 'SefazErpDetalhesComponent',
    },
    SefazErpComponent: {
        url: '/notas/sefazErp',
        urlType: UrlTypeEnum.embedded,
        title: 'Notas Sefaz x ERP',
        name: 'SefazErpComponent',
    },
    NotasEmitidasComponent: {
        url: '/notas/notasEmitidas',
        urlType: UrlTypeEnum.nativo,
        title: 'Notas Emitidas',
        name: 'NotasEmitidasComponent',
    },
    NotasEmitidasEmpresaComponent: {
        url: '/notas/notasEmitidasEmpresa',
        urlType: UrlTypeEnum.nativo,
        title: 'Notas Emitidas Empresa',
        name: 'NotasEmitidasEmpresaComponent',
    },
    CadastrosDomicilioEletronicoComponent: {
        url: '/cadastros/domicilioEletronico',
        urlType: UrlTypeEnum.embedded,
        title: 'Cadastro de Domícilio Eletronico',
        name: 'CadastrosDomicilioEletronicoComponent',
    },
    CadastrosCertidoesComponent: {
        url: '/cadastros/certidoes',
        urlType: UrlTypeEnum.embedded,
        title: 'Cadastro de Certidões',
        name: 'CadastrosCertidoesComponent',
    },
    DomicilioEletronicoComponent: {
        url: '/domicilio/domicilioEletronico',
        urlType: UrlTypeEnum.nativo,
        title: 'Domicílio Eletrônico',
        name: 'DomicilioEletronicoComponent',
    },
    DecCndComponent: {
        url: '/dec-cnd/dashboard',
        urlType: UrlTypeEnum.nativo,
        title: 'Dashboard',
        name: 'DecCndComponent',
    },
    SimplesNacional: {
        url: '/terceiros/simplesNacional',
        urlType: UrlTypeEnum.nativo,
        title: 'Simples Nacional',
        name: 'SimplesNacionalComponent',
    },
    ControleCertidoesComponent: {
        url: '/certidoes/controleCertidoes',
        urlType: UrlTypeEnum.embedded,
        title: 'Controle de Certidões',
        name: 'ControleCertidoesComponent',
    },
    ReceitaFederalComponent: {
        url: '/receita/receitaFederal',
        urlType: UrlTypeEnum.embedded,
        title: 'Receita Federal',
        name: 'ReceitaFederalComponent',
    },
    ConnectDownloadComponent: {
        url: '/connect/connectDownload',
        urlType: UrlTypeEnum.embedded,
        title: 'Connect Download',
        name: 'ConnectDownloadComponent',
    },
    NotifyTestComponent: {
        url: '/notificacao/notifyTest',
        urlType: UrlTypeEnum.embedded,
        title: 'Notification Dispatcher',
        name: 'NotifyTestComponent',
    },
    VisibilidadeDetalheComponent: {
        url: '/administracao/visibilidades/visibilidade-detalhe/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Visibilidade Detalhe',
        name: 'VisibilidadeDetalheComponent',
    },
    VisibilidadesComponent: {
        url: '/administracao/visibilidades',
        urlType: UrlTypeEnum.embedded,
        title: 'Visibilidades',
        name: 'VisibilidadesComponent',
    },
    GruposEmpresariaisComponent: {
        url: '/administracao/grupos-empresariais',
        urlType: UrlTypeEnum.embedded,
        title: 'Grupos Empresariais',
        name: 'GruposEmpresariaisComponent',
    },
    GuiasLotesComponent: {
        url: '/integracoes/guiasLotes',
        urlType: UrlTypeEnum.embedded,
        title: 'Guias Lotes',
        name: 'GuiasLotesComponent',
    },
    ErpComponent: {
        url: '/integracoes/erp',
        urlType: UrlTypeEnum.embedded,
        title: 'Erp',
        name: 'ErpComponent',
    },
    GuiasArquivosComponent: {
        url: '/integracoes/guiasArquivos',
        urlType: UrlTypeEnum.embedded,
        title: 'Guias Arquivos',
        name: 'GuiasArquivosComponent',
    },
    FlowObrigacoesComponent: {
        url: '/flow/flowObrigacoes',
        urlType: UrlTypeEnum.embedded,
        title: 'Flow Obrigações',
        name: 'FlowObrigacoesComponent',
    },
    CheckEfdContribuicoesTributacaoDetalhesComponent: {
        url: '/check/checkEfdContribuicoesTributacaoDetalhes',
        urlType: UrlTypeEnum.embedded,
        title: 'EFD Contribuições Tributação Detalhes',
        name: 'CheckEfdContribuicoesTributacaoDetalhesComponent',
    },
    CheckEfdContribuicoesTributacaoComponent: {
        url: '/check/checkEfdContribuicoesTributacao',
        urlType: UrlTypeEnum.embedded,
        title: 'EFD Contribuições Tributação',
        name: 'CheckEfdContribuicoesTributacaoComponent',
    },
    ObrigacoesEstaduaisComponent: {
        url: '/drive/obrigacoesEstaduais',
        urlType: UrlTypeEnum.embedded,
        title: 'Obrigações Estaduais',
        name: 'ObrigacoesEstaduaisComponent',
    },
    ObrigacoesPrincipaisComponent: {
        url: '/drive/obrigacoesPrincipais',
        urlType: UrlTypeEnum.nativo,
        title: 'Obrigações Principais',
        name: 'ObrigacoesPrincipaisComponent',
    },
    TransitoriosUploadArquivosComponent: {
        url: '/drive/transitorios/uploadArquivos',
        urlType: UrlTypeEnum.embedded,
        title: 'Upload de Arquivos',
        name: 'TransitoriosUploadArquivosComponent',
    },
    CheckNotasDetalhesComponent: {
        url: '/compliance/spedNotasDetalhes/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'SPED Notas Detalhes',
        name: 'CheckNotasDetalhesComponent',
    },
    CheckNotasComponent: {
        url: '/compliance/sped-notas',
        urlType: UrlTypeEnum.embedded,
        title: 'SPED x Notas',
        name: 'CheckNotasComponent',
    },
    CalendarComponent: {
        url: '/calendar/calendarObrigacoes',
        urlType: UrlTypeEnum.embedded,
        title: 'Calendar Obrigações',
        name: 'CalendarObrigacoesComponent',
    },
    CalendarTasksComponent: {
        url: '/calendar/calendarTasks',
        urlType: UrlTypeEnum.embedded,
        title: 'Calendar Atividades',
        name: 'CalendarTasksComponent',
    },
    MonitorProcessamentosComponent: {
        url: '/monitoramento/processamentos',
        urlType: UrlTypeEnum.embedded,
        title: 'Monitoramento Processamentos',
        name: 'MonitorProcessamentosComponent',
    },
    ComplianceCalendarComponent: {
        url: '/compliance/complianceCalendar',
        urlType: UrlTypeEnum.embedded,
        title: 'Compliance Calendar',
        name: 'ComplianceCalendarComponent',
    },
    ComplianceTributosEmpresasComponent: {
        url: '/compliance/complianceTributosEmpresas',
        urlType: UrlTypeEnum.nativo,
        title: 'Compliance Tributos Empresas',
        name: 'ComplianceTributosEmpresasComponent',
    },
    ComplianceTributosComponent: {
        url: '/compliance/complianceTributos',
        urlType: UrlTypeEnum.nativo,
        title: 'Compliance Tributos',
        name: 'ComplianceTributosComponent',
    },
    ObrigacoesFederaisComponent: {
        url: '/drive/obrigacoesFederais',
        urlType: UrlTypeEnum.nativo,
        title: 'Obrigações Federais',
        name: 'ObrigacoesFederaisComponent',
    },
    ObrigacoesEditarRegrasComponent: {
        url: '/check/editar-obrigacoes-regras',
        urlType: UrlTypeEnum.nativo,
        title: 'Editar Regras',
        name: 'ObrigacoesEditarRegrasComponent',
    },
    ObrigacoesRegrasComponent: {
        url: '/check/obrigacoes-regras',
        urlType: UrlTypeEnum.nativo,
        title: 'Regras',
        name: 'ObrigacoesRegrasComponent',
    },
    CadastrosObrigacoesPrevidenciarioComponent: {
        url: '/cadastros/obrigacoes-previdenciario',
        urlType: UrlTypeEnum.nativo,
        title: 'Obrigações Previdenciário',
        name: 'CadastrosObrigacoesPrevidenciarioComponent',
    },
    CadastrosObrigacoesEstaduaisComponent: {
        url: '/cadastros/obrigacoes-estaduais',
        urlType: UrlTypeEnum.nativo,
        title: 'Obrigações Estaduais',
        name: 'CadastrosObrigacoesEstaduaisComponent',
    },
    CadastrosObrigacoesFederaisComponent: {
        url: '/cadastros/obrigacoes-federais',
        urlType: UrlTypeEnum.nativo,
        title: 'Obrigações Federais',
        name: 'CadastrosObrigacoesFederaisComponent',
    },
    CadastrosObrigacoesEmpresasComponent: {
        url: '/cadastros/obrigacoes-empresas',
        urlType: UrlTypeEnum.nativo,
        title: 'Obrigações Empresas',
        name: 'CadastrosObrigacoesEmpresasComponent',
    },
    ObrigacaoEmpresaDetalheComponent: {
        url: '/cadastros/obrigacao-empresa-detalhe/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Editar Obrigação Empresa',
        name: 'ObrigacaoEmpresaDetalheComponent',
    },
    CadastrosEditarCredenciaisAcessoComponent: {
        url: '/cadastros/editar-credenciais-acesso/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Editar Credenciais de Acesso',
        name: 'CadastrosEditarCredenciaisAcessoComponent',
    },
    CadastrosEditarResponsaveisComponent: {
        url: '/cadastros/editar-responsavel/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Editar Responsável',
        name: 'CadastrosEditarResponsaveisComponent',
    },
    CadastrosCredenciaisAcessoComponent: {
        url: '/cadastros/credenciais-acesso',
        urlType: UrlTypeEnum.nativo,
        title: 'Credenciais de Acesso',
        name: 'CadastrosCredenciaisAcessoComponent',
    },

    CadastroResponsaveisComponent: {
        url: '/cadastros/responsaveis',
        urlType: UrlTypeEnum.nativo,
        title: 'Responsáveis',
        name: 'CadastroResponsaveisComponent',
    },
    CadastrosEditarInscricoesRegimesComponent: {
        url: '/cadastros/editar-inscricoes-regimes/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Editar Inscrições e Regimes',
        name: 'CadastrosEditarInscricoesRegimesComponent',
    },
    CadastrosInscricoesRegimesComponent: {
        url: '/cadastros/inscricoes-regimes',
        urlType: UrlTypeEnum.nativo,
        title: 'Inscrições e Regimes',
        name: 'CadastrosInscricoesRegimesComponent',
    },
    ObrigacaoDetalheComponent: {
        url: '/cadastros/obrigacoes/obrigacao-detalhe/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Editar Obrigação',
        name: 'ObrigacaoDetalheComponent',
    },
    CadastrosObrigacoesComponent: {
        url: '/cadastros/obrigacoes',
        urlType: UrlTypeEnum.nativo,
        title: 'Cadastro de Obrigações',
        name: 'CadastrosObrigacoesComponent',
    },
    EquipesComponent: {
        url: '/cadastros/equipes',
        urlType: UrlTypeEnum.nativo,
        title: 'Cadastros de Equipes',
        name: 'EquipesComponent',
    },
    ImportarRegrasPisCofinsComponent: {
        url: '/cadastros/regras-pis-cofins/importar-movimento',
        urlType: UrlTypeEnum.nativo,
        title: 'Importar via Movimentação',
        name: 'ImportarRegrasPisCofinsComponent',
    },
    RegrasPisCofinsComponent: {
        url: '/cadastros/regras-pis-cofins',
        urlType: UrlTypeEnum.nativo,
        title: 'Regras PIS / Cofins',
        name: 'RegrasPisCofinsComponent',
    },
    EmpresaCpnfiguracoesComponent: {
        url: '/configuracoes/empresa/:id',
        urlType: UrlTypeEnum.embedded,
        title: 'Configurações da Empresa',
        name: 'ExternalPageComponent',
    },
    ConfiguracoesGestorComponent: {
        url: '/configuracoes/gestor/:id',
        urlType: UrlTypeEnum.embedded,
        title: 'Configurações Gestor',
        name: 'ExternalPageComponent',
    },
    ConfiguracoesAuditorComponent: {
        url: '/configuracoes/auditor/:id',
        urlType: UrlTypeEnum.embedded,
        title: 'Configurações Auditor',
        name: 'ExternalPageComponent',
    },
    ComplianceObrigacoesComponent: {
        url: '/compliance/complianceObrigacoes',
        urlType: UrlTypeEnum.nativo,
        title: 'Compliance Obrigações',
        name: 'ComplianceObrigacoesComponent',
    },
    TransitoriosComponent: {
        url: '/drive/arquivos-transitorios',
        urlType: UrlTypeEnum.nativo,
        title: 'Transferência de Arquivos',
        name: 'TransitoriosComponent',
    },
    MonitorArquivosTransitoriosComponentUpload: {
        url: '/drive/arquivos-transitorios/uploads',
        urlType: UrlTypeEnum.nativo,
        title: 'Transf. de Arquivos via Upload',
        name: 'MonitorArquivosTransitoriosComponentUpload',
    },
    MonitorArquivosTransitoriosComponentAgent: {
        url: '/drive/arquivos-transitorios/agent',
        urlType: UrlTypeEnum.nativo,
        title: 'Transf. de Arquivos via Agent',
        name: 'MonitorArquivosTransitoriosComponentAgent',
    },
    MonitorArquivosTransitoriosComponentEdi: {
        url: '/drive/arquivos-transitorios/edi',
        urlType: UrlTypeEnum.nativo,
        title: 'Transf. de Arquivos via EDI',
        name: 'MonitorArquivosTransitoriosComponentEdi',
    },
    PerfisComponent: {
        url: '/administracao/perfis-de-acesso',
        urlType: UrlTypeEnum.nativo,
        title: 'Perfil de Acesso',
        name: 'PerfisComponent',
    },
    PerfilDetalheComponent: {
        url: '/administracao/perfil/detalhe/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Detalhe do Perfil',
        name: 'PerfilDetalheComponent',
    },

    UsuarioLogadoComponent: {
        url: '/usuario-logado',
        urlType: UrlTypeEnum.nativo,
        title: 'Meu Usuário',
        name: 'UsuarioLogadoComponent',
    },
    UsuariosComponent: {
        url: '/administracao/usuarios',
        urlType: UrlTypeEnum.nativo,
        title: 'Usuários',
        name: 'UsuariosComponent',
    },
    UsuarioDetalheComponent: {
        url: '/administracao/usuario/detalhe/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Detalhe do Usuário',
        name: 'UsuarioDetalheComponent',
    },
    ConfiguracoesContaComponent: {
        url: '/administracao/configuracoes-conta',
        urlType: UrlTypeEnum.nativo,
        title: 'Configurações da conta',
        name: 'ConfiguracoesContaComponent',
    },
    EmpresasComponent: {
        url: '/administracao/empresas',
        urlType: UrlTypeEnum.nativo,
        title: 'Empresas',
        name: 'EmpresasComponent',
    },
    EmpresaDetalheComponent: {
        url: '/administracao/empresa/detalhe/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Detalhe da Empresa',
        name: 'EmpresaDetalheComponent',
    },
    ListLogComponent: {
        url: '/logs/listar',
        urlType: UrlTypeEnum.nativo,
        title: 'Logs do Sistema',
        name: 'LogComponent'
    },
    ListCompaniesComponent: {
        url: '/conta/empresas',
        urlType: UrlTypeEnum.nativo,
        title: 'Empresas',
        name: 'ListCompaniesComponent'
    },
    ListProfileComponent: {
        url: '/conta/perfis-de-acesso',
        urlType: UrlTypeEnum.nativo,
        title: 'Perfis de Acesso',
        name: 'ListProfileComponent'
    },
    RegisterProfileComponent: {
        url: '/perfis/cadastrar',
        urlType: UrlTypeEnum.nativo,
        title: 'Cadastrar Perfil',
        name: 'RegisterProfileComponent'
    },
    RegisterCompanyComponent: {
        url: '/empresas/cadastrar',
        urlType: UrlTypeEnum.nativo,
        title: 'Cadastar Empresa',
        name: 'RegisterCompanyComponent'
    },
    RegisterUserComponent: {
        url: '/usuarios/cadastrar',
        urlType: UrlTypeEnum.nativo,
        title: 'Cadastar Usuário',
        name: 'RegisterUserComponent'
    },
    DetailUserComponent: {
        url: '/usuarios/editar/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Detalhar Usuário',
        name: 'DetailUserComponent',
    },
    DetailMenuComponent: {
        url: '/menus/editar',
        urlType: UrlTypeEnum.nativo,
        title: 'Menu do Sistema',
        name: 'DetailMenuComponent',
    },
    DetailCompanyComponent: {
        url: '/empresas/editar/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Editar Empresa',
        name: 'DetailCompanyComponent',
    },
    DetailProfileComponent: {
        url: '/perfis/editar/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Editar Perfil',
        name: 'DetailProfileComponent',
    },
    CheckObrigacoesComponent: {
        url: '/check/checkObrigacoes',
        urlType: UrlTypeEnum.nativo,
        title: 'Check Obrigações',
        name: 'CheckObrigacoesComponent',
    },
    ObrigacoesDashboardComponent: {
        url: '/obrigacoes/dashboard',
        urlType: UrlTypeEnum.nativo,
        title: 'Dashboard Obrigações',
        name: 'ObrigacoesDashboardComponent',
    },
    AdministracaoComponent: {
        url: '/administracao',
        urlType: UrlTypeEnum.nativo,
        title: 'Administração',
        name: 'AdministracaoComponent',
    },
    ExternalPageComponent: {
        url: '/embedded',
        urlType: UrlTypeEnum.embedded,
        title: 'Embedded',
        name: 'ExternalPageComponent',
    },
    DadosEmpresaComponent: {
        url: '/empresa/dadosEmpresa',
        urlType: UrlTypeEnum.nativo,
        title: 'Dados de Empresas',
        name: 'DadosEmpresaComponent'
    },
    DetalhesDadosEmpresaComponent: {
        url: '/empresa/detalhes-dados-empresa/',
        urlType: UrlTypeEnum.nativo,
        title: 'Detalhes da Empresa',
        name: 'DetalhesDadosEmpresaComponent',
    },
    BaixasAutomaticasComponent: {
        url: '/drive/baixas-automaticas',
        urlType: UrlTypeEnum.nativo,
        title: 'Baixas Automáticas',
        name: 'BaixasAutomaticasComponent',
    },
    BaixasAutomaticasDetalhesComponent: {
        url: '/drive/solicitacoes-baixas',
        urlType: UrlTypeEnum.nativo,
        title: 'Solicitações Baixas',
        name: 'BaixasAutomaticasDetalhesComponent',
    },
    ConfiguracoesComponent: {
        url: '/integracoes/configuracoes',
        urlType: UrlTypeEnum.nativo,
        title: 'Configurações',
        name: 'ConfiguracoesComponent',
    },
    LoadingComponent: {
        url: '/loading',
        urlType: UrlTypeEnum.nativo,
        title: 'loading...',
        name: 'LoadingComponent',
    },
    NotasFiscaisEletronicasComponent: {
        url: '/configuracoes/notasFiscaisEletronicas',
        urlType: UrlTypeEnum.nativo,
        title: 'Notas Fiscais Eletronicas',
        name: 'NotasFiscaisEletronicasComponent',
    },
    EditarNotasFiscaisEletronicasComponent: {
        url: '/configuracoes/editarNotasFiscaisEletronicas/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Editar Notas Fiscais Eletronicas',
        name: 'EditarNotasFiscaisEletronicasComponent',
    },
    OmissoesSefazComponent: {
        url: '/consultas/omissoesSefaz',
        urlType: UrlTypeEnum.nativo,
        title: 'Omissões Sefaz',
        name: 'OmissoesSefazComponent',
    },
    DiagnosticoFiscalComponent: {
        url: '/compliance/diagnostico-fiscal',
        urlType: UrlTypeEnum.nativo,
        title: 'Diagnóstico Fiscal',
        name: 'DiagnosticoFiscalComponent',
    },
    ComplianceBaixaObrigacoesComponent: {
        url: '/configuracoes/complianceBaixaseAutomacoes',
        urlType: UrlTypeEnum.nativo,
        title: 'Compliance Baixas e Automações',
        name: 'ComplianceBaixaObrigacoesComponent',
    },
    HistoricoDeAcessosComponent: {
        url: '/monitoramento/historico-de-acessos',
        urlType: UrlTypeEnum.nativo,
        title: 'Histórico de Acessos',
        name: 'HistoricoDeAcessosComponent',
    },
    CalendarConfirmacoesComponent: {
        url: '/calendar/calendarConfirmacoes',
        urlType: UrlTypeEnum.nativo,
        title: 'Calendar Confirmações',
        name: 'CalendarConfirmacoesComponent',
    },
    RegrasDesconsideracoesComponent: {
        url: '/compliance/regras-desconsideracoes',
        urlType: UrlTypeEnum.nativo,
        title: 'Regras Desconsiderações',
        name: 'RegrasDesconsideracoesComponent',
    },
    DashboardComponent: {
        url: '/compliance/bi-dashboard',
        urlType: UrlTypeEnum.nativo,
        title: 'Compliance - BI Dashboard',
        name: 'DashboardComponent',
    },
    MonitorDeEventosComponent: {
        url: '/monitor-de-eventos',
        urlType: UrlTypeEnum.nativo,
        title: 'Monitor de Eventos',
        name: 'MonitorDeEventosComponent',
    },
    ComplianceConfiguracoesComponent: {
        url: '/compliance/configuracoes',
        urlType: UrlTypeEnum.nativo,
        title: 'Compliance Configurações',
        name: 'ComplianceConfiguracoesComponent',
    },
    EntradasComponent: {
        url: '/workflow/notas-de-entrada',
        urlType: UrlTypeEnum.nativo,
        title: 'Entradas',
        name: 'EntradasComponent',
    },
    NfeTerceirosComponent: {
        url: '/notas/nfe/terceiros/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'NFE - Terceiros',
        name: 'NfeTerceirosComponent'
    },
    CategoriasEStatusComponent: {
        url: '/workflow/categorias-e-status',
        urlType: UrlTypeEnum.nativo,
        title: 'Categorias e Status Workflow',
        name: 'CategoriasEStatusComponent',
    },
    ControleDeCertificadosComponent: {
        url: '/dashboards/controle-de-certificados',
        urlType: UrlTypeEnum.nativo,
        title: 'Controle de Certificados',
        name: 'ControleDeCertificadosComponent',
    },
    RegrasAnaliseXmlSaidaComponent: {
        url: '/cadastros/regras-analise-xml-saida',
        urlType: UrlTypeEnum.nativo,
        title: 'Regas XML Saída',
        name: 'RegrasAnaliseXmlSaidaComponent',
    },
    AnalisesXMLSaidasComponent: {
        url: '/notas/analise-xml-saidas',
        urlType: UrlTypeEnum.nativo,
        title: 'Análise XML Saídas',
        name: 'AnalisesXMLSaidasComponent',
    },
    AnalisesXMLSaidasDetalhesComponent: {
        url: '/notas/analise-xml-saidas-detalhes',
        urlType: UrlTypeEnum.nativo,
        title: 'Análise XML Saídas Detalhes',
        name: 'AnalisesXMLSaidasDetalhesComponent',
    },
    InboxComponent: {
        url: '/inbox/mensagens',
        urlType: UrlTypeEnum.nativo,
        title: 'Inbox',
        name: 'InboxComponent',
    },
    CamposPreviewArquivoComponent: {
        url: '/integracoes/arquivo',
        urlType: UrlTypeEnum.nativo,
        title: 'Preview Arquivo',
        name: 'CamposPreviewArquivoComponent',
    },
    InboxConfigComponent: {
        url: '/configuracoes/inbox',
        urlType: UrlTypeEnum.nativo,
        title: 'Configurações Inbox',
        name: 'InboxConfigComponent',
    },
    EditarSATComponent: {
        url: '/configuracoes/editarSAT/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Editar SAT',
        name: 'EditarSATComponent',
    },
    RelatorioPendenciasFiscaisComponent: {
        url: '/situacaoFiscal/relatorio-pendencias-fiscais',
        urlType: UrlTypeEnum.nativo,
        title: 'Relatório Pendências Fiscais',
        name: 'RelatorioPendenciasFiscaisComponent'
    },
};
