import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class CalendarTasksService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/calendar/tasks', {});
    }

    retornaCabecalho(mes: number, ano: number, filtros: any): Observable<any> {

        const mesStr = this.mesStr(mes);

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.post(`${environment.apiUrl}/calendar/tasks/cabecalho/${ano}/${mesStr}${queryStr}`, {});
    }

    retornaObrigacoes(mes: number, ano: number): Observable<any> {

        const mesStr = this.mesStr(mes);

        return this.http.get(`${environment.apiUrl}/calendar/obrigacoes/obrigacoes/${ano}/${mesStr}`);
    }


    retornaAtividades(mes: number, ano: number, filtros: any, order: any): Observable<any> {

        const mesStr = this.mesStr(mes);

        if (filtros.vencimento) {
            filtros.vencimento = this.formataDateBD(filtros.vencimento.toString());
        }

        if (order?.ordenar && order.sentido) {
            filtros.ordenar = order.ordenar;
            filtros.sentido = order.sentido;
        }

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.post(`${environment.apiUrl}/calendar/tasks/atividades/${ano}/${mesStr}${queryStr}`, {});
    }

    retornarSelectsEmpresas(grupo: string): Observable<any> {

        const params = grupo ? '?grupoEmpresarial=' + grupo : '';

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select${params}`);
    }

    solicitarAprovacao(id: string, dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/calendar/tasks/solicitar-aprovacao/${id}`, dados);
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    montaOrder(parametros): any {
        let order = {};

        parametros.forEach((value) => {

            if (value.value) {
                order = {
                    ordenar: value.key,
                    sentido: value.value === 'ascend' ? 'asc' : 'desc'
                };
            }
        });

        return order;

    }

    concluirAtividades(selectedIds: Set<string> | string[]): Observable<any> {

        const data = Array.from(selectedIds);

        return this.http.post(`${environment.apiUrl}/calendar/tasks/concluir`, {atividade_id: data});

    }

    gerarDctfFuturo(cnpj: string, ano: number, competencia: string): Observable<any> {

        return this.http.post(`${environment.apiUrl}/calendar/tasks/gerar-dctf-futuro`, {cnpj, ano, competencia});

    }

    desconcluirAtividades(selectedIds: Set<string> | string[]): Observable<any> {

        const data = Array.from(selectedIds);

        return this.http.put(`${environment.apiUrl}/calendar/tasks/desfazerConclusao`, {atividade_id: data});

    }

    retornaComboResponsaveis(equipeId: string): Observable<any> {

        const qStr = equipeId ? `?equipeId=${equipeId}` : '';

        return this.http.get(`${environment.apiUrl}/filtros/responsaveis/select` + qStr);

    }

    retornaComboEquipes(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/equipes/listar`);

    }

    vincularResponsavelCalendar(selectedIds: Set<string> | string[], responsavelId: any) {

        const data = {
            ids: Array.from(selectedIds),
            itens: [
                responsavelId
            ]
        };

        return this.http.put(`${environment.apiUrl}/calendar/tasks/obrigacoes/responsavel`, data);

    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            if (valor) {
                retorno = valor.toString();
            } else {
                retorno = valor;
            }

        }

        return retorno;
    }

    retornarSelectsCategorias(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/categorias`);
    }

    getHistorico(atividadeId: string, params?: any): Observable<any> {

        const filtros: any = {};

        if (params && params.sort) {
            Object.entries(params.sort).forEach((f: any) => {
                if (f[1].value) {
                    filtros.ordenar = f[1].key;
                    filtros.sentido = f[1].value;
                }
            });
        }

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/calendar/tasks/historico-conclusao/${atividadeId}`);

    }

    retornaselectsGrupos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

    dispensar(ids: string[], justificativa: string): Observable<any> {

        return this.http.post(`${environment.apiUrl}/calendar/tasks/dispensar-atividade`, {ids, justificativa });
    }

}
