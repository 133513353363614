import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContaNaoEncontradaComponent} from '@components/conta-nao-encontrada/conta-nao-encontrada.component';
import {ContaNaoEncontradaRoutingModule} from '@components/conta-nao-encontrada/conta-nao-encontrada-routing.module';


@NgModule({
    declarations: [
        ContaNaoEncontradaComponent
    ],
    imports: [
        CommonModule,
        ContaNaoEncontradaRoutingModule
    ]
})
export class ContaNaoEncontradaModule {
}
