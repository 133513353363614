import {Component, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {HttpClient} from '@angular/common/http';
import {DataService} from '@services/data.service';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {ConfiguracoesService} from './configuracoes.service';
import {environment} from '../../../../environments/environment';
import {Helpers} from '../../../core/helpers';

interface FormStack {
    modalVisible: boolean;
    formGroup: FormGroup;
}

@Component({
    selector: 'app-cadastros-inscricoes-regimes',
    templateUrl: './configuracoes.component.html',
    styleUrls: ['./configuracoes.component.scss']
})
export class ConfiguracoesComponent extends AbstractListTable<any> implements OnInit, TabHandlerInterface {
    readonly registerLink = '/usuarios/cadastrar';

    token;
    formFiltrar: FormStack;
    qtdFiltrosAtivos = 0;
    usuarioLogado: any;
    @Input() data;
    optionsEmpresas = [];
    ultimaMensagemSefaz = '';
    modalUltimaMensagemTitulo = '';
    modalMensagemSefazVisible = false;
    exibirTudo = false;
    statusCard = false;
    loadingAddConfig = false;
    loadingRemoveConfig = [];
    loadingAlterandoConfig = false;
    loadingCarregandoConfig = false;

    camposFormConfiguracoesERP = [];

    formConfiguracaoERP: FormStack;

    modalCopiarVisible = false;
    configParaCopiar: any = {};

    empresas = [];
    gruposEmpresariais = [];
    loadingEmpresas = false;

    checkedEmpresas = new Set<string>();

    empresasFront = [];
    buscaInterna: any = null;

    loadingCopiandoConfig = false;

    integracoes = [{key: 'SAP', value: 'sap'}, {key: 'DEALER', value: 'dealer'}];

    editando = false;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
            json: 'Configuração deve ser um JSON válido'
        }
    };

    constructor(
        private fb: FormBuilder,
        private modalService: NzModalService,
        private service: ConfiguracoesService,
        private toastService: ToastrService,
        private tabService: TabService,
        private dataService: DataService,
        protected http: HttpClient) {
        super(service, null, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa: [null, null],
                grupo: [null, null],
            })
        };

        this.formConfiguracaoERP = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
                status: ['inativa', Validators.required],
                configuracao: [null, Validators.required],
                empresa_id: [null, Validators.required],
            })
        };

    }

    ngOnInit() {
        this.dataService.currentUser.subscribe((data) => {
            this.usuarioLogado = data;
            if (this.usuarioLogado.origem === 'console') {
                this.exibirTudo = true;
            }
        });

        this.service.retornarOptionEmpresas().subscribe((retorno: any) => {
            this.optionsEmpresas = [];
            this.optionsEmpresas = retorno;

            this.empresas = this.optionsEmpresas;
            this.filtrarEmpresasFront();
        });

        this.service.retornaselectsGrupos().subscribe((retorno: any) => {
            this.gruposEmpresariais = retorno.grupoEmpresarial;
        });

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    showModalConfiguracaoERP(visible: boolean, configuracao = null, editando = false, empresaId = null) {
        this.editando = editando;
        if (visible && editando) {
            this.configParaCopiar = configuracao;
            this.filtrarCamposConfigERP(configuracao.id);
        } else {
            this.configParaCopiar = {};
            this.formConfiguracaoERP.formGroup.reset();
            this.formConfiguracaoERP.formGroup.get('empresa_id').setValue(empresaId);
        }

        this.formConfiguracaoERP.modalVisible = visible;
    }

    filtrarCamposConfigERP(id: string) {
        this.loadingCarregandoConfig = true;
        this.camposFormConfiguracoesERP = [];

        this.service.retornaCamposConfigERP(id).subscribe((retorno: any) => {
            this.camposFormConfiguracoesERP = retorno;

            this.formConfiguracaoERP.formGroup.get('status').setValue(retorno.status);
            this.formConfiguracaoERP.formGroup.get('tipo').setValue(retorno.tipo);
            this.formConfiguracaoERP.formGroup.get('configuracao').setValue(retorno.configuracao);

            this.loadingCarregandoConfig = false;
        });
    }

    confirmaEditarConfigERP() {

        if (this.formConfiguracaoERP.formGroup.valid) {
            this.loadingAlterandoConfig = true;

            const dados = this.formConfiguracaoERP.formGroup.value;
            delete dados.empresa_id;

            this.service.alterarConfigERP(this.configParaCopiar.id, dados).subscribe((retorno: any) => {
                this.loadingAlterandoConfig = false;
                this.toastService.success('Ação realizada com sucesso');
                this.showModalConfiguracaoERP(false);
                this.queryTable(this.currentParams, this.currentSearch);
            }, error => {
                this.loadingAlterandoConfig = false;
                this.toastService.error(error.error.message);
            });

        } else {
            Object.values(this.formConfiguracaoERP.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    confirmaCadastrarConfigERP() {

        if (this.formConfiguracaoERP.formGroup.valid) {

            this.loadingAddConfig = true;

            this.service.cadastrarConfiguracaoERP(this.formConfiguracaoERP.formGroup.value).subscribe((retorno: any) => {
                this.optionsEmpresas = [];
                this.optionsEmpresas = retorno;
                this.loadingAddConfig = false;
                this.showModalConfiguracaoERP(false);
                this.toastService.success('Dados gravados com sucesso');
                this.queryTable(this.currentParams, this.currentSearch);
            }, error => {
                this.toastService.error(error.error.message);
            });
        } else {
            Object.values(this.formConfiguracaoERP.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    showModalCopiarConfiguracao(visible: boolean, configuracao = null) {
        this.configParaCopiar = configuracao;
        this.modalCopiarVisible = visible;

        if (!visible) {
            this.checkedEmpresas.clear();
        }
    }

    showConfirmCopiar(): void {
        const modal: NzModalRef = this.modalService.create({
            nzTitle: 'Exportar configuração',
            nzContent: `Esta ação irá copiar a configuração ${this.configParaCopiar.tipo.toUpperCase()} para ${this.checkedEmpresas.size} empresa(s). Deseja continuar?`,
            nzFooter: [
                {
                    label: 'Cancelar',
                    type: 'default',
                    onClick: () => {
                        this.showModalCopiarConfiguracao(false);
                        modal.destroy();
                    },
                },
                {
                    label: 'Continuar',
                    type: 'primary',
                    onClick: () => {
                        this.confirmarCopiarConfiguracao();
                        modal.destroy();
                    }
                },
            ]
        });

    }

    showConfirmRemover(i: any): void {
        const modal: NzModalRef = this.modalService.create({
            nzTitle: 'Remover configuração',
            nzContent: `Deseja realmente remover essa configuração?`,
            nzFooter: [
                {
                    label: 'Cancelar',
                    type: 'default',
                    onClick: () => {
                        modal.destroy();
                    },
                },
                {
                    label: 'Continuar',
                    type: 'primary',
                    onClick: () => {
                        this.removerConfig(i.id);
                        modal.destroy();
                    }
                },
            ]
        });

    }

    removerConfig(id: string) {
        this.loadingRemoveConfig.push(id);

        this.service.removerConfiguracaoERP(id).subscribe((retorno: any) => {
            this.loadingRemoveConfig = [];
            this.toastService.success('Ação realizada com sucesso');
            this.queryTable(this.currentParams, this.currentSearch);
        }, error => {
            this.loadingRemoveConfig = [];
            this.toastService.error(error.error.message);
        });
    }

    confirmarCopiarConfiguracao() {

        this.loadingCopiandoConfig = true;

        this.service.copiarConfigERP({
            origem: this.configParaCopiar.id,
            destinos: Array.from(this.checkedEmpresas),

        }).subscribe((retorno: any) => {
            this.loadingCopiandoConfig = false;
            this.modalCopiarVisible = false;
            this.toastService.success('Ação realizada com sucesso');
            this.queryTable(this.currentParams, this.currentSearch);
        }, error => {
            this.toastService.error(error.error.message);
            this.loadingCopiandoConfig = false;
        });

    }

    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
    }

    confirmaFiltrar(): void {

        this.currentParams.pageIndex = 1;

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    onAllChecked(checked: boolean): void {
        const itens = this.items.filter(({disabled}) => !disabled);
        itens.forEach(item => {
            this.updateCheckedSet(item.id, checked);
        });
    }

    buscar() {
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        if (search) {
            this.currentSearch = search;
        }

        this.calculaBadgeFiltros();

        this.loading = true;

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.abstractService.listToTable(this.currentParams, this.currentSearch,
            environment.apiUrl + '/integracoes/erp/configuracoes-conta/tabela').subscribe((response) => {

            this.items = response;

            this.items.forEach(config => {
                const ativas = [];
                if (config.integracoes) {
                    config.integracoes.map(i => {
                        if (i.status === 1) {
                            ativas.push(i.tipo);
                        }
                    });
                }
                config.integracoes_ativas = ativas;
            });

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loading = false;
        });

    }

    modalMsgSefaz(visible: boolean, msg?: string, titulo?: string) {
        if (!visible) {
            this.ultimaMensagemSefaz = null;
            this.modalUltimaMensagemTitulo = null;
        } else {
            this.ultimaMensagemSefaz = msg;
            this.modalUltimaMensagemTitulo = titulo;
        }
        this.modalMensagemSefazVisible = visible;
    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {

                if (valor && chave) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }

    }

    btnResetSearch() {

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    clickEvent() {

        this.statusCard = !this.statusCard;

    }

    filtrarEmpresasFront() {
        this.empresasFront = [];

        if (this.buscaInterna) {
            const busca = this.buscaInterna.toLowerCase();
            this.empresasFront = this.empresas.filter(e => {
                if (e.nome.toLowerCase().includes(busca) ||
                    e.cnpj.includes(busca) ||
                    (Helpers.maskCnpjCpf(e.cnpj).includes(busca))) {
                    return e;
                }
            });
        } else {
            this.empresasFront = this.empresas;
        }
    }

    empresaChecked(id: string): void {

        this.updateCheckedSetEmpresa(id);
    }

    onAllCheckedEmpresas(checked: boolean): void {
        const empresas = this.empresas.filter(({disabled}) => !disabled);
        empresas.forEach(e => {
            this.updateCheckedSetEmpresa(e.id);
        });
    }

    updateCheckedSetEmpresa(id: string): void {
        if (!this.checkedEmpresas.has(id)) {
            this.checkedEmpresas.add(id);
        } else {
            this.checkedEmpresas.delete(id);
        }
    }

    btnResetSearchFront() {
        this.buscaInterna = null;
        this.empresasFront = this.empresas;
    }

}
