import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ComplianceConfiguracoesService} from './compliance-configuracoes.service';
import {Helpers} from '../../../core/helpers';
import {ToastrService} from 'ngx-toastr';
import {Pagination} from '@models/pagination.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-configuracoes-conta',
    templateUrl: './compliance-configuracoes.component.html',
    styleUrls: ['./compliance-configuracoes.scss']
})
export class ComplianceConfiguracoesComponent implements OnInit {

    pagination: Pagination;
    currentParams: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 1,
        sort: [],
        filter: []
    };
    currentSearch: string;

    checkedItems = new Set<string>();

    checked = false;

    responsavelEmailMassa = null;

    loadingTable = false;
    showModalCompetenciaAnalises = false;
    showModalDiaAnalises = false;
    showModalResponsaveis = false;
    showModalResponsalMassa = false;
    formEditarCompetenciaAnalise = this.fb.group({
        dataInicioAnalise: [new Date(), Validators.required],
    });

    formEditarDiaAnalise = this.fb.group({
        diaAnalise: [null, Validators.required],
    });

    formResponsaveis = this.fb.group({});

    loadings = {
        editando: false,
        tabelaResponsaveis: false
    };

    comboResponsaveis = [];
    marcadorBi = null;
    inicioAnalise = null;
    diaAnalise = null;

    responsavelPorEmpresa = [];
    responsavelPorEmpresaPreenchidos = {};
    responsaveisDefinidos = 0;
    qtdEmpresas = 0;
    camposPorEmpresa = [];

    dias = [];

    constructor(
        private fb: FormBuilder,
        private service: ComplianceConfiguracoesService,
        private toastrService: ToastrService,
        private modal: NzModalService,
        private cdr: ChangeDetectorRef
    ) {
        this.pagination = new Pagination();
    }

    protected readonly Helpers = Helpers;

    ngOnInit() {

        for (let i = 1; i <= 31; i++) {
            this.dias.push(String(i));
        }

        this.getDataInicioAnalise();
        this.getDiaAnalise();
        this.service.retornaComboResponsaveis().subscribe((retorno: any) => {
            this.comboResponsaveis = retorno;
        });

        this.service.retornaValorTabela('MARCADOR_BI').subscribe((retorno: any) => {
            this.marcadorBi = retorno.value;
        });

        this.getResponsaveisPorEmpresa(this.currentParams, this.currentSearch);
    }

    getDataInicioAnalise() {
        this.service.retornaValorTabela('ANALISE_AUTOMATICA_DATA_INICIO').subscribe((retorno: any) => {
            this.inicioAnalise = retorno.value;
            this.formEditarCompetenciaAnalise.get('dataInicioAnalise').setValue(retorno.value);
        });
    }

    getDiaAnalise() {
        this.service.retornaValorTabela('DIA_ANALISE_AUTOMATICA').subscribe((retorno: any) => {
            this.diaAnalise = retorno.value;
            this.formEditarDiaAnalise.get('diaAnalise').setValue(retorno.value);
        });
    }

    buscar() {
        this.currentParams.pageIndex = 1;
        this.getResponsaveisPorEmpresa(this.currentParams, this.currentSearch);
    }

    getResponsaveisPorEmpresa(params: NzTableQueryParams, search: string = null) {

        this.loadings.tabelaResponsaveis = true;

        this.currentParams = params;
        this.currentSearch = search;

        const filtros: any = {};

        filtros.quantidade = 20;
        if (search) {
            filtros.procurar = search;
        } else {
            delete filtros.procurar;
        }

        if (params?.pageIndex) {
            filtros.pagina = params.pageIndex;
        }

        this.service.retornaResponsaveisPorEmpresa(filtros).subscribe({
            next: (response: any) => {

                this.responsaveisDefinidos = response.quantidade.qtdResponsaveis;
                this.qtdEmpresas = response.quantidade.qtdEmpresas;

                this.responsavelPorEmpresa.forEach(r => {
                    this.responsavelPorEmpresaPreenchidos[r.empresa.id] = r.responsavel.email;
                });

                this.responsavelPorEmpresa = [];
                response.registros.data.forEach(r => {
                    this.responsavelPorEmpresa.push({
                        empresa: {nome: r.empresa_nome, id: r.empresa_id, cnpj: r.empresa_cnpj},
                        responsavel: this.responsavelPorEmpresaPreenchidos[r.empresa_id] ?
                            {nome: r.usuario_nome, email: this.responsavelPorEmpresaPreenchidos[r.empresa_id]} :
                            {nome: r.usuario_nome, email: r.usuario_email}
                    });
                });

                this.pagination = new Pagination(
                    response?.registros?.per_page || 10,
                    response?.registros?.current_page || 1,
                    response?.registros?.last_page || 1,
                    response?.registros?.total || 50);

                this.loadings.tabelaResponsaveis = false;
            },
            error: (err) => {
                this.loadings.tabelaResponsaveis = false;
                this.toastrService.error(err.error.message);
            }
        });

        this.responsavelPorEmpresa.forEach(r => {
            this.formResponsaveis.addControl(r.empresa.id, new FormControl());
            this.formResponsaveis.get(r.empresa.id).setValue(r.responsavel.email, Validators.required);
        });

        this.camposPorEmpresa = Object.keys(this.formResponsaveis.controls);
    }


    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.getResponsaveisPorEmpresa(params, this.currentSearch);
            this.pagination.current_page = params.pageIndex;
        }

    }

    modalCompetenciaAnalises() {
        this.showModalCompetenciaAnalises = !this.showModalCompetenciaAnalises;
    }

    modalDiaAnalises() {
        this.formEditarDiaAnalise.get('diaAnalise').setValue(this.diaAnalise);
        console.log(typeof this.formEditarDiaAnalise.get('diaAnalise').value);
        this.showModalDiaAnalises = !this.showModalDiaAnalises;
    }

    modalResponsaveis() {
        this.showModalResponsaveis = !this.showModalResponsaveis;
        if (!this.showModalResponsaveis) {
            this.responsavelPorEmpresaPreenchidos = {};
            this.currentParams.pageIndex = 1;
            this.currentSearch = null;

            this.getResponsaveisPorEmpresa(this.currentParams);
        }
    }

    modalResponsavelMassa() {
        this.showModalResponsalMassa = !this.showModalResponsalMassa;
    }

    editarInicioAnalises() {

        if (this.formEditarCompetenciaAnalise.valid) {

            const data = new Date(this.formEditarCompetenciaAnalise.value.dataInicioAnalise);
            data.setDate(1);

            this.loadings.editando = true;

            this.service.atualizaValorTabela(
                'ANALISE_AUTOMATICA_DATA_INICIO',
                {value: data}).subscribe({
                next: (retorno: any) => {
                    this.loadings.editando = false;
                    this.modalCompetenciaAnalises();
                    this.getDataInicioAnalise();
                    this.toastrService.success('Ação realizada com sucesso!');

                }, error: (err) => {
                    this.loadings.editando = false;
                    this.toastrService.error(err.error.message);
                }
            });

        } else {

            this.loadings.editando = false;
            Object.values(this.formEditarCompetenciaAnalise.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }


    }

    editarDiaAnalises() {

        if (this.formEditarDiaAnalise.valid) {
            const dia = this.formEditarDiaAnalise.value.diaAnalise;

            this.loadings.editando = true;

            this.service.atualizaValorTabela(
                'DIA_ANALISE_AUTOMATICA',
                {value: dia}).subscribe({
                next: (retorno: any) => {
                    this.loadings.editando = false;
                    this.modalDiaAnalises();
                    this.getDiaAnalise();
                    this.toastrService.success('Ação realizada com sucesso!');

                }, error: (err) => {
                    this.loadings.editando = false;
                    this.toastrService.error(err.error.message);
                }
            });

        } else {

            this.loadings.editando = false;
            Object.values(this.formEditarCompetenciaAnalise.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }


    }

    editarResponsaveis(usuarioEmail = null) {
        const toUpdate = [];
        const emailInvalidos = [];

        this.loadings.editando = true;

        if (this.checkedItems.size > 0 && usuarioEmail) {
            Array.from(this.checkedItems).forEach(e => {

                if (!Helpers.isEmail(usuarioEmail)) {
                    this.toastrService.error('Email para responsável inválido!');
                    emailInvalidos.push(usuarioEmail);
                }

                toUpdate.push(
                    {
                        empresa_id: e,
                        email: usuarioEmail
                    }
                );
            });
        } else {

            this.responsavelPorEmpresa.forEach(r => {
                if (r.empresa.id) {

                    if (r.responsavel.email && !Helpers.isEmail(r.responsavel.email)) {
                        emailInvalidos.push(r.responsavel.email);
                    }

                    toUpdate.push(
                        {
                            empresa_id: r.empresa.id,
                            email: r.responsavel.email
                        }
                    );
                }
            });

            for (const email of emailInvalidos) {
                this.toastrService.error(`${email} não é um email válido!`);
            }

            Object.entries(this.responsavelPorEmpresaPreenchidos).forEach((key) => {

                const existe = toUpdate.find(item => item.empresa_id === key[0]);

                if (!existe) {

                    toUpdate.push(
                        {
                            empresa_id: key[0],
                            email: key[1]
                        }
                    );

                }
            });

        }

        if (emailInvalidos.length) {
            this.loadings.editando = false;
            return;
        }

        this.service.alteraResponsaveisPorEmpresa(toUpdate).subscribe({
            next: (res: any) => {
                this.toastrService.success(res.message);
                this.currentParams.pageIndex = 1;
                this.getResponsaveisPorEmpresa(this.currentParams, this.currentSearch);
                this.modalResponsaveis();
                this.loadings.editando = false;
                this.checkedItems.clear();
                this.pagination.current_page = 1;

            },
            error: (err) => {
                this.loadings.editando = false;
                this.toastrService.error(err.error.message);
            }
        });
    }

    onAllChecked(checked: boolean): void {

        this.checked = checked;

        this.responsavelPorEmpresa.forEach(({empresa}) => {
            this.updateCheckedSet(empresa.id, checked);
        });

    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

        this.checked = this.checkedItems.size === this.responsavelPorEmpresa.length;

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }
    }

    confirmAtualizarEmMassa(): void {
        const msg = this.checkedItems.size > 1 ? `Deseja definir o usuário como responsável para as ${this.checkedItems.size} empresas selecionadas?` :
            'Deseja definir o usuário como responsável para a empresa selecionada?';
        this.modal.confirm({
            nzTitle: msg,
            nzOkText: 'Sim',
            nzCancelText: 'Não',
            nzOnOk: () => {
                this.modalResponsavelMassa();
                this.editarResponsaveis(this.responsavelEmailMassa);
            }
        });

    }

}
