import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ContaNaoEncontradaComponent} from '@components/conta-nao-encontrada/conta-nao-encontrada.component';

const routes: Routes = [

    {path: '', component: ContaNaoEncontradaComponent}];

@NgModule({
    exports: [RouterModule],
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ]
})
export class ContaNaoEncontradaRoutingModule {
}
