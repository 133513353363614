<div style="background-color: #fff; padding-bottom: 50px">
    <nz-page-header class="site-page-header" nzTitle="Configurações" nzSubtitle="Inbox"></nz-page-header>

    <div style=" display: flex; flex-direction: column; align-items: center;
     padding-top: 3%;">

        <div style="width: 75%; padding-bottom: 100px">
            <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formConfiguracao"
                  style="width: 100%;">
                <nz-divider nzPlain [nzText]="tituloGoogle" nzOrientation="left"></nz-divider>

                <ng-template #tituloGoogle>
                    <span class="titulo">
                        Autenticação Google
                    </span>
                </ng-template>
                <nz-form-item>
                    <nz-form-label [nzSpan]="7"></nz-form-label>
                    <button *ngIf="!oauthGoogle else logadoComGoogle" nz-button nzType="default"
                            (click)="loginViaGoole()">
                        <img style="margin: -4px 3px 0 -3px; width: 20px" src="/assets/google-logo.png">
                        Login via Google
                    </button>

                    <ng-template #logadoComGoogle>
                        <button [nzLoading]="loadingBtGoogle"
                                [nz-tooltip]="'Logado com ' + this.formConfiguracao.get('email').value" nz-button
                                nzType="default" (click)="desconectarGoole()">
                            <img style="margin: -4px 3px 0 -3px; width: 20px" src="/assets/google-logo.png">
                            Desconectar conta Google
                        </button>
                    </ng-template>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="7"></nz-form-label>
                </nz-form-item>
                <nz-divider nzPlain [nzText]="tituloImap" nzOrientation="left"></nz-divider>
                <ng-template #tituloImap>
                    <span class="titulo">
                        Autenticação IMAP
                    </span>
                </ng-template>
                <nz-form-item>
                    <nz-form-label [nzSpan]="7" nzRequired>Host do Servidor IMAP</nz-form-label>
                    <nz-form-control [nzSpan]="12" [nzErrorTip]="'Campo Obrigatório'">
                        <input nz-input type="text" formControlName="host" placeholder="Host do servidor IMAP"
                               maxlength="100"/>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="7" nzRequired>Conta (e-mail)</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <input autocomplete="off" nz-input type="email" formControlName="email"
                               placeholder="Conta (e-mail)"
                               maxlength="100"/>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="7">Senha</nz-form-label>
                    <nz-form-control [nzSpan]="12" [nzErrorTip]="'Campo Obrigatório'">
                        <nz-input-group [nzSuffix]="suffixTemplate">
                            <input
                                    [type]="passwordVisible ? 'text' : 'password'"
                                    nz-input
                                    autocomplete="off"
                                    placeholder="Senha"
                                    formControlName="password"
                            />
                        </nz-input-group>
                        <ng-template #suffixTemplate>
                          <span
                                  nz-icon
                                  class="ant-input-password-icon"
                                  [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                                  (click)="passwordVisible = !passwordVisible"
                          ></span>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="7">Porta IMAP</nz-form-label>
                    <nz-form-control [nzSpan]="12" [nzErrorTip]="'Campo Obrigatório'">
                        <input nz-input type="text" formControlName="port" placeholder="Porta" type="text"/>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="7">Pasta de leitura</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <input nz-input type="text" formControlName="pasta" placeholder="Pasta para leitura"
                               type="text"/>
                    </nz-form-control>
                </nz-form-item>

                <nz-divider nzPlain [nzText]="tituloInbox" nzOrientation="left"></nz-divider>

                <ng-template #tituloInbox>
                    <span class="titulo">
                        Inbox
                    </span>
                </ng-template>

                <nz-form-item>
                    <nz-form-label [nzSpan]="7">Ler mensagens a partir de</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-date-picker style="width: 100%;" formControlName="data"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="7">Ativo</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-switch formControlName="status"></nz-switch>
                    </nz-form-control>
                </nz-form-item>

                <div style="width: 59%; margin: 0 auto; text-align: right;">
                    <button nz-button nzType="primary"
                            [nzLoading]="loadingBt" (click)="this.cadastrarConfiguracao();">
                        Gravar
                    </button>
                </div>


            </form>
        </div>

    </div>

</div>