<div class="principal">
    <div class="info">

        <div class="div-l">



            <div class="textos">
                <h2>
                    Conta não encontrada.
                </h2>

                <p>
                    Nenhuma conta encontrada.
                    <br>
                    Podem existir caracteres inválidos, ou a sua conta pode não ter sido cadastrada ainda.
                </p>

                <div class="link">
                    <a>
                        Contatar suporte
                        <i class="fa-solid fa-arrow-right"></i>
                    </a>
                </div>
            </div>

            <div class="img-logo">
                <img alt="logo-mastertax" src="{{logoUrl}}">
            </div>

        </div>


    </div>
    <div class="imagem">
        <img src="" class="image"
             *ngIf="backgroundImageUrl"
             [ngStyle]="{'background-image': 'url(' + backgroundImageUrl + ')', 'background-size' : 'cover'}">
    </div>
</div>
