import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Empresa} from '@models/empresa.model';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ConfiguracoesContaService extends AbstractService<Empresa> {

    constructor(http: HttpClient) {
        super(http, '/administracao/configuracoes-conta', {});
    }

}
