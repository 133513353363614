<nz-page-header class="site-page-header" nzTitle="Arquivo"
                nzSubtitle="Integrações"></nz-page-header>

<nz-content>
    <nz-card [nzLoading]="loadings.dados" [nzBodyStyle]="{'padding': '15px'}">
        <div *ngIf="semDados">
            <nz-empty nzNotFoundImage="simple"></nz-empty>
        </div>
        <div nz-row *ngIf="camposLayout?.length">
            <div nz-col [nzSm]="24" [nzMd]="12">
                <iframe [ngStyle]="{height: '1200px'}"
                        [src]="iframeUrl" title=""
                        style="width: 100%; border: none;"
                        *ngIf="iframeUrl"></iframe>
            </div>
            <div nz-col [nzSm]="24" [nzMd]="12">
                <form nz-form [nzAutoTips]="Helpers.getAutoTips()" [nzNoColon]="true" [formGroup]="formLayout.formGroup"
                      style="width: 100%">
                    <ng-container *ngFor="let item of camposLayout">

                        <div class="sessao" *ngIf="item.tipo === 'sessao' else campo">
                            <h2 class="nome-sessao">{{ item.nome }}</h2>

                            <div *ngFor="let c of item.campos">
                                <nz-form-item class="item-form" *ngIf="c.obrigatorio || sessaoesParaExibir[item.nome]">
                                    <nz-form-label nzRequired *ngIf="c.obrigatorio else naoObrigatorio"
                                                   [nzSpan]="8">{{ formataVisualizacaoCampo(c.nome) }}
                                    </nz-form-label>
                                    <ng-template #naoObrigatorio>
                                        <nz-form-label [nzSpan]="8">{{ formataVisualizacaoCampo(c.nome) }}
                                        </nz-form-label>
                                    </ng-template>
                                    <nz-form-control [nzSpan]="14">
                                        <input nz-input value="{{c.valor}}" formControlName="{{c.nome}}" type="text"
                                               autocomplete="off"/>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                            <div class="bt-sessao">
                                <button *ngIf="item.qtdRequired !== item.campos.length"
                                        (click)="mostrarMais(item.nome)" nz-button nzType="primary" nzGhost>
                                    {{ sessaoesParaExibir[item.nome] ? 'Mostrar menos campos' : 'Mostrar mais campos' }}
                                </button>
                            </div>
                        </div>
                        <ng-template #campo>
                            <div>
                                <nz-form-item>
                                    <nz-form-label nzRequired *ngIf="item.obrigatorio else campoNaoObrigatorio"
                                                   [nzSpan]="8">{{ item.nme }}
                                    </nz-form-label>
                                    <ng-template #campoNaoObrigatorio>
                                        <nz-form-label [nzSpan]="8"> {{ item.nome }}
                                        </nz-form-label>
                                    </ng-template>
                                    <nz-form-control [nzSpan]="14">
                                        <input nz-input value="{{item.nome}}"
                                               formControlName="{{item.nome}}" type="text"
                                               autocomplete="off"/>
                                    </nz-form-control>
                                </nz-form-item>

                            </div>

                        </ng-template>

                    </ng-container>
                    <div class="bt-sessao" style="margin-top: 20px">
                        <button style="margin: 20px 0; width: 150px" nz-button nzType="primary"
                                (click)="gravarArquivo()"
                                [nzLoading]="loadings.gravando">Gravar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </nz-card>
</nz-content>
