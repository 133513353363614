import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NotasFiscaisEntradaComponent} from './notas-fiscais-entrada.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzFormModule} from 'ng-zorro-antd/form';
import {UiModule} from '../../ui.module';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NgxMaskModule} from 'ngx-mask';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzTabsModule} from 'ng-zorro-antd/tabs';



@NgModule({
  declarations: [NotasFiscaisEntradaComponent],
  exports: [NotasFiscaisEntradaComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NzPageHeaderModule,
        NzLayoutModule,
        NzCardModule,
        NzBadgeModule,
        NzInputModule,
        NzTableModule,
        NzTagModule,
        NzModalModule,
        NzGridModule,
        NzSelectModule,
        NzFormModule,
        UiModule,
        NzDatePickerModule,
        NgxMaskModule,
        NzCheckboxModule,
        NzTabsModule
    ]
})
export class NotasFiscaisEntradaModule { }
